import React from 'react';
import PropTypes from "prop-types"
import {Col, ControlLabel, FormGroup, Row} from "react-bootstrap";
import ColorCheckbox from "./ColorCheckbox";
import {colors as defaultColors, mapForSelect, schedulerEventStatuses} from "../../common/commonHandlers";
import moment from "moment";
import Datetime from "react-datetime";
import {connect} from "react-redux";
import * as actions from '../lists/ServicesApi'
import {bindActionCreators} from "redux";
import ResourceComponent from "../../components/ResourceComponent";

class Filter extends ResourceComponent {
    state = {
        users: [],
        statuses: [],
        color: 'employee',
        date: null,
        colors: ['employee', 'status', 'type'],
        isEmployeeFilterOpened: false,
        isTypeFilterOpened: false,
        isColorFilterOpened: false,
        isHolidaySettingsOpened: false,
        isStatusesFilterOpened: false,
        showDisabled: false,
        showAllHolidays: true
    };

    constructor(props, context) {
        super(props, context);

        this.firstRender = React.createRef(false)
    }

    componentDidMount = () => {
        const {client_id, value, userSearchFilters, selectedFilter, syncCalendars} = this.props;

        let selected;
        if (userSearchFilters && userSearchFilters.length > 0 && selectedFilter) {
            selected = userSearchFilters.find(f => f.id === selectedFilter)
        }

        this.setState({
            date: this.props.value.date,
            showDisabled: selected && !syncCalendars ? selected.settings.showDisabled : value?.showDisabled
        });
        if (this.firstRender) {
            this.firstRender.current = true;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value, selectedFilter, userSearchFilters, setActiveFilter, newSchedulerView, users} = this.props

        if (prevState.showDisabled !== this.state.showDisabled) {
            this.props.setDisabledState(this.state.showDisabled)
        }
        if (prevProps.users !== users) {
            this.setState({
                users: users
            })
        }
        if (prevProps.value !== value) {
            if (!this.state.showDisabled) {
                this.state.users.filter(u => u.disabled !== true)
            }

            if (selectedFilter && userSearchFilters.length > 0 && newSchedulerView) {
                const filter = userSearchFilters.find(f => f.id === selectedFilter)

                value.schedulerEventTypes = filter.settings.schedulerEventTypes
                value.color = filter.settings.color
                value.statuses = filter.settings.statuses

                setActiveFilter(selectedFilter)
            }

            this.setState({
                schedulerEventTypes: value.schedulerEventTypes,
                color: value.color,
                statuses: value.statuses,
                showDisabled: value?.showDisabled
            })
        }
    }

    merge(currentList, savedList) {
        const current = currentList.map(p => ({...p, selected: true}));
        const saved = savedList ? savedList.slice() : [];
        const result = [];
        current.forEach(x => {
            const found = saved.find(y => y.value === x.value);
            if (!found) {
                result.push(x);
            } else {
                result.push(found);
            }
        });
        return result;
    }

    userLabelFormatter = (user) => {
        if (user.deleted) {
            return `${user.label} (deleted)`
        } else if (user.disabled) {
            return `${user.label} (disabled)`
        } else {
            return user.label
        }
    }

    componentWillReceiveProps(nextProps) {
        this.updateProps(nextProps)
    }

    updateProps(nextProps) {
        const currentUsers = nextProps.users;
        if (this.state.users.length === 0 && currentUsers.length > 0 && nextProps.value) {
            if (!currentUsers.some(x => x.label === 'Not assigned')) {
                currentUsers.unshift({value: null, label: 'Not assigned', selected: true, color: 'default'});
            }
            const savedStatuses = this.merge(nextProps.statuses, nextProps.value.statuses);
            const savedSchedulerEventTypes = this.merge(mapForSelect(nextProps.schedulerEventTypes), nextProps.value.schedulerEventTypes);
            this.setState({
                    users: currentUsers,
                    statuses: savedStatuses,
                    schedulerEventTypes: savedSchedulerEventTypes,
                    color: nextProps.value.color || 'employee',
                    defaultView: nextProps.value.defaultView,
                    showAllHolidays: nextProps.showAllHolidays
                }
                , () => !this.firstRender.current === true && this.props.onChange(this.state));
        }
    }

    render() {
        const eventTypesWithColor = this.props.schedulerEventTypes;
        let {newSchedulerView, roles, toggleFilter, filterStatuses} = this.props;
        if (window.innerWidth < 992) {
            newSchedulerView = true;
        }
        const {users, statuses, date, color, schedulerEventTypes, colors, showDisabled, showAllHolidays} = this.state;
        this.updateProps(this.props);

        const sortUserFunction = (a, b) => {
            if (a.disabled && !b.disabled) {return 1}
            if (!a.disabled && b.disabled) {return -1}
            if (a.value === null && b.value !== null) {return -1}
            if (a.value !== null && b.value === null) {return 1}
            if (a.label < b.label) {return -1}
            if (a.label > b.label) {return 1}

            return 0;
        }

        let calendarState = {}
        if (!newSchedulerView) {
            return (
                <div>
                    <form role="form" className="scheduler-filter">
                        <div>
                            <FormGroup>
                                <section className="datetime-picker--section mt0">
                                    <Datetime open viewMode="days"
                                              value={date ? date : moment()}
                                              onChange={(date) => this.props.onDateChange(date)}/>
                                </section>
                            </FormGroup>
                        </div>
                        <Row className={!newSchedulerView}>
                            <Col md={!newSchedulerView ? 12 : 12} className="no-horizontal-padding">
                                <Row>
                                    <Col xs={!newSchedulerView ? 12 : 7}>
                                        <FormGroup>
                                            <ControlLabel className="classic-filters pointer"
                                                          onClick={() => toggleFilter("isEmployeeFilterOpened", calendarState)}>
                                                <b>Employees</b>
                                            </ControlLabel>
                                            {filterStatuses.employee &&
                                            <div className="columnDirection full-width">
                                                <Col md={4} className="full-width text-right">
                                                    <ColorCheckbox
                                                        label="disabled"
                                                        onChange={e => {
                                                            this.setState({showDisabled: !showDisabled}, () => this.props.onChange(this.state))
                                                        }}
                                                        className="inline mr-1"
                                                        value={showDisabled}
                                                    />
                                                    <ColorCheckbox
                                                        label="all"
                                                        onChange={e => {
                                                            users.forEach(u => u.selected = e);
                                                            this.setState({users}, () => this.props.onChange(this.state));
                                                        }}
                                                        className="inline"
                                                        value={users.every(u => u.selected)}
                                                    />
                                                </Col>
                                                {filterStatuses.employee && <div className="employee-select">
                                                    {users.sort(sortUserFunction).filter(u => showDisabled ? true : !u.disabled).map((user,i) => {
                                                        const employee = this.props.employees?.find(e => e.employee_id === user.employee_id)
                                                            return <ColorCheckbox
                                                                color={this.props.activeFilter ? user?.color ? user?.color : 'default' : employee?.color ? employee?.color : 'default'}
                                                                value={user?.selected}
                                                                label={this.userLabelFormatter(employee ? employee : user)}
                                                                key={i}
                                                                onChange={e => {
                                                                    user.selected = e;
                                                                    this.setState({users}, () => this.props.onChange(this.state));
                                                                }}
                                                                selectColor={(roles && roles.includes('admin') && !user?.disabled) ? e => {
                                                                    if (this.props.selectedFilter) {
                                                                        user.color = e;
                                                                        employee.color = e;
                                                                        this.setState({users}, () => this.props.onChange(this.state));
                                                                    } else {
                                                                        this.setState({users}, () => this.props.updateEmployeeColor({...employee, color: e, id: employee?.employee_id}, () => this.setState({users: []}, () => this.setState({users}))));
                                                                    }
                                                                } : null}
                                                            />
                                                        }
                                                    )}
                                                </div>}
                                            </div>}
                                        </FormGroup>
                                    </Col>
                                    <Col xs={!newSchedulerView ? 12 : 5} className="no-horizontal-padding">
                                        <FormGroup>
                                            <ControlLabel className="classic-filters pointer"
                                                          onClick={() => toggleFilter("isTypeFilterOpened", calendarState)}>
                                                <b>Type</b>
                                            </ControlLabel>
                                            {filterStatuses.type &&
                                            <Row>
                                                {schedulerEventTypes && schedulerEventTypes.map(s => {
                                                    const eventType = eventTypesWithColor.find(x => x.name === s.label);
                                                    return (<ColorCheckbox label={s.label}
                                                                           value={s.selected}
                                                                           key={s.label}
                                                                           color={eventType && eventType.color}
                                                                           onChange={e => {
                                                                               s.selected = e;
                                                                               this.setState({schedulerEventTypes}, () => this.props.onChange(this.state));
                                                                           }}

                                                    />)
                                                }).map(this.wrapTwoColumns)}
                                            </Row>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={!newSchedulerView ? 12 : 12}>
                                <Row className={!newSchedulerView && 'no-margin'}>
                                    <Col xs={!newSchedulerView ? 12 : 7}
                                         className="no-horizontal-padding no-left-padding no-right-padding">
                                        <FormGroup>
                                            <ControlLabel className="classic-filters pointer"
                                                          onClick={() => toggleFilter("isColorFilterOpened", calendarState)}>
                                                <b>Color</b>
                                            </ControlLabel>
                                            {filterStatuses.color &&
                                            <Row>
                                                {colors.map(c => {
                                                    return <div className="form-check abc-radio"
                                                                onClick={() => {
                                                                    this.setState({color: c}, () => this.props.onChange(this.state));
                                                                }}
                                                    >
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               checked={this.props.value.color === c}
                                                        />
                                                        <label className="form-check-label">
                                                            {c.charAt(0).toUpperCase() + c.slice(1)}
                                                        </label>
                                                    </div>
                                                }).map(this.wrapTwoColumns)}
                                            </Row>}
                                        </FormGroup>
                                    </Col>
                                    <Col xs={!newSchedulerView ? 12 : 5}
                                         className="no-horizontal-padding no-left-padding no-right-padding">
                                        <FormGroup>
                                            <ControlLabel className="classic-filters pointer"
                                                          onClick={() => toggleFilter("isStatusesFilterOpened", calendarState)}>
                                                <b>Statuses</b>
                                            </ControlLabel>
                                            {filterStatuses.statuses &&
                                            <Row>
                                                {this.renderStatuses(statuses, statuses)}
                                            </Row>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={!newSchedulerView ? 12 : 12}>
                                <Row className={!newSchedulerView && 'no-margin'}>
                                    <Col
                                        xs={!newSchedulerView ? 12 : 7}
                                        className="no-horizontal-padding no-left-padding no-right-padding"
                                    >
                                        <FormGroup>
                                            <ControlLabel
                                                className="classic-filters pointer"
                                                onClick={() => toggleFilter("isHolidaySettingsOpened", calendarState)}
                                            >
                                                <b>Holiday Settings</b>
                                            </ControlLabel>
                                            {filterStatuses.holidaySettings &&
                                                <Row>
                                                    <Col className="no-right-padding" xs={12}>
                                                        <div
                                                            className="form-check abc-radio"
                                                            onClick={() => {
                                                                this.setState({showAllHolidays: !showAllHolidays}, () => this.props.onChange(this.state));
                                                            }}
                                                        >
                                                            <input className="form-check-input"
                                                                   type="radio"
                                                                   checked={showAllHolidays}
                                                            />
                                                            <label className="form-check-label">
                                                                Show all holidays
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </div>
            )
        } else {
            return (<div>
                <form role="form" className="scheduler-filter">
                    <Row>
                        <Col md={12} className="no-horizontal-padding">
                            <Row>
                                <Col xs={7}>
                                    <FormGroup>
                                        <Row className='vcenter'>
                                            <Col md={4}>
                                                <ControlLabel><b>Employees:</b></ControlLabel>
                                            </Col>
                                            <Col md={5}>
                                                <ColorCheckbox
                                                    label="disabled"
                                                    onChange={e => {
                                                        this.setState({showDisabled: !showDisabled}, () => this.props.onChange(this.state))
                                                    }}
                                                    className="inline"
                                                    value={showDisabled}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <ColorCheckbox
                                                    label="all"
                                                    onChange={e => {
                                                        users.forEach(u => u.selected = e);
                                                        this.setState({users}, () => this.props.onChange(this.state));
                                                    }}
                                                    className="inline"
                                                    value={users.every(u => u.selected)}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="employee-select">
                                            {users.sort(sortUserFunction).filter(u => showDisabled ? true : !u.disabled).map((user, i) => {
                                                const employee = this.props.employees?.find(e => e.employee_id === user.employee_id)
                                                    return <ColorCheckbox color={employee?.color ? employee.color : 'default'}
                                                                          value={user?.selected}
                                                                          label={this.userLabelFormatter(employee ? employee : user)}
                                                                          key={i}
                                                                          onChange={e => {
                                                                              user.selected = e;
                                                                              this.setState({users}, () => this.props.onChange(this.state));
                                                                          }}
                                                                          selectColor={(roles && roles.includes('admin') && !user?.disabled) ? e => {
                                                                              if (this.props.selectedFilter) {
                                                                                  user.color = e;
                                                                                  employee.color = e;
                                                                                  this.setState({users}, () => this.props.onChange(this.state));
                                                                              } else {
                                                                                  this.setState({users}, () => this.props.updateEmployeeColor({...employee, color: e, id: employee?.employee_id}, () => this.setState({users: []}, () => this.setState({users}))));
                                                                              }
                                                                          } : null}
                                                    />
                                                }
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={5} className="no-horizontal-padding">
                                    <FormGroup>
                                        <ControlLabel><b>Type:</b></ControlLabel>
                                        <Row className='filter-types'>
                                            {schedulerEventTypes && schedulerEventTypes.map(s => {
                                                const eventType = eventTypesWithColor.find(x => x.name === s.label);
                                                return (<ColorCheckbox label={s.label}
                                                                       value={s.selected}
                                                                       key={s.label}
                                                                       color={eventType && eventType.color}
                                                                       onChange={e => {
                                                                           s.selected = e;
                                                                           this.setState({schedulerEventTypes}, () => this.props.onChange(this.state));
                                                                       }}

                                                />)
                                            }).map(this.wrapTwoColumns)}
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col xs={7} className="no-horizontal-padding">
                                    <FormGroup>
                                        <ControlLabel><b>Color:</b></ControlLabel>
                                        <Row>
                                            {colors.map(c => {
                                                return <div className="form-check abc-radio"
                                                            onClick={() => {
                                                                this.setState({color: c}, () => this.props.onChange(this.state));
                                                            }}
                                                >
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           checked={color === c}
                                                    />
                                                    <label className="form-check-label">
                                                        {c.charAt(0).toUpperCase() + c.slice(1)}
                                                    </label>
                                                </div>
                                            }).map(this.wrapTwoColumns)}
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xs={5} className="no-horizontal-padding">
                                    <FormGroup>
                                        <ControlLabel><b>Statuses:</b></ControlLabel>
                                        <Row>
                                            {this.renderStatuses(statuses, statuses)}
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md={7} className="no-horizontal-padding">
                                    <FormGroup>
                                        <ControlLabel><b>Holiday Settings:</b></ControlLabel>
                                        <Row>
                                            <ColorCheckbox
                                                value={showAllHolidays}
                                                label='Show all holidays'
                                                onChange={() => {
                                                    this.setState({showAllHolidays: !showAllHolidays}, () => this.props.onChange(this.state));
                                                }}
                                            />
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>)
        }
    }

    wrapTwoColumns = c => {
        const {newSchedulerView} = this.props;
        return !newSchedulerView ? <Col className="no-right-padding" xs={12}>{c}</Col> : c
    };

    renderStatuses(statuses, slice) {
        return slice.map(status =>
            <div style={{color: status.color}} className="bold" key={status.label}>
                <ColorCheckbox
                    value={status.selected}
                    label={status.label}
                    onChange={e => {
                        status.selected = e;
                        this.setState({statuses}, () => this.props.onChange(this.state));
                    }}
                />
            </div>
        ).map(this.wrapTwoColumns);
    }
}

Filter.propTypes = {
    users: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    schedulerEventTypes: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onDateChange: PropTypes.func,
    value: PropTypes.object,
};

Filter.defaultProps = {
    statuses: schedulerEventStatuses()
};

const mapStateToProps = (state) => ({
    roles: state.auth.roles,
    client_id: state.auth.client_id
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
