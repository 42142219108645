import React from "react"
import {
    Button,
    Col,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Row,
    Tooltip,
    Badge, FormControl,
} from "react-bootstrap"
import {Link} from "react-router"
import {LinkContainer} from "react-router-bootstrap"
import * as actions from "./CustomerProposalAPI"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import LeadsFilterModal from "../../LeadsPage/LeadsFilterModal";
import Select from "react-select"
import moment from "moment"
import SplitPane from "react-split-pane";
import {Circle, GoogleMap, Marker, Polygon, Polyline, Rectangle} from "react-google-maps"
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import Dollars from "../../../components/Dollars"
import {BootstrapTable, TableHeaderColumn, SizePerPageDropDown} from "react-bootstrap-table"
import AssetEditorModal from "../../AssetEditorModal"
import {getIcon, getLabel} from "../../MapViewPage/IconProvider";
import {
    checkRectangleCoordinates,
    defaultDateFormatShort,
    colors,
    defaultDateFormat,
    mapForSelect,
    select
} from "../../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ResourceComponent from "../../../components/ResourceComponent";
import './CustomerProposal.css';
import {debounce} from "throttle-debounce";
import {getBasicAssetMarker} from "../../../utilities";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const mdScreen = window.screen.width <= 1600;
const xlScreen = window.screen.width >= 1600;

class WorkHistory extends ResourceComponent {
    constructor(props) {
        super(props);

        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            },
        };

        this.state = {
            showFilterModal: false,
            customer: null,
            customerProposals: [],
            customerProposal: null,
            customerSites: [],
            customerAssets: [],
            selectedSite: null,
            resource: {
                customer_id: null,
                site_id: "all",
                per_page: 25,
                page: 1,
            },
        };
        this.delayedSearch = debounce(1000, this.searchCustomerProposal);
    }

    componentDidMount() {
        const {searchForCustomer} = this.props.actions;
        this.customerSearch = this.buildSearchComponent('customer_id', searchForCustomer);
        if (this.props.params.id) {
            let {resource} = this.state;
            resource.customer_id = this.props.params.id;
            this.setState(resource, this.reload)
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const {customer_id, site_id} = this.state.resource;
        const {customerAssets, resource} = this.state;

        if (prevState.site_id !== site_id && site_id === null) {
            resource.site_id = 'all'
            this.setState(resource, this.reload)
        }

        if (prevState.customer_id !== customer_id) {
            const coordinates = customerAssets.map(asset => ({
                longitude: asset.longitude,
                latitude: asset.latitude
            }))[0];
            this.resetMap(coordinates);
        }
    }

    searchCustomerProposal = () => {
        let {resource} = this.state;
        const {fetchProposals} = this.props.actions;

        resource.page = 1

        fetchProposals(resource, results => {
            this.setState({customerProposals: results.content, total: results.total})
        })
    }

    reload = () => {
        const {fetchCustomerById, fetchProposals, fetchCustomerSites, fetchCustomerAssets} = this.props.actions;
        const {customer_id} = this.state.resource;
        let {resource} = this.state;
        fetchCustomerById(customer_id, (customer) => this.setState({customer: customer}));
        fetchProposals(resource, (results) => {
            this.setState({
                customerProposals: results.content,
                customerProposal: results.content[0],
                total: results.total
            })
        });
        fetchCustomerSites(customer_id, (sites) => {
            this.setState({
                customerSites: sites,
                resource: resource,
                selectedSite: resource.site_id
            }, () => fetchCustomerAssets(resource.site_id && resource.site_id !== 'all' ? resource.site_id : sites.map(site => site.id), (assets) => this.setState({customerAssets: assets})));
        });
    };

    getProposals() {
        this.props.actions.fetchProposals({...this.state.resource}, results => {
            this.setState({
                customerProposals: results.content,
                customerProposal: results.content[0],
                total: results.total
            })
        });
    };

    cancelModal = siteId => {
        const {customerSites, resource} = this.state
        const {fetchCustomerAssets, doneEditingAsset} = this.props.actions;
        doneEditingAsset();
        fetchCustomerAssets(resource.site_id && resource.site_id !== 'all' ? resource.site_id : customerSites.map(site => site.id), (assets) => this.setState({customerAssets: assets}));
    };

    onMarkerClick = id => {
        this.props.actions.onMarkerClick(id)
    }

    indexN = (cell, row, enumObject, index) => <div>{index + 1}</div>;

    resetMap = site => {
        const siteValue = site === null || site === undefined;
        if (siteValue) {
            return false
        }
        window._googleMapComponent && window._googleMapComponent.panTo && window._googleMapComponent.panTo({
            lat: site.latitude,
            lng: site.longitude
        })
    };

    dataFormat = (field, row) => {
        const start = row.promised_date_start;
        const end = row.promised_date_end;

        if (!end || !start) return field;

        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format(defaultDateFormat)} - {moment(end).format(defaultDateFormat)}
                    </div>
                </div>
            )
        }
        return field
    };

    renderLeadCreated = (leadDate) => {
        const leadCreated = leadDate && leadDate !== null ?
            <div className="serviceParamsWrapper"><span
                className="serviceDetails mr-10">{moment(leadDate).format(defaultDateFormat)}</span>
            </div> : null;

        const tooltipPlacement = isMobile ? "left" : "right";

        return (
            <div className="text-left">
                {leadDate && leadDate !== null ?
                    <div className="space-between"><span style={{color: 'blue'}}>Lead created:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{leadCreated}</Tooltip>}><span><Badge
                        className="proposalBadge font10">1</Badge><br/></span></OverlayTrigger>
                    </div> : null}
            </div>
        );
    };

    renderServicesCount = (proposalServices) => {
        const leadCreated = proposalServices && proposalServices.length > 0 && proposalServices[0].leadDate !== null ?
            <div className="serviceParamsWrapper"><span
                className="serviceDetails mr-10">{moment(proposalServices[0].leadDate).format(defaultDateFormat)}</span>
            </div> : null;
        const createdServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.created_at !== null ?
            <div className={ps.created_at[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.created_at).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const proposedServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.proposal_date !== null ?
            <div className={ps.proposal_date[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.proposal_date).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const acceptedServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.accepted_date !== null ?
            <div className={ps.accepted_date[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.accepted_date).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const promisedFromServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.promisedStartDate !== null ?
            <div className={ps.promisedStartDate[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.promisedStartDate).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const promisedToServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.promisedEndDate !== null ?
            <div className={ps.promisedEndDate[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.promisedEndDate).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const woServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.workOrderDate !== null ?
            <div className={ps.workOrderDate[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.workOrderDate).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const scheduledServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.scheduled_date !== null ?
            <div className={ps.scheduled_date[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.scheduled_date).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const completedServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.completedDate !== null ?
            <div className={ps.completedDate[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.completedDate).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const invoicedServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.invoiced_at !== null ?
            <div className={ps.invoiced_at[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper">
                    <span
                        className="serviceDetails mr-10">{moment(ps.invoiced_at).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);
        const paidServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.paid_at !== null ?
            <div className={ps.paid_at[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.paid_at).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span></div>
            </div> : null);
        const declinedServices = proposalServices && proposalServices.length > 0 && proposalServices.sort((a, b) => a.number - b.number).map((ps) => ps.declined_date !== null ?
            <div className={ps.declined_date[0] ? "serviceInfo" : "serviceInfo top7"}>
                <span><strong>#{ps.number} {ps.name}</strong></span>
                <div className="serviceParamsWrapper"><span
                    className="serviceDetails mr-10">{moment(ps.declined_date).format(defaultDateFormat)}</span>/<span
                    className="ml-10 mr-10">{ps.hours}h</span>/<span
                    className="ml-10"><Dollars amount={ps.amount}/></span>
                </div>
            </div> : null);

        const tooltipPlacement = isMobile ? "left" : "right";

        return (
            <div className="text-left">
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.leadDate).length > 0 ?
                    <div className="space-between"><span style={{color: 'blue'}}>Lead created:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{leadCreated}</Tooltip>}><span><Badge
                        className="proposalBadge font10">1</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.created_at).length > 0 ?
                    <div className="space-between"><span style={{color: '#87571c'}}>Created:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{createdServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.created_at !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.proposal_date).length > 0 ?
                    <div className="space-between"><span style={{color: '#e87e27'}}>Proposed:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{proposedServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.proposal_date !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.accepted_date).length > 0 ?
                    <div className="space-between"><span style={{color: '#1bb307'}}>Accepted:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{acceptedServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.accepted_date !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.promisedStartDate).length > 0 ?
                    <div className="space-between"><span style={{color: '#3ec0c7'}}>Promised from:</span><OverlayTrigger
                        placement={tooltipPlacement} overlay={<Tooltip>{promisedFromServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.promisedStartDate !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.promisedEndDate).length > 0 ?
                    <div className="space-between"><span style={{color: '#912abd'}}>Promised to:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{promisedToServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.promisedEndDate !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.workOrderDate).length > 0 ?
                    <div className="space-between"><span style={{color: '#b80d70'}}>W/O created:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{woServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.workOrderDate !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.scheduled_date).length > 0 ?
                    <div className="space-between"><span style={{color: '#6e0a0f'}}>Scheduled:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{scheduledServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.scheduled_date !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.completedDate).length > 0 ?
                    <div className="space-between"><span style={{color: '#4f07b3'}}>Completed:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{completedServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.completedDate !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.invoiced_at).length > 0 ?
                    <div className="space-between"><span style={{color: '#3196de'}}>Invoiced:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{invoicedServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.invoiced_at !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.paid_at).length > 0 ?
                    <div className="space-between"><span style={{color: '#523600'}}>Paid:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{paidServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.paid_at !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
                {proposalServices && proposalServices.length > 0 && proposalServices.filter(ps => ps.declined_date).length > 0 ?
                    <div className="space-between"><span style={{color: '#c73030'}}>Declined:</span><OverlayTrigger
                        placement={tooltipPlacement}
                        overlay={
                            <Tooltip>{declinedServices}</Tooltip>}><span><Badge
                        className="proposalBadge font10">{proposalServices.filter(ps => ps.declined_date !== null).length}</Badge><br/></span></OverlayTrigger>
                    </div> : null}
            </div>
        );
    };

    eventsFormatter = (cell, row) => (
        <div>
            <span>{this.renderLeadCreated(row.leadDate)}</span>
            <span>{this.renderServicesCount(row.proposalServices)}</span>
        </div>
    );

    rowFormatter = (cell, row, enumObject, index) => (
        <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{this.indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{this.linkFormatter(row.proposalNo, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.proposalTitle}</Col>
            </Row>
            <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{this.siteFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Events</Col>
                <Col xs={6}>{this.eventsFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>${row.proposalTotal}</Col>
            </Row>
        </div>
    );

    dollarFormatter = cell => <Dollars amount={cell}/>;

    dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort);

    linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown"
            >
                <LinkContainer to={`/mapview/${row.id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.id}`}>
                    <MenuItem>Proposal Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
                <MenuItem divider/>
                <LinkContainer to={`/work_orders/${row.id}`}>
                    <MenuItem>Manage Work Orders</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    );

    siteFormatter(cell, row) {
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>{row.siteName}</strong>
                </div>
                <div>
                    {row.siteAddress}
                </div>
            </div>
        );
    }

    returnTableData = () => {
        const {selectedSite, customerProposals} = this.state;
        if (selectedSite && selectedSite !== 'all') {
            return customerProposals.filter(proposal => proposal.siteId === selectedSite).length;
        }
        return customerProposals.length;
    };

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (polygon.type_polygon === 'polyLine') {
            return 0.75
        } else {
            return 0.5
        }
    }

    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.latitude
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.longitude
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.label)}
                strokeColor={"#ffffff"}
                onClick={() => this.onMarkerClick(ass.id)}
                zIndex={1000}
            />
        )
    }

    render() {
        const {showFilterModal, customerProposals, customerSites, total, customer, resource, customerAssets, selectedSite} = this.state;
        const {editingAssetId, client} = this.props;
        const {fetchCustomerAssets, updateAssetAttribute} = this.props.actions;
        const {customer_id, site_id, page, per_page} = resource;
        const customerSitesOptions = mapForSelect(customerSites);
        const customerOptions = this.customerSearch.searchOptions();
        const tableData = selectedSite && selectedSite !== 'all' ? customerProposals.filter(proposal => proposal.siteId === selectedSite) : customerProposals;
        const coordinates = customerAssets && customerAssets?.map(asset => ({
            longitude: asset.longitude,
            latitude: asset.latitude
        }))[0];
        const customerId = customer_id ? customer_id : null;
        if (customerId === null) return <span> I need a customer_id parameter. I can't go on </span>;
        const customerID = +customerId;

        if (customer === null) return <span> Loading... </span>;

        return (
            <Grid fluid id="customerProposal">
                <div>
                    <Row>
                        <Col md={12}>
                        </Col>
                    </Row>
                    <Row>
                        <div style={{
                            display: window.screen.width === 768 ? 'flex' : null,
                            justifyContent: window.screen.width === 768 ? 'space-between' : null
                        }}>
                            <Col md={window.screen.width < 1600 ? 4 : 6}
                                 className={isMobile ? "bottom15 vcenter" : "vcenter"}>
                                <div style={{display: isMobile ? null : 'flex'}}>
                                    <h2 className={isMobile && !smMobile ? 'nmt-5 bottom0' : "no-top-margin mr-9 "}>Customer
                                        Proposals</h2>
                                    <h5 className={smMobile ? "text-primary nmt-10" : isMobile && !smMobile ? "text-primary mt0" : "mt13 text-primary"}>{total} returned</h5>
                                </div>
                            </Col>
                            <Col md={window.screen.width < 1600 ? 5 : 3}
                                 style={{display: 'flex', flexDirection: 'column', marginBottom: isMobile ? 15 : 0}}>
                                <div>
                                    <div>
                                        <strong>Billing Address:</strong>
                                        {" "}
                                        {customer.address_1},
                                        {customer.address_2}{" "}
                                        {customer.city}, {customer.state}{" "}
                                        {customer.zip}
                                    </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <h5 style={{display: 'inline-block'}} className="mr25">
                                        <span style={{color: "#f89406"}}>Customer Arborist:</span>
                                        {" "}
                                        {customer.person_id ? `${customer.person_id && customer.person_id.first_name} ${customer.person_id && customer.person_id.last_name}` : "None Assigned"}
                                    </h5>
                                    <Link to={`/lead?customer_id=${customerID}`}><h5 className="findTimeText">FIND A
                                        TIME</h5></Link>
                                </div>
                            </Col>
                        </div>
                        <Col md={3} className="justify-flex-end">
                            <div className="mr-9">
                                {customerSites.length > 0 ?
                                    customer && selectedSite === 'all' ?
                                        <LinkContainer
                                            to={`/mapview?customer_id=${customer.id}`}>
                                            <Button bsStyle="primary">New Proposal <FontAwesomeIcon icon="plus"
                                                                                                    className="small ml-2"/></Button>
                                        </LinkContainer>
                                        :
                                        customer && selectedSite !== 'all' ?
                                            <LinkContainer
                                                to={`/mapview?customer_id=${customer.id}&site_id=${selectedSite}`}>
                                                <Button bsStyle="primary">New Proposal <FontAwesomeIcon icon="plus"
                                                                                                        className="small ml-2"/></Button>
                                            </LinkContainer> : null
                                    :
                                    <Button bsStyle="primary" disabled={true}>New Proposal <FontAwesomeIcon icon="plus"
                                                                                            className="small ml-2"/></Button>
                                }
                            </div>
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => this.setState({showFilterModal: !showFilterModal})}>
                                Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                            </Button>
                        </Col>
                    </Row>
                    {isMobile &&
                    <Row className="bottom10">
                        <Col md={5}>
                            <FormControl
                                name="q"
                                placeholder="Search..."
                                value={resource.q}
                                onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                            />
                        </Col>
                    </Row>
                    }
                </div>
                <hr className={'mb-10-mt-0'}/>
                <LeadsFilterModal
                    title="Customer Proposal Filter"
                    show={showFilterModal}
                    onHide={() => this.setState({showFilterModal: !showFilterModal})}
                    closeButton={() => this.setState({showFilterModal: !showFilterModal})}
                >
                    <Row className="nomarginLR mt-15">
                        <Col>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    value={{value: customer.id, label: customer.name}}
                                    options={customerOptions}
                                    placeholder="Customer"
                                    onInputChange={this.customerSearch.search}
                                    onChange={this.selectResourceAttr('customer_id', () => {
                                        this.setState({resource: {...this.state.resource, site_id: null}}, this.reload);
                                    })}
                                    isDisabled
                            />
                        </Col>
                    </Row>
                    <Row className="no-margin">
                        <Col className='bottom15' style={{marginBottom: smMobile ? "calc(50vh - 80px)" : 90}}>
                            {customerSites && customer &&
                            <Row>
                                <Col md={12}>
                                    <Select
                                        className="Select bottom15 top15"
                                        classNamePrefix="select"
                                        name="select_site"
                                        value={select(customerSitesOptions, site_id)}
                                        options={customerSitesOptions}
                                        onChange={this.selectResourceAttr('site_id', () => {
                                            this.setState({resource: {...this.state.resource, page: 1}}, this.reload);
                                        })}
                                        placeholder="Select site"
                                        isClearable
                                    />
                                </Col>
                            </Row>
                            }
                        </Col>
                    </Row>
                </LeadsFilterModal>
                {isMobile ?
                    <Row className="paddingMapMobile nmargin-lr10">
                        <div>
                            <div className="bottom15">
                                <BootstrapTable
                                    data={tableData}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    pagination={true}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource},
                                                    this.getProposals);
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: total
                                                }],
                                            sizePerPage: per_page
                                        }
                                    }
                                >
                                    <TableHeaderColumn hidden dataField="id" isKey={true} dataSort={true}>
                                        ID
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="id"
                                        dataFormat={this.rowFormatter}
                                    >
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                        <div>
                            <Col>
                                <Row>
                                    <Col md={12}>
                                        {customerAssets && customerAssets.length ?
                                            <GoogleMapLoader
                                                containerElement={
                                                    <div
                                                        id="work_map_view"
                                                        className="proposalMap"
                                                        style={{
                                                            height: "500px",
                                                            width: "100%",
                                                        }}
                                                    />
                                                }
                                                googleMapElement={
                                                    <GoogleMap
                                                        ref={it => (window._googleMapComponent = it)}
                                                        defaultZoom={20}
                                                        mapTypeId="hybrid"
                                                        tilt={0}
                                                        defaultCenter={
                                                            customerAssets
                                                                ? {
                                                                    lat: customerAssets[0].latitude,
                                                                    lng: customerAssets[0].longitude
                                                                }
                                                                : ((client && client.organization_longitude) ?
                                                                {
                                                                    lat: client.organization_latitude,
                                                                    lng: client.organization_longitude
                                                                }
                                                                : {
                                                                    lat: 40.0,
                                                                    lng: -105.0
                                                                })
                                                        }
                                                    >
                                                        {customerAssets && customerAssets.map((ass, i) => {
                                                            const polygon = ass.polygons.find(p => p.chosen)

                                                            if (polygon && polygon.type_polygon !== 'marker') {
                                                                const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                                                const opacity = this.setShapeOpacity(ass)

                                                                if (polygon.type_polygon === 'circle') {
                                                                    return (<><Circle
                                                                        defaultOpacity={1}
                                                                        onClick={() => this.onMarkerClick(ass.id)}
                                                                        defaultCenter={{
                                                                            lat: polygon.polygon_points[0].lat,
                                                                            lng: polygon.polygon_points[0].lng
                                                                        }}
                                                                        editable={false}
                                                                        label={ass.label}
                                                                        options={{
                                                                            fillOpacity: opacity,
                                                                            strokeColor: color,
                                                                            fillColor: color
                                                                        }}
                                                                        radius={polygon.radius}
                                                                        key={i}
                                                                    />
                                                                        {this.markerPolygon(ass)}
                                                                    </>)
                                                                } else if (polygon.type_polygon === 'polyLine') {
                                                                    return (<><Polyline
                                                                        onClick={() => this.onMarkerClick(ass.id)}
                                                                        path={polygon.polygon_points}
                                                                        key={i}
                                                                        label={ass.label}
                                                                        options={{
                                                                            strokeOpacity: opacity,
                                                                            strokeColor: color,
                                                                            fillColor: color
                                                                        }}
                                                                        editable={false}
                                                                    />
                                                                        {this.markerPolygon(ass)}
                                                                    </>)
                                                                } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                                                    return (<><Rectangle
                                                                        onClick={() => this.onMarkerClick(ass.id)}
                                                                        bounds={{
                                                                            north: polygon.polygon_points[0].lat,
                                                                            south: polygon.polygon_points[3].lat,
                                                                            east: polygon.polygon_points[1].lng,
                                                                            west: polygon.polygon_points[0].lng
                                                                        }}
                                                                        key={i}
                                                                        label={ass.label}
                                                                        options={{
                                                                            fillOpacity: opacity,
                                                                            strokeColor: color,
                                                                            fillColor: color
                                                                        }}
                                                                        editable={false}
                                                                    />
                                                                        {this.markerPolygon(ass)}
                                                                    </>)
                                                                } else {
                                                                    return (<>
                                                                        <Polygon
                                                                            onClick={() => this.onMarkerClick(ass.id)}
                                                                            path={polygon.polygon_points}
                                                                            key={i}
                                                                            label={ass.label}
                                                                            options={{
                                                                                fillOpacity: opacity,
                                                                                strokeColor: color,
                                                                                fillColor: color
                                                                            }}
                                                                            editable={false}
                                                                        />
                                                                        {this.markerPolygon(ass)}
                                                                    </>)
                                                                }
                                                            } else {
                                                                return (
                                                                    <Marker
                                                                        key={ass.id}
                                                                        position={{lat: ass.latitude, lng: ass.longitude}}
                                                                        zIndex={null}
                                                                        icon={getBasicAssetMarker({asset: ass, label: ass.label})}
                                                                        title={
                                                                            ass.plant_name +
                                                                            (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                                                        }
                                                                        onClick={() => this.onMarkerClick(ass.id)}
                                                                    >
                                                                    </Marker>

                                                                )
                                                            }
                                                        })}
                                                    </GoogleMap>
                                                }
                                            />
                                            :
                                            <span>No assets to map</span>
                                        }
                                    </Col>
                                </Row>
                                {coordinates && this.resetMap(coordinates)}
                            </Col>
                        </div>
                        <div className="bottom10">
                            <Col>
                                {editingAssetId !== null
                                    ? <AssetEditorModal
                                        editingAssetId={editingAssetId}
                                        doneEditingAsset={this.cancelModal}
                                        updateAssetAttribute={updateAssetAttribute}
                                        onSuccess={this._onSuccess}
                                    />
                                    : null}
                            </Col>
                        </div>
                    </Row>
                    :
                    <Row>
                        <SplitPane split="vertical" defaultSize={"50%"} minSize={500}>
                            <div>
                                <div>
                                    <Row className="tableHeader">
                                        <Col md={7}/>
                                        <Col md={5}>
                                            <FormControl
                                                name="q"
                                                placeholder="Search..."
                                                value={resource.q}
                                                onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                                            />
                                        </Col>
                                    </Row>
                                    <BootstrapTable
                                        data={tableData}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        pagination={true}
                                        fetchInfo={{dataTotalSize: total}}
                                        trStyle={{height: customerProposals && customerProposals.length === 1 ? '180px' : null}}
                                        options={
                                            {
                                                onPageChange: (page, per_page) => {
                                                    let {resource} = this.state;
                                                    resource = {...resource, page, per_page};
                                                    this.setState({resource},
                                                        this.getProposals);
                                                },
                                                page,
                                                sizePerPageList: [{
                                                    text: '10', value: 10
                                                }, {
                                                    text: '25', value: 25
                                                },
                                                    {
                                                        text: '30', value: 30
                                                    },
                                                    {
                                                        text: '50', value: 50
                                                    },
                                                    {
                                                        text: '100', value: 100
                                                    },
                                                    {
                                                        text: '200', value: 200
                                                    },
                                                    {
                                                        text: 'All', value: total
                                                    }],
                                                sizePerPage: per_page,
                                                sizePerPageDropDown: this.renderSizePerPageDropDown
                                            }
                                        }
                                    >
                                        <TableHeaderColumn hidden dataField="id" isKey={true} dataSort={true}>
                                            ID
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="10%"
                                            dataField="proposalNo"
                                            dataFormat={this.linkFormatter}
                                            dataSort={true}
                                            columnClassName="menu-show-fixer"
                                        >
                                            Proposal #
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="15%"
                                            dataField="proposalTitle"
                                            dataSort={true}
                                            dataAlign={"left"}
                                        >
                                            Title
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            tdStyle={{whiteSpace: 'normal'}}
                                            thStyle={{whiteSpace: 'normal'}}
                                            width="15%"
                                            dataFormat={this.siteFormatter}
                                            dataSort={true}
                                            dataAlign={"left"}
                                        >
                                            Site
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="15%"
                                            dataField="arborist"
                                            dataSort={true}
                                            dataAlign={"left"}
                                        >
                                            Arborist
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width={mdScreen ? "19%" : xlScreen ? "14%" : "16%"}
                                            dataField="proposalServices"
                                            dataFormat={this.eventsFormatter}
                                            dataSort={true}
                                            dataAlign={"left"}
                                        >
                                            Events
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="10%"
                                            dataField="proposalTotal"
                                            dataFormat={this.dollarFormatter}
                                            dataSort={true}
                                            dataAlign={"right"}
                                        >
                                            Cost
                                        </TableHeaderColumn>

                                    </BootstrapTable>
                                </div>
                            </div>
                            <div>
                                <Col>
                                    <Row>
                                        <Col md={12}>
                                            {customerAssets && customerAssets.length ?
                                                <GoogleMapLoader
                                                    containerElement={
                                                        <div
                                                            id="work_map_view"
                                                            className="proposalMap"
                                                            style={{
                                                                height: "500px",
                                                                width: "100%",
                                                            }}
                                                        />
                                                    }
                                                    googleMapElement={
                                                        <GoogleMap
                                                            ref={it => (window._googleMapComponent = it)}
                                                            defaultZoom={20}
                                                            mapTypeId="hybrid"
                                                            tilt={0}
                                                            defaultCenter={
                                                                customerAssets
                                                                    ? {
                                                                        lat: customerAssets[0].latitude,
                                                                        lng: customerAssets[0].longitude
                                                                    }
                                                                    : ((client && client.organization_longitude) ?
                                                                    {
                                                                        lat: client.organization_latitude,
                                                                        lng: client.organization_longitude
                                                                    }
                                                                    : {
                                                                        lat: 40.0,
                                                                        lng: -105.0
                                                                    })
                                                            }
                                                        >
                                                            {customerAssets && customerAssets.map((ass, i) => {
                                                                const polygon = ass.polygons.find(p => p.chosen)

                                                                if (polygon && polygon.type_polygon !== 'marker') {
                                                                    const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                                                    const highlighted = colors[51]
                                                                    const opacity = this.setShapeOpacity(ass)

                                                                    if (polygon.type_polygon === 'circle') {
                                                                        return (<><Circle
                                                                            defaultOpacity={1}
                                                                            onClick={() => this.onMarkerClick(ass.id)}
                                                                            defaultCenter={{
                                                                                lat: polygon.polygon_points[0].lat,
                                                                                lng: polygon.polygon_points[0].lng
                                                                            }}
                                                                            editable={false}
                                                                            label={ass.label}
                                                                            options={{
                                                                                fillOpacity: opacity,
                                                                                strokeColor: color,
                                                                                fillColor: color
                                                                            }}
                                                                            radius={polygon.radius}
                                                                            key={i}
                                                                        />
                                                                            {this.markerPolygon(ass)}
                                                                        </>)
                                                                    } else if (polygon.type_polygon === 'polyLine') {
                                                                        return (<><Polyline
                                                                            onClick={() => this.onMarkerClick(ass.id)}
                                                                            path={polygon.polygon_points}
                                                                            key={i}
                                                                            label={ass.label}
                                                                            options={{
                                                                                strokeOpacity: opacity,
                                                                                strokeColor: color,
                                                                                fillColor: color
                                                                            }}
                                                                            editable={false}
                                                                        />
                                                                            {this.markerPolygon(ass)}
                                                                        </>)
                                                                    } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                                                        return (<><Rectangle
                                                                            onClick={() => this.onMarkerClick(ass.id)}
                                                                            bounds={{
                                                                                north: polygon.polygon_points[0].lat,
                                                                                south: polygon.polygon_points[3].lat,
                                                                                east: polygon.polygon_points[1].lng,
                                                                                west: polygon.polygon_points[0].lng
                                                                            }}
                                                                            key={i}
                                                                            label={ass.label}
                                                                            options={{
                                                                                fillOpacity: opacity,
                                                                                strokeColor: color,
                                                                                fillColor: color
                                                                            }}
                                                                            editable={false}
                                                                        />
                                                                            {this.markerPolygon(ass)}
                                                                        </>)
                                                                    } else {
                                                                        return (<>
                                                                            <Polygon
                                                                                onClick={() => this.onMarkerClick(ass.id)}
                                                                                path={polygon.polygon_points}
                                                                                key={i}
                                                                                label={ass.label}
                                                                                options={{
                                                                                    fillOpacity: opacity,
                                                                                    strokeColor: color,
                                                                                    fillColor: color
                                                                                }}
                                                                                editable={false}
                                                                            />
                                                                            {this.markerPolygon(ass)}
                                                                        </>)
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <Marker
                                                                            key={ass.id}
                                                                            position={{lat: ass.latitude, lng: ass.longitude}}
                                                                            zIndex={null}
                                                                            icon={getBasicAssetMarker({asset: ass, label: ass.label})}
                                                                            title={
                                                                                ass.plant_name +
                                                                                (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                                                            }
                                                                            onClick={() => this.onMarkerClick(ass.id)}
                                                                        >
                                                                        </Marker>

                                                                    )
                                                                }
                                                            })}
                                                        </GoogleMap>
                                                    }
                                                />

                                                : <span>No assets to map</span>}
                                        </Col>
                                    </Row>
                                    {coordinates && this.resetMap(coordinates)}
                                </Col>
                            </div>
                        </SplitPane>
                        <div>
                            <Col>
                                {editingAssetId !== null
                                    ? <AssetEditorModal
                                        editingAssetId={editingAssetId}
                                        doneEditingAsset={this.cancelModal}
                                        updateAssetAttribute={updateAssetAttribute}
                                        onSuccess={fetchCustomerAssets}
                                    />
                                    : null}
                            </Col>
                        </div>
                    </Row>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        editingAssetId: state.mapView.editingAssetId,
        client: state.client.customerInfo
    }
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkHistory)
