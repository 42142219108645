import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {browserHistory, Link} from "react-router";
import ProposalMenu from "./ProposalMenu";
import * as mapViewActions from "./MapViewPageApi";
import {faPiedPiperHat} from '@fortawesome/fontawesome-free-brands'
import {
    Button,
    Checkbox,
    Clearfix,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    Modal,
    ModalFooter,
    OverlayTrigger,
    Panel,
    Row,
    Tooltip,
} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import {
    colors,
    dashboardStatuses,
    defaultDateFormat,
    defaultTimeFormat,
    formatInlineDate,
    mapForSelect,
    round,
    select,
    sortAssetsLabels,
    swapElement
} from "../../../common/commonHandlers";
import Select from "react-select";
import Datetime from "react-datetime";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import SplitPane from 'react-split-pane';
import {debounce} from "throttle-debounce";
import Map from "./Map";
import Dollars from "../../../components/Dollars";
import './MapViewPage.scss';
import InlineEditable from "../../Scheduler/InlineEditable";
import ProposalServiceForm from "./ProposalServiceForm";
import moment from "moment/moment";
import AssetEditorModal from "./AssetEditorModal";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {addAlert} from "../../App/actions";
import PlantName from "../../../components/PlantName";
import RateModal from './RateModal';
import Attachment from "../../../components/image/Attachment";
import '../../../components/email/dropzone.css';
import AssignedArboristModal from "../../AssignedArboristModal/AssignedArboristModal";
import {isAssignedArborist} from "../../AssignedArboristModal/AssignedArboristModalApi"
import _ from 'lodash'
import InactiveModal from "./InactiveModal";
import MDSpinner from "react-md-spinner";
import AssetsList from "./AssetsList";
import ConfirmProposalDeletionModal from "./ConfirmProposalDeletionModal";
import * as turf from '@turf/turf'
import tokml from 'tokml'
import JSZip from 'jszip'
import {saveAs} from 'file-saver'

const actions = {...mapViewActions, isAssignedArborist, addAlert};
const isMobile = window.screen.width <= 1024;
const isNotMobile = window.screen.width >= 1024;

class MapViewPage extends ResourceComponent {
    panelMinWidth = 992;

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.delayedAssetSearch = debounce(500, this.refreshAssetSearch);
    }

    initialState = () => ({
        resource: {proposal_services: [], proposal_date: round(moment())},
        employees: [],
        customerSearchResults: [],
        services: [],
        bundleServices: [],
        priorities: [],
        equipments: [],
        site: null,
        options: [
            {value: true, label: 'Active'},
            {value: false, label: 'Inactive'},
            {value: 'included', label: 'Included'},
            {value: 'excluded', label: 'Excluded'}
        ],
        active: true,
        search: '',
        proposalService: null,
        loaded: moment(),
        assets: [],
        asset: null,
        isPanelOpen: true,
        isPanelVisible: false,
        contextLoaded: false,
        windowWidth: null,
        proposalServiceCompact: window.innerWidth > 992 && window.innerWidth < 1025,
        showLabels: true,
        setProposed: false,
        proposalServiceStatuses: [],
        moveToProposed: null,
        panelWidth: 500,
        isPsTotalChanged: false,
        isPsModalOpened: false,
        currentInvoiceStatus: '',
        editedPs: null,
        isStreetView: false,
        showNewAssets: false,
        activeBundleServicesCreator: false,
        selectedServices: [],
        selectedBundleService: null,
        BSCreating: false,
        showAssetErrorModal: false,
        showFixAssetsModal: false,
        errorAssetNumbers: [],
        showAssignedArboristModal: false,
        lockedMapAssetMovement: false,
        showInactiveModal: false,
        showBundleServicesWarningModal: false,
        allSelected: false,
        selectedMarkerId: null,
        oldAssets: [],
        remountMapKey: 1,
        anyCircleMoved: false,
        isLoading: false,
        showProposalDeletionModal: false,
        showMyLocation: true,
        myLocationMarker: null,
    });

    state = this.initialState();

    reload = (id) => {
        const {assets} = this.state

        if (id) {
            this.props.actions.loadProposal(id, this.state.showNewAssets,
                result => {
                    if (result.resource.proposalInfo.doc_order) {
                        let order = JSON.parse(result.resource.proposalInfo.doc_order);
                        result.resource.proposalInfo.customerFiles = result.resource.proposalInfo.customerFiles.map(file => {
                            file.order = order.findIndex((o) => o === file.id)
                            if (file.order === -1) {
                                file.order = order.length + file.id
                            }
                            return file
                        })
                    } else {
                        result.resource.proposalInfo.customerFiles = result.resource.proposalInfo.customerFiles.map((file, index) => {
                            file.order = index
                            return file
                        })
                    }
                    const resource = result.resource;
                    if (resource.proposal_services.length > 0 && resource.proposal_services.map(ps => ps.service_status_name === 'Draft').filter(ps => ps === true).length === resource.proposal_services.length) {
                        this.setState({moveToProposed: true});
                    } else {
                        this.setState({moveToProposed: false})
                    }
                    if (resource.proposal_services > 0) {
                        resource.proposal_services = resource.proposal_services.sort(function (a, b) {
                            return new Date(b.created_at) - new Date(a.created_at);
                        })
                    }
                    resource.proposal_date = moment(resource.proposal_date);
                    result.oldAssets = _.cloneDeep(result.assets.map(a => ({...a, backup: true})))

                    if (assets && assets.length > 0) {
                        result.assets = result.assets.map(a => {
                            const index = assets.findIndex(asset => asset.id === a.id);
                            if (index > -1) {
                                a.checked = assets[index].checked
                            }
                            return a
                        })
                    }
                    this.setState({
                        ...result,
                        isLoading: false
                    }, () => setTimeout(this.setState({loaded: moment()})), 500);
                })
        } else {
            this.setState({...this.initialState(), isLoading: false}, this.componentDidMount);
        }
    };

    onEmailSend = (id) => {
        this.props.actions.reloadProposal(id, result => {
            const resource = result.resource;
            if (resource.proposal_services.length > 0 && resource.proposal_services.map(ps => ps.service_status_name === 'Draft').filter(ps => ps === true).length === resource.proposal_services.length) {
                this.setState({moveToProposed: true});
            } else {
                this.setState({moveToProposed: false})
            }
            if (resource.proposal_services > 0) {
                resource.proposal_services = resource.proposal_services.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
            }
            resource.proposal_date = moment(resource.proposal_date);
            this.setState({resource})
        })
    }

    updateDimensions = () => {
        if (window.innerWidth < this.panelMinWidth) {
            this.setState({isPanelOpen: true, windowWidth: window.innerWidth})
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fullScreen} = this.props;
        const {resource, isStreetView, assets, active} = this.state;
        const leftPanel = document.querySelector(`.Pane .vertical .Pane1`)
        const middlePanel = document.getElementsByClassName('Pane')[0]
        const bodyPanel = document.getElementsByClassName('Pane')[1]
        const leadMap = document.querySelector(`#leadMap`)
        const wrapper = document.getElementsByClassName(`ui`)[0]
        const proposalPage = document.querySelector('#proposal-page')
        const gmBtn = document.querySelector(`.gm-bundled-control`)
        const resizersArr = document.getElementsByClassName(`Resizer`)
        const resizers = resizersArr && [...resizersArr]

        if (prevProps.fullScreen !== fullScreen && fullScreen && isNotMobile) {
            if (leftPanel && middlePanel && resizers && leadMap && wrapper && proposalPage && gmBtn && bodyPanel) {
                leftPanel.style.display = 'none'
                middlePanel.style.display = 'none'
                leadMap.style.margin = '0 !important'
                wrapper.style.overflow = 'hidden'
                proposalPage.style.padding = 0
                gmBtn.style.margin = '10px 20px 10px 10px'
                resizers.forEach(resizer => resizer.style.display = 'none')
                bodyPanel.style.paddingTop = 0
            }
        } else if (prevProps.fullScreen !== fullScreen && !fullScreen && isNotMobile) {
            if (leftPanel && middlePanel && resizers && leadMap && wrapper && proposalPage && gmBtn && bodyPanel) {
                leftPanel.style.display = 'block'
                middlePanel.style.display = 'block'
                leadMap.style.margin = '0 !important'
                wrapper.style.overflow = 'inherit'
                proposalPage.style.padding = '0 15px 0 15px'
                gmBtn.style.margin = '10px'
                resizers.forEach(resizer => resizer.style.display = 'block')
                bodyPanel.style.paddingTop = '10px'
            }
        }

        if ((prevState.isStreetView !== isStreetView) && !isStreetView) {
            this.reload(resource.id)
        }
        if (prevState.assets !== assets) {
            if (assets) {
                let assetNumbers = []
                assets.map(asset => {
                    if (((!asset.longitude && asset.longitude !== 0) || (!asset.latitude && asset.latitude !== 0)) || this.checkPolygonsCoords(asset)) {
                        this.setState({showAssetErrorModal: true})
                        assetNumbers.push(asset.label)
                        this.setState({errorAssetNumbers: assetNumbers})
                    }
                })
            }
        }

        if (prevState.active !== active) {
            this.setState({
                assets: assets.map(a => {
                    a.checked = false
                    return a
                })
            })
        }

        if (this.props.params.proposalId !== prevProps.params.proposalId) {
            this.componentDidMount()
            this.setState({asset: null})
        }
    }

    refreshAssetSearch = (search) => {
        const newState = {search: search};
        if (search === '') {
            //!!! we need it otherwise markers won't appear back
            newState.loaded = moment();
        }
        this.setState(newState);
    }

    proposalServiceTitle = (ps, selectedServices, changeSelectedService) => {
        const {
            priorities,
            services,
            bundleServices,
            proposalService,
            proposalServiceCompact,
            panelWidth,
            activeBundleServicesCreator
        } = this.state;
        const servicesOptions = mapForSelect(services);
        const bundleServicesOptions = mapForSelect(bundleServices);
        const prioritiesOptions = mapForSelect(priorities);
        const service = select(ps.is_bundle_service ? bundleServicesOptions : servicesOptions, ps.service_id)
        const priority = select(prioritiesOptions, ps.priority_ids);
        const selectedAssets = ps?.asset_numbers && ps?.asset_numbers.length > 0 ? ps.asset_numbers?.map(number => this.getSiteAssets().find(a => a.asset_number === number)?.label).sort((a, b) => a - b) : []

        const length = selectedAssets.length > 0 ? (selectedAssets.length < 5 ? selectedAssets.join(', ') :
            <span><b>{selectedAssets.length}</b> assets</span>) : 'no assets';

        return (
            <div className={activeBundleServicesCreator && `service_tab`}>
                {activeBundleServicesCreator &&
                    <Checkbox className='bundle_services_checkbox'
                              type="checkbox"
                              checked={selectedServices.includes(ps.id)}
                              onChange={changeSelectedService}
                    />
                }
                <Row className="pointer"
                     onClick={() => {
                         this.setState({proposalService: proposalService || activeBundleServicesCreator ? null : ps})
                         activeBundleServicesCreator && changeSelectedService()
                     }}>
                    {!proposalServiceCompact && panelWidth >= 450 ?
                        <div className="flex-nowrap">
                            <Col md={1} className="text-nowrap">
                                <span># {ps.order_number_in_proposal}</span>
                            </Col>
                            <Col md={2} style={{display: 'flex', wordBreak: 'break-word', minWidth: '100px'}}>
                                <p className="">{service && service.label}</p>
                            </Col>
                            <Col md={2} className={!isMobile && "text-center"}>
                                {length}
                            </Col>
                            <Col md={1}>
                                {ps.man_hours} {ps.man_hours > 1 ? 'hrs' : 'hr'}
                            </Col>
                            <Col md={3}>
                                {ps.priorities && ps.priorities.length > 0 &&
                                    ps.priorities.map(p => <span
                                        key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '} </span>)}
                            </Col>
                            <Col md={3}>
                                <Col md={6} style={{minWidth: '70px'}}>
                                    {ps.service_status_name}
                                </Col>
                                <Col md={6}>
                                    <Dollars amount={ps.service_total}/>
                                </Col>
                                <Col md={12} className="word-break">
                                    PO: {ps.purchase_order}
                                </Col>
                            </Col>
                        </div>
                        : !proposalServiceCompact && panelWidth < 450 ?
                            <div className="flex-column-start ml-6">
                                <Row className="vertical-align">
                                    <Col md={12}># {ps.service_number}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Name: {service && service.label}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Assets: {length}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Hours: {ps.man_hours} {ps.man_hours > 1 ? 'hrs' : 'hr'}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Priority: {priority && priority.label}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Status: {ps.service_status_name}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>PO: {ps.purchase_order}</Col>
                                </Row>
                                <Row className="vertical-align">
                                    <Col md={12}>Total: <Dollars amount={ps.service_total}/></Col>
                                </Row>
                            </div>
                            :
                            <div>
                                <Col md={6}>
                                    <div># {ps.service_number}</div>
                                    <div>{service && service.label}</div>
                                    <div>{length}</div>
                                </Col>
                                <Col md={6}>
                                    <div>{ps.man_hours} {ps.man_hours > 1 ? 'hrs' : 'hr'}</div>
                                    <div>{priority && priority.label}</div>
                                    <div>{ps.service_status_name}</div>
                                    <Dollars amount={ps.service_total}/>
                                </Col>
                            </div>
                    }
                </Row>
            </div>)
    };
    defaultSize = (i) => {
        const isTablet = window.innerWidth > 992 && window.innerWidth < 1025;
        const {user} = this.state;
        const mapping = {
            'Map': isTablet ? 250 : 500,
            'Services': this.state.isPanelOpen ? (isTablet ? 350 : 400) : (isTablet ? 350 : 500),
            'Assets': 250
        };
        return mapping[user.map_view_settings[i]];
    };
    handleWrapServiceTitle = e => {
        this.setState({proposalServiceCompact: e < 400})
    };

    search = e => {
        e !== '' && this.props.actions.searchForCustomer(e, customerSearchResults => {
            this.setState({customerSearchResults})
        })
    };

    assetLabel(a) {
        return (
            <span>
                {a.label + ' - ' + (a.plant_count > 1 ? ' [' + a.plant_count + ']' : "")}
                <PlantName plant={a.plant_name}/>
            </span>
        )
    }

    selectSite = (e) => {
        this.selectResourceAttr('site_id', () => {
            if (e) {
                this.props.actions.loadSite(e.value, site => {
                    this.setState({site, assets: site.assets, loaded: moment(), inactiveSite: site.inactive});
                });
            } else {
                this.setState({site: null, assets: [], loaded: moment()});
            }
        })(e);
    };

    componentDidMount() {
        const id = this.props.params.proposalId;
        this.setState({isPanelOpen: id === undefined, isPanelVisible: id === undefined, isLoading: true});
        const {resource} = this.state;
        const query = this.props.location.query;
        const leadId = query.lead_id;
        const customerId = query.customer_id && parseInt(query.customer_id)
        const siteId = query.site_id && parseInt(query.site_id);

        if (customerId) {
            this.props.actions.getCustomer(customerId, async result => {
                this.search(result.name)
                if (result.person_id) {
                    resource.person_id = result?.person_id?.id
                } else {
                    this.props.actions.getCustomerLastProposalArborist(customerId, async result => {
                        resource.person_id = await result?.person?.id
                    })
                }
                this.setState({inactiveCustomer: result.inactive})
            })
        }

        this.props.actions.load(customerId, result => {
            this.setState({...result, contextLoaded: true});
            resource.customer_id = customerId;
            resource.site_id = siteId;
            if (id) {
                if (result.user.mapview_asset_movement_lock_settings) {
                    let lockMode = result.user.mapview_asset_movement_lock_settings.includes(parseInt(id))
                    if (lockMode) {
                        this.setState({lockedMapAssetMovement: lockMode})
                    }
                }
                if (resource.proposal_services.length > 0 && resource.proposal_services.map(ps => ps.service_status_name === 'Draft').filter(ps => ps === true).length === resource.proposal_services.length) {
                    this.setState({moveToProposed: true}, () => this.reload(id));
                } else {
                    this.setState({moveToProposed: false}, () => this.reload(id));
                }
            } else if (leadId) {
                this.props.actions.loadLead(leadId, result => {
                    const lead = result.lead;
                    resource.lead_id = lead.id;
                    resource.customer_id = lead.customer_id;
                    resource.site_id = lead.site_id;
                    resource.person_id = lead.person_id;
                    this.setState({...result, resource});
                });
            } else {
                const {userRoles} = this.props;
                if (userRoles.includes('sales_arborist')) {
                    resource.person_id = result.user.person_id;
                    this.setState({...result, resource});
                } else {
                    if (!resource.person_id) {
                        resource.person_id = result.user.person_id;
                    }
                    this.setState({resource: resource});
                }
            }
            if (customerId) {
                this.props.actions.getCustomerLastProposalSite(customerId, async result => {
                    resource.site_id = await result?.site?.id
                    this.setState({resource: resource, inactiveSite: result?.site?.inactive});
                })
            }
        });
    }

    newProposalService = () => {
        const {resource} = this.state;
        let highestServiceNumber = 1
        let highestOrder = 1

        if (resource.proposal_services?.length > 0) {
            const lastProposalService = resource.proposal_services.sort((a, b) => a.service_number - b.service_number).slice(-1)[0]
            if (lastProposalService.is_bundle_service) {
                highestServiceNumber = Math.max(...lastProposalService.bundle_services.map(ps => ps.service_number)) + 1
            } else {
                highestServiceNumber = lastProposalService.service_number + 1
            }
            highestOrder = Math.max(...resource.proposal_services.map(ps => ps.order_number_in_proposal)) + 1
        }
        return {
            service_number: highestServiceNumber,
            order_number_in_proposal: highestOrder,
            proposal_service_status_id: this.state.proposalServiceStatuses.find(pss => pss.label === 'Draft').value
        };
    };

    proposalServicesFooter = () => {
        const {resource, panelWidth, isLoading} = this.state;
        const total = resource.proposal_services.reduce((sum, ps) => sum + parseFloat(ps.service_total), 0);
        return <Row>
            <Col md={6}>
                {resource?.proposalInfo?.site_moved ?
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="add-new-proposal-service">Site has been moved to another
                            customer</Tooltip>}>
                        <span>
                            <Button
                                bsSize="small"
                                bsStyle="success"
                                disabled
                                style={{pointerEvents: 'none'}}
                            >
                                <span>{panelWidth < 300 ? "Add New" : "Add New Proposal Service"}</span>
                            </Button>


                        </span>
                    </OverlayTrigger>
                    :
                    <Button
                        disabled={isLoading}
                        onClick={() => this.setState({proposalService: this.newProposalService()})}
                        bsSize="small"
                        bsStyle="success"
                    >
                        <span>{panelWidth < 300 ? "Add New" : "Add New Proposal Service"}</span>
                    </Button>
                }
            </Col>
            <Col md={6} className="text-success text-right">
                <strong>
                    Total: {<Dollars amount={total}/>}
                </strong>
            </Col>
        </Row>
    };

    getSelectedAssets = () => {
        return this.getSiteAssets().filter(a => a.checked);
    };

    proposalServicesHeader = () => {
        const {resource, proposalService, panelWidth, isLoading} = this.state;
        return <Row className="vcenter hcenter proposal_services_header_wrapper">
            <Col md={6} className='proposal_services_header'>
                <h5>{resource.proposal_services.filter(x => !x._destroy).length} proposal
                    services</h5>
                <Button
                    bsSize="small"
                    className='bundle_services_button'
                    disabled={isLoading}
                    onClick={() => {
                        let service_statuses = ["Proposed", "Accepted", "In Work Order", "Completed", "Invoiced",
                            "Paid", "Payment", "Scheduled", "Declined", "Not Offered"]
                        this.state.resource.proposal_services.map(ps => {
                            if (service_statuses.includes(ps.service_status_name)) {
                                this.setState({showBundleServicesWarningModal: true})
                            }
                        })
                        this.setState({activeBundleServicesCreator: true, proposalService: null})
                    }}
                >
                    Bundle Services
                </Button>
            </Col>
            <Col xs={12} md={6}
                 className="text-right buttons"
                 style={{display: 'flex', justifyContent: panelWidth < 350 ? null : "flex-end"}}>
                <Row className={`nomarginLR full-width ${isLoading ? "disabled-region" : ""}`}>
                    <Col xs={12} md={12} className="vertical-align text-left"
                         style={{justifyContent: window.innerWidth < 400 ? 'flex-start' : 'flex-end'}}>
                        <Link className="text-success pointer" onClick={this.onSave}>
                            <FontAwesomeIcon icon="check"
                                             className="small-margin"/>Save
                        </Link>
                        <Link className="text-danger pointer"
                              onClick={this.cancel}>
                            <FontAwesomeIcon icon="times"
                                             className="small-margin"/>Cancel
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    };

    bundleServiceCreatorHeader = () => {
        const {selectedServices, selectedBundleService, bundleServices, BSCreating} = this.state
        const optionsBundleServices = mapForSelect(bundleServices.filter(s => !s.disabled))
        const optionsBundleServicesAll = mapForSelect(bundleServices)


        const handleCloseCreator = () => {
            this.setState({
                activeBundleServicesCreator: false,
                selectedBundleService: null,
                selectedServices: [],
                BSCreating: false
            })
        }

        const handleSave = () => {
            this.createBundleService(() => {
                this.saveProposal(() => {
                    handleCloseCreator()
                });
            })
        }

        const handleSelectBundleService = (e) => this.setState({selectedBundleService: e.value})

        return <>
            <Row>
                <Col xs={6}>
                    <h4>Bundle service creator</h4>
                </Col>
                <Col xs={6} className={`text-right ${BSCreating ? 'disabled-region' : ''}`}>
                    <Link
                        className={`text-success pointer ${selectedServices.length === 0 || selectedBundleService === null ? 'disabled-region' : ''}`}
                        onClick={handleSave}>
                        <FontAwesomeIcon icon="check"
                                         className="small-margin"/>Save
                    </Link>
                    <Link className="text-danger pointer"
                          onClick={handleCloseCreator}>
                        <FontAwesomeIcon icon="times"
                                         className="small-margin"/>Cancel
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h5>Selected services: {selectedServices.length}</h5>
                </Col>
            </Row>
            <Row className="vcenter">
                <Col xs={12}>
                    <Select className="Select"
                            classNamePrefix="select"
                            options={optionsBundleServices}
                            isDisabled={BSCreating}
                            value={select(optionsBundleServicesAll, selectedBundleService)}
                            onChange={handleSelectBundleService}
                            placeholder='Select bundle service...'
                    />
                </Col>
            </Row>
        </>
    }

    saveBSOrder = (resource) => {
        this.props.actions.saveBSOrder(resource)
    }

    saveProposalService = (callback, mode = null) => (ps) => {
        const {resource} = this.state;
        if (!resource.person_id) {
            this.props.actions.addAlert({message: "Select arborist to create proposal service"});
        } else if (!ps.service_id) {
            this.props.actions.addAlert({message: "Select service to add new proposal service"});
        } else if (ps.asset_ids.length === 0 && !ps.is_bundle_service) {
            this.props.actions.addAlert({message: "Add at least one asset to save proposal service"});
        } else {
            const index = resource.proposal_services.findIndex(p => p.service_number === ps.service_number);
            if (index > -1) {
                if (mode === 'duplicate') {
                    this.setState({isLoading: true})
                    let tempBundleServices = []

                    const lastProposalService = resource.proposal_services.sort((a, b) => a.service_number - b.service_number).slice(-1)[0]
                    let nextServiceNumber = 1

                    if (lastProposalService.is_bundle_service) {
                        nextServiceNumber = Math.max(...lastProposalService?.bundle_services?.map(bs => bs.service_number)) + 1
                    } else {
                        nextServiceNumber = lastProposalService.service_number + 1
                    }

                    if (ps.is_bundle_service) {
                        ps.bundle_services.forEach((s, i) => {
                            tempBundleServices.push({
                                ...s,
                                id: null,
                                service_status_name: "Draft",
                                service_number: nextServiceNumber + i
                            })
                        })
                        if (ps.id) {
                            let duplicatePS = {
                                ...ps,
                                id: null,
                                service_status_name: "Draft",
                                service_number: nextServiceNumber,
                                deleteable: true,
                                bs_services_attributes: tempBundleServices,
                                duplicateId: ps.id,
                                order_number_in_proposal: Math.max(...resource.proposal_services.map(ps => ps.order_number_in_proposal)) + 1,
                            }
                            this.props.actions.duplicatePS(duplicatePS, (res) => {
                                duplicatePS = {
                                    ...duplicatePS,
                                    id: res.id,
                                    bundle_services: res.bundle_services,
                                    bs_services_attributes: []
                                }
                                resource.proposal_services.push(duplicatePS);
                                callback()
                            })
                        }
                    } else {
                        if (ps.id) {
                            let duplicatePS = {
                                ...ps,
                                id: null,
                                duplicateId: ps.id,
                                service_number: nextServiceNumber,
                                service_status_name: "Draft",
                                deleteable: true,
                                order_number_in_proposal: Math.max(...resource.proposal_services.map(ps => ps.order_number_in_proposal)) + 1,
                            }
                            this.props.actions.duplicatePS(duplicatePS, (res) => {
                                duplicatePS = {...duplicatePS, ...res}
                                resource.proposal_services.push(duplicatePS);
                                callback()
                            })


                        }
                    }

                } else {
                    resource.proposal_services[index] = {...resource.proposal_services[index], ...ps}
                }
            } else {
                resource.proposal_services.push(ps);
            }
            resource.proposal_services.sort((a, b) => a.service_number > b.service_number);
            if (mode === 'duplicate') {
                this.setState({resource});
            } else {
                this.setState({resource}, callback);
            }
        }
    };

    updateOrderNumberProposal = (proposal_services) => {
        let i = 1
        return proposal_services.sort((a, b) => a.service_number - b.service_number).map(ps => {
            if (!ps._destroy) {
                ps = {
                    ...ps,
                    order_number_in_proposal: i
                }
                i += 1
            }
            return ps
        })
    }

    createBundleService = (callback) => {
        const {
            resource,
            selectedServices,
            bundleServices,
            selectedBundleService,
        } = this.state;
        const {actions} = this.props;

        if (!resource.person_id) {
            actions.addAlert({message: "Select arborist to create proposal service"});
        } else if (!selectedBundleService) {
            this.props.actions.addAlert({message: "Select bundle service to add new proposal bundle service"});
        } else if (selectedServices.length < 1) {
            actions.addAlert({message: "Add at least one service to save bundle service"});
        } else {
            let manHoursBS = 0
            let serviceTotalBS = 0
            let tempServices = []

            this.setState({BSCreating: true})

            resource.proposal_services.forEach((s) => {
                if (selectedServices.includes(s.id)) tempServices.push({...s})
            })

            tempServices = tempServices.sort((a, b) => (a.service_number > b.service_number) ? 1 : -1).map((s) => {
                s.id = null
                s.proposal_id = null
                s.proposal_service_status_id = 10
                manHoursBS += s.man_hours
                serviceTotalBS += parseFloat(s.service_total)
                return s
            })

            const aveSalaryRateBS = Math.round(((serviceTotalBS / manHoursBS) + Number.EPSILON) * 100) / 100
            manHoursBS = Math.round((manHoursBS + Number.EPSILON) * 100) / 100
            serviceTotalBS = Math.round((serviceTotalBS + Number.EPSILON) * 100) / 100

            for (let i = 0; i < resource.proposal_services.length; i++) {
                if (selectedServices.includes(resource.proposal_services[i].id)) {
                    resource.proposal_services[i]._destroy = true;
                    resource.proposal_services[i]._skip_when_bundle_child = true;
                }
            }

            const childBundleProposalServices = resource.proposal_services?.filter(ps => ps._destroy) || []
            const minServiceNumber = childBundleProposalServices?.length > 0 ? Math.min(...childBundleProposalServices.map((o) => +o.service_number)) : 1

            const bs = bundleServices.filter(bs => bs.id === selectedBundleService)[0]

            const bundleServiceData = {
                asset_ids: [],
                ave_salary_rate: aveSalaryRateBS.toString(),
                equipment_ids: [],
                man_hours: manHoursBS,
                notes: bs.skills,
                priority_id: [],
                proposal_service_status_id: 10,
                scheduler_events: [],
                service_id: bs.id,
                service_number: minServiceNumber,
                service_total: serviceTotalBS,
                bs_services_attributes: tempServices,
                is_bundle_service: true
            }

            resource.proposal_services.push(bundleServiceData);
            resource.proposal_services = this.updateOrderNumberProposal(resource.proposal_services)
            this.setState({resource}, callback);
        }
    };

    deleteBundleService = bs_id => {
        this.setState({isLoading: true})
        const {resource} = this.state;
        const bs_idx = resource.proposal_services.findIndex(ps => ps.id === bs_id)

        resource.proposal_services[bs_idx]._destroy = true

        let tempServices = []

        resource.proposal_services[bs_idx].bundle_services.forEach((s) => {
            tempServices.push({...s})
        })

        resource.proposal_services[bs_idx].bundle_services.forEach((s) => {
            s._destroy = true
            s._skip_when_bundle_child = true
        })

        resource.proposal_services[bs_idx].bs_services_attributes = resource.proposal_services[bs_idx].bundle_services

        tempServices = tempServices.map((s, i) => {
            s.id = null
            s.proposal_id = resource.proposal_services[bs_idx].proposal_id
            s.proposal_service_status_id = 10
            s._skip_when_bundle_child = true
            return s
        })

        resource.proposal_services = [...resource.proposal_services, ...tempServices]
        resource.proposal_services = this.updateOrderNumberProposal(resource.proposal_services)

        this.setState({resource, proposalService: null}, () => this.saveProposal(() => {
            this.setState({isLoading: false})
        }));
    }

    deleteProposalService = ps => {
        this.setState({isLoading: true})
        const {proposalService, resource} = this.state;
        proposalService._destroy = true;
        if (proposalService.is_bundle_service) {
            const bundlePsToDelete = proposalService.bundle_services.map(
                bs => {
                    bs._skip_when_bundle_child = true
                    return bs
                }
            )
            proposalService.bs_services_attributes = bundlePsToDelete
        }
        resource.proposal_services = this.updateOrderNumberProposal(resource.proposal_services.filter(s => s.id !== ps.id))
        this.props.actions.savePSOrder(resource.proposal_services, false, () => {
            this.setState({
                resource,
                proposalService: null
            }, this.props.actions.deleteProposalService(ps.id, () => this.reload(ps.proposal_id)));
        })
    };

    deleteServiceFromBS = (bs_id, s_id) => {
        this.setState({isLoading: true})
        const {resource} = this.state;

        const bs_idx = resource.proposal_services.findIndex(ps => ps.id === bs_id)

        if (resource.proposal_services[bs_idx].bundle_services.length <= 1) {
            this.deleteBundleService(bs_id)
        } else {
            const s_idx = resource.proposal_services[bs_idx].bundle_services.findIndex(s => s.id === s_id)
            let tempService = {...resource.proposal_services[bs_idx].bundle_services[s_idx]}

            resource.proposal_services[bs_idx].bs_services_attributes = resource.proposal_services[bs_idx].bundle_services
            resource.proposal_services[bs_idx].bs_services_attributes[s_idx]._destroy = true

            tempService.id = null
            tempService.proposal_id = resource.proposal_services[bs_idx].proposal_id
            tempService.proposal_service_status_id = 10
            tempService._skip_when_bundle_child = true

            const assetLabels = resource.proposal_services[bs_idx].bundle_services.filter(ps => !ps._destroy).map((o) => +o.service_number)
            const newLabel = Math.min(...assetLabels)
            resource.proposal_services[bs_idx].service_number = newLabel

            resource.proposal_services.push(tempService)

            const restServicesAttributes = resource.proposal_services[bs_idx].bs_services_attributes.filter(bs => bs.id !== s_id)
            const newServiceTotal = restServicesAttributes.reduce((a, v) => a = a + +v.service_total, 0)
            const newManHours = restServicesAttributes.reduce((a, v) => a = a + +v.man_hours, 0)
            resource.proposal_services[bs_idx].service_total = newServiceTotal
            resource.proposal_services[bs_idx].man_hours = newManHours
            resource.proposal_services[bs_idx].ave_salary_rate = newServiceTotal / newManHours

            resource.proposal_services = this.updateOrderNumberProposal(resource.proposal_services)
            this.setState({
                resource,
                proposalService: null
            }, () => this.saveProposal(() => this.setState({isLoading: false})));
        }
    }

    renderProposalService = (value) => {
        const {proposalService, selectedServices} = this.state;
        const expanded = proposalService && value.order_number_in_proposal === proposalService.order_number_in_proposal;

        const handleChangeSelectedService = () => {
            if (selectedServices.includes(value.id)) {
                this.setState({selectedServices: selectedServices.filter(s => s !== value.id)})
            } else {
                this.setState({selectedServices: [...selectedServices, value.id]})
            }
        }

        return <Panel
            key={value.key}
            ref={el => (this.container = el)}
            className={`proposal-form-panel ${value.is_bundle_service ? "proposal-form-panel__bundle" : ""}`}
            collapsible
            header={this.proposalServiceTitle(value, selectedServices, handleChangeSelectedService)}
            expanded={expanded}
        >
            {expanded &&
                <ProposalServiceForm
                    ps={value}
                    compareValues={this.compareValues}
                    {...this.getProposalServiceFormProps()}
                />}
        </Panel>
    };


    renderNormalProposalServices = () => {
        const {resource, activeBundleServicesCreator} = this.state;
        const services = _.cloneDeep(resource.proposal_services.filter(
            x => !x._destroy && !(activeBundleServicesCreator && (x.is_bundle_service || !(x.service_status_name === 'Draft')))))
            .sort((a, b) => a.service_number - b.service_number);

        return services.map(x => this.renderProposalService(x));
    };
    renderSortableProposalServices = () => {
        const {resource, proposalService, activeBundleServicesCreator} = this.state;
        const isSortable = (service) => service ? service.deleteable === false || proposalService === service : true
        const SortableItem = SortableElement(({value}) => this.renderProposalService(value));
        const SortableList = SortableContainer(({items}) =>
            <div>
                {items.map((service, i) => (
                    <SortableItem
                        disabled={isSortable(service) || (isSortable(items[i - 1]) && isSortable(items[i + 1]))}
                        key={i} sortIndex={service.service_number}
                        index={i} value={service}
                        collection={isSortable(service) || (isSortable(items[i - 1]) && isSortable(items[i + 1])) ? 1 : 0}
                    />
                ))}
            </div>
        );
        let services = _.cloneDeep(resource.proposal_services.filter(
            x => !x._destroy && !(activeBundleServicesCreator && (x.is_bundle_service || !(x.service_status_name === 'Draft')))))
            .sort((a, b) => a.service_number - b.service_number);

        return <SortableList pressDelay={500} items={services} onSortEnd={({oldIndex, newIndex}) => {
            let sortableArray = services.map(service => isSortable(service))
            if (oldIndex > newIndex ? sortableArray.slice(newIndex, oldIndex).some(el => el) : sortableArray.slice(oldIndex, newIndex).some(el => el)) {
                return
            }

            swapElement(services, oldIndex, newIndex);
            let newServiceNumber = 1
            services = services.map((ps, i) => {
                if (ps.is_bundle_service) {
                    ps.bundle_services.forEach((bs, index) => {
                        bs.service_number = newServiceNumber
                        if (index === 0) {
                            ps.service_number = newServiceNumber
                        }
                        newServiceNumber += 1
                    })
                } else {
                    ps.service_number = newServiceNumber
                    newServiceNumber += 1
                }
                return ps
            });

            services = services.map((ps, i) => {
                ps.order_number_in_proposal = i + 1
                return ps
            });
            resource.proposal_services = services
            this.setState({resource}, () => this.savePSOrder());
        }}/>
    };

    savePSOrder = () => {
        const {proposal_services} = this.state.resource
        this.props.actions.savePSOrder(proposal_services)
    }

    getProposalServiceFormProps = () => {
        const {
            services,
            priorities,
            equipments,
            isPsTotalChanged
        } = this.state;
        return {
            getSelectedAssets: this.getSelectedAssets,
            getAllAssets: () => {
                return this.getSiteAssets()
            },
            onSave: this.saveProposalService(() => {
                if (isPsTotalChanged === true) {
                    this.setState({isPsModalOpened: true})
                } else {
                    this.saveProposal();
                }
                this.setState({proposalService: null});
            }),
            onSaveBSOrder: this.saveBSOrder,
            onSaveAndNew: this.saveProposalService(() => {
                if (isPsTotalChanged === true) {
                    this.setState({isPsModalOpened: true})
                } else {
                    this.saveProposal();
                }
                this.setState({proposalService: this.newProposalService()});
            }),
            onDuplicate: this.saveProposalService(() => {
                this.setState({isLoading: false})
                this.setState({proposalService: null})
            }, 'duplicate'),
            onDelete: this.deleteProposalService,
            onDeleteBS: this.deleteBundleService,
            onDeleteServiceFromBS: this.deleteServiceFromBS,
            priorities: priorities,
            services: services,
            equipments: equipments,
            submit: (submit) => (this.submitProposalService = submit)
        };
    };

    renderProposalServices = () => {
        const {resource, proposalService, activeBundleServicesCreator, BSCreating, isLoading} = this.state;
        const anyExpanded = (proposalService || false);
        const newProposalService = anyExpanded && !resource.proposal_services
            .some(ps => ps.service_number === proposalService.service_number);
        return <>
            <div className='loading-spinner-ps'>
                {isLoading ? <MDSpinner size={70} borderSize={2}/> : <></>}
            </div>
            <Panel
                style={{display: this.props.fullScreen && 'none'}}
                footer={!activeBundleServicesCreator && this.proposalServicesFooter()}
                header={activeBundleServicesCreator ? this.bundleServiceCreatorHeader() : this.proposalServicesHeader()}
                className={`remaining ${isLoading ? 'disabled-region' : ''}`}
            >
                <Row>
                    <Col md={12}>
                        <div className={`services ${activeBundleServicesCreator && 'bundle_services'}`}>
                            <Row>
                                {BSCreating ? <Col md={12}>Bundle service is being created...</Col> :
                                    <Col md={12}>
                                        {anyExpanded ? this.renderNormalProposalServices() : this.renderSortableProposalServices()}
                                        {newProposalService && <Panel
                                            className="proposal-form-panel"
                                            collapsible
                                            expanded
                                            header={"New Proposal Service"}
                                        >
                                            <ProposalServiceForm
                                                ps={proposalService}
                                                compareValues={this.compareValues}
                                                {...this.getProposalServiceFormProps()}
                                            />
                                        </Panel>}
                                    </Col>}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Panel>
        </>
    };

    assetClick = (a) => () => {
        setTimeout(() => this.selectMarker('assets', (e, x) => e.id === x.id, () => this.setState({loaded: moment()}))(a), 100);
    };

    updateOpacity = (val) => {
        this.setState({opacity: val});
    }

    compareValues = (currentInputValue, currentStateValue, invoiceStatus, editedPs) => {
        if (invoiceStatus) {
            const isNotPsAccepted = (invoiceStatus === "Draft" || invoiceStatus === "Proposed" || invoiceStatus === "Declined" || invoiceStatus === "Not Offered");
            const isValueChanged = currentInputValue !== currentStateValue

            this.setState({isPsTotalChanged: false})

            if (isValueChanged && !isNotPsAccepted && editedPs) {
                this.setState({isPsTotalChanged: true, currentInvoiceStatus: invoiceStatus, editedPs: editedPs})
            }
        }
    }

    attachmentName = (file) => {
        return <span className="proposal_attachment_name" target="_blank">
            {file.name.substring(0, 20)}
            {file.name.length > 20 && '...'}
        </span>
    }

    checkPolygonsCoords = (asset) => {
        if (Array.isArray(asset.polygons) && asset.polygons.length > 0) {
            return asset.polygons.some(p => p.polygon_points.some(pp => (
                (pp.lat ? !pp.lat && pp.lat !== 0 : !pp.latitude && pp.latitude !== 0) ||
                (pp.lng ? !pp.lng && pp.lng !== 0 : !pp.longitude && pp.longitude !== 0)
            )))
        } else {
            return false
        }
    }

    setAssetChecked = (asset) => {
        let {assets} = this.state
        let oldAsset = assets.find(a => a.id === asset.id);
        if (oldAsset) {
            const newAssets = assets.map(a => a.id === oldAsset.id ? {...oldAsset, checked: asset.checked} : a)
            this.setState({assets: newAssets})
        }
    }

    renderAssets = () => {
        const {options, search, active, site} = this.state;
        const disabledAssetsAmount = this.state.assets?.length > 0 && this.state.assets.filter(asset => asset.disabled)?.length
        let assets = this.getFilteredAssets();
        const isMobile = window.innerWidth < this.panelMinWidth;

        return <div
            style={{display: this.props.fullScreen && 'none', height: "100%"}}
            className={`top10 ${isMobile ? "" : "small-margin"}`}>
            <FormGroup bsSize="small">
                <Select className="Select" classNamePrefix="select"
                        name="active"
                        value={select(options, active)}
                        options={options}
                        onChange={e => this.setState({active: e ? e.value : null})}
                        placeholder="All"
                        isClearable
                />
            </FormGroup>
            <FormGroup bsSize="small">
                <FormControl
                    type="text"
                    placeholder="Search..."
                    name={"search_input"}
                    onChange={e => {
                        let value = e.target?.value
                        this.delayedAssetSearch(value)
                    }}
                />
            </FormGroup>
            <Row>
                <Col xs={12} className={`${isMobile ? "d-flex" : ""}`}>
                    <Row className="vertical-align">
                        <Col xs={6} className={`${isMobile ? "full-width" : ""}`}>
                            <ColorCheckbox value={this.getSelectedAssets().length === this.getFilteredAssets().length}
                                           onChange={e => {
                                               this.getSiteAssets().forEach(a => a.checked = false);
                                               this.getFilteredAssets().forEach(a => a.checked = e);
                                               site.assets = site.assets.sort((a, b) => sortAssetsLabels(a, b, 'label'))
                                               this.setState({site});
                                           }}
                                           name={"select_all"}
                                           label="Select All"/>
                        </Col>
                        <Col xs={6}
                             className={`${isMobile ? "full-width no_text_wrap" : ""}`}><b>{this.getSelectedAssets().length}</b> ({assets.length})
                            Selected</Col>
                    </Row>
                    <Row className={`${isMobile ? "vertical-align" : ""}`}>
                        <Col md={6}/>
                        <Col md={6}
                             className={`${isMobile ? "full-width no_text_wrap" : ""}`}><b>{disabledAssetsAmount}</b> ({this.state.assets?.length})
                            Disabled</Col>
                    </Row>
                </Col>
            </Row>
            {isMobile ?
                <Panel className="asset-form-panel">
                    <AssetsList
                        searchString={this.state.search}
                        selectedId={this.state.selectedMarkerId}
                        allSelected={this.getSelectedAssets().length === this.getFilteredAssets().length}
                        assets={assets}
                        assetClick={c => this.assetClick(c)}
                        site={site}
                        setAssetChecked={(asset) => this.setAssetChecked(asset)}
                        setState={s => this.setState(s)}
                        checkPolygonsCoords={c => this.checkPolygonsCoords(c)}
                    />
                </Panel>
                :
                <AssetsList
                    searchString={this.state.search}
                    selectedId={this.state.selectedMarkerId}
                    allSelected={this.getSelectedAssets().length === this.getFilteredAssets().length}
                    assets={assets}
                    assetClick={c => this.assetClick(c)}
                    site={site}
                    setAssetChecked={(asset) => this.setAssetChecked(asset)}
                    setState={s => this.setState(s)}
                    checkPolygonsCoords={c => this.checkPolygonsCoords(c)}
                />
            }
        </div>
    };

    getFilteredAssets = () => {
        const {resource, search, active} = this.state;
        let assets = this.getSiteAssets();
        const proposalAssets = resource.proposal_services.flatMap(ps => {
            if (ps.is_bundle_service) {
                if (ps.bundle_services && ps.bundle_services.length > 0) {
                    return ps.bundle_services.flatMap(bs => bs.asset_ids)
                } else if (ps.bs_services_attributes && ps.bs_services_attributes.length > 0) {
                    return ps.bs_services_attributes.flatMap(bs => bs.asset_ids)
                }
            } else {
                return ps.asset_ids
            }
        })
        const uniqueProposalAssets = proposalAssets.filter((proposalAsset, index, array) => array.indexOf(proposalAsset) === index)

        if (active !== null) {
            switch (active) {
                case false:
                    assets = assets.filter(a => a.disabled === true);
                    break;
                case true:
                    assets = assets.filter(a => a.disabled === false);
                    break;
                case 'included':
                    assets = assets.filter(el => uniqueProposalAssets.find(a => a === el.id))
                    break;
                case 'excluded':
                    assets = assets.filter(el => uniqueProposalAssets.every(a => a !== el.id))
                    break;
            }
        }

        if (search !== '') {
            assets = assets.filter(a => {
                return (a.plant_name && a.plant_name.toLowerCase().includes(search.toLowerCase()))
                    || a.label && a.label.toLowerCase().includes(search.toLowerCase());
            });
        }
        return assets;

    };

    getSiteAssets = () => {

        const {assets} = this.state;
        return assets;
    };

    handleTogglePanel() {
        this.setState({isPanelOpen: !this.state.isPanelOpen});
    }

    handleHidePanel = () => {
        this.setState({isPanelVisible: !this.state.isPanelVisible});
    };

    handleHideLabels = () => {
        this.setState({showLabels: !this.state.showLabels});
    };

    componentWillMount() {
        this.updateDimensions();
    };

    saveProposal = (callback) => {
        let {resource, proposalService} = this.state;
        const {proposal_services} = resource;
        const primaryUrl = new URL(window.location.href).origin

        resource.url = primaryUrl;

        if (!resource.person_id) {
            this.props.actions.addAlert({message: "Select arborist to create proposal service"});
        } else if (resource.person_id && proposal_services && proposal_services.length >= 0) {
            this.props.actions.saveProposal(resource, result => {
                resource.proposal_services = resource.proposal_services && resource.proposal_services.filter(ps => ps.id);
                const newProposal = {...resource, ...result};
                if (resource.id !== result.id) {
                    this.setState({
                        resource: newProposal,
                        isPanelVisible: false,
                        inactiveCustomer: newProposal?.inactive_customer,
                        inactiveSite: newProposal?.inactive_site
                    }, () => {
                        browserHistory.push(`/mapview/${result.id}`);
                    });
                } else {
                    this.setState({resource: newProposal}, () => callback ? callback() : {});
                }
            })
        } else {
            this.props.actions.fetchLastAddedService(resource, callback => {
                    if ((callback.all_services !== (proposal_services && proposal_services.length)) || (proposalService && proposalService.id)) {
                        this.props.actions.saveProposal(resource, result => {
                            resource.proposal_services = resource.proposal_services && resource.proposal_services.filter(ps => ps.id);
                            const newProposal = {...resource, ...result};
                            if (resource.id !== result.id) {
                                this.setState({resource: newProposal, isPanelVisible: false}, () => {
                                    browserHistory.push(`/mapview/${result.id}`);
                                });
                            } else {
                                this.setState({resource: newProposal}, () => callback());
                            }
                        })
                    } else if (callback.all_services === (proposal_services && proposal_services.length) && (proposalService && !proposalService.id)) {
                        resource.proposal_services = resource.proposal_services && resource.proposal_services.filter(ps => ps.id);
                        this.setState({resource: resource}, () => this.props.actions.addAlert({message: "This service number already exists, please refresh page and try again"}));
                    }
                }
            );
        }
    };

    cancel = () => {
        this.reload(this.props.params.proposalId);
    };

    handleProposalIdChange = (id) => {
        let {resource} = this.state;
        resource.id = id;
        this.setState(resource, () => this.reload(id));
    };

    onSave = () => {
        const {proposalService} = this.state;
        if (proposalService) {
            this.submitProposalService();
        } else {
            this.saveProposal();
        }
    };

    renderDetailsForm = () => {
        const {resource, employees, customerSearchResults, showLabels} = this.state;
        const isPanelOpen = this.isPanelOpened();
        const id = this.props.params.proposalId;
        const customerOptions = mapForSelect(customerSearchResults);
        const currentStatus = 'draft';
        const statuses = dashboardStatuses();
        const selectedCustomer = customerSearchResults && customerSearchResults.find(x => x.id === resource.customer_id);
        const sites = (resource.customer_id && selectedCustomer) ? selectedCustomer.sites.filter(s => !s.site_moved) : [];
        const sitesOptions = mapForSelect(sites);
        const customer = select(customerOptions, resource.customer_id);
        const employee = select(employees, resource.person_id);
        const formColor = employee ? employee.color : '#3a87ad';

        if (!resource.site_id && sitesOptions.length === 1) {
            resource.site_id = sitesOptions[0] && sitesOptions[0].value
            this.state.site = sitesOptions[0] && sites.find(x => x.id === sitesOptions[0].value);
            if (this.state.site.inactive) {
                this.state.inactiveSite = this.state.site.inactive
            }
        }

        const site = select(sitesOptions, resource.site_id);

        const selectCustomerAttachment = (id) => {
            this.props.actions.selectCustomerAttachmentForPrint(id, async data => {
                const updatedFiles = await resource.proposalInfo.customerFiles.map(file => {
                    if (file.id === id) {
                        file.selected_for_print = !file.selected_for_print
                    }
                    return file
                })
                this.setState({customerFiles: updatedFiles});
            })
        }

        const selectSiteAttachment = (id) => {
            this.props.actions.selectSiteAttachmentForPrint(id, async data => {
                const updatedFiles = await resource.proposalInfo.siteFiles.map(file => {
                    if (file.id === id) {
                        file.selected_for_print = !file.selected_for_print
                    }
                    return file
                })
                this.setState({siteFiles: updatedFiles});
            })
        }

        return <Form horizontal>
            <div style={{background: formColor}} className="master-form-header">
                <Button className="toggle-button" onClick={() => this.handleTogglePanel()}><span
                    className={isPanelOpen ? "glyphicon glyphicon-menu-left" : "glyphicon glyphicon-menu-right"}/></Button>
                <Row className="vcenter hcenter full-width">
                    <Col xs={2} className="text-center">
                        {!isPanelOpen ?
                            <OverlayTrigger id="customer_overlay" placement="right" overlay={<Tooltip
                                id="customer_label">{customer ? customer.label : 'Customer'}</Tooltip>}>
                                <Glyphicon glyph="user" className="big"/>
                            </OverlayTrigger> : <Glyphicon glyph="user" className="big"/>}
                    </Col>
                    {isPanelOpen && <>
                        <Col xs={this.state.inactiveCustomer ? 9 : 10}>
                            <InlineEditable
                                editable={!id}
                                value={
                                    <h3>{customer ? customer.label : 'Customer'}</h3>}
                                form={(done) =>
                                    <Select autoFocus menuIsOpen className="Select"
                                            classNamePrefix="select"
                                            value={customer}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            onInputChange={this.delayedSearch}
                                            onChange={
                                                this.selectResourceAttr('customer_id', () => {
                                                        this.setState({assets: []})
                                                        done();
                                                        const {customer_id} = this.state.resource;
                                                        const selectedCustomer = customerSearchResults.find(x => x.id === customer_id);
                                                        if (selectedCustomer != null) {
                                                            this.setState({inactiveCustomer: selectedCustomer.inactive})
                                                            const selectSite = selectedCustomer.sites.length === 1;
                                                            if (selectSite) {
                                                                this.selectSite((mapForSelect(selectedCustomer.sites)[0]));
                                                            } else {
                                                                this.setState({inactiveSite: false})
                                                                this.selectSite(null);
                                                            }
                                                        }
                                                    }
                                                )
                                            }
                                            isClearable
                                    />
                                }
                            />
                        </Col>
                        {this.state.inactiveCustomer && <Col xs={1}>
                            <OverlayTrigger placement="right" overlay={<Tooltip
                                id="inactive_customer">Customer is inactive</Tooltip>}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className='warning'/>
                            </OverlayTrigger>
                        </Col>}
                    </>
                    }
                </Row>
                <Row className="vcenter hcenter full-width bottom15 top10">
                    <Col xs={2} className="text-center">
                        {!isPanelOpen ?
                            <OverlayTrigger placement="right" overlay={<Tooltip
                                id="employee_label">{employee ? employee.label : 'Arborist'}</Tooltip>}>
                                <FontAwesomeIcon icon={faPiedPiperHat} className="biggest"/>
                            </OverlayTrigger> : <FontAwesomeIcon icon={faPiedPiperHat} className="biggest"/>}
                    </Col>
                    {isPanelOpen &&
                        <Col xs={10}>
                            <InlineEditable
                                value={<h3>{employee ? employee.label : 'Arborist'}</h3>}
                                form={(done) =>
                                    <Select autoFocus menuIsOpen className="Select" classNamePrefix="select"
                                            value={employee}
                                            options={employees}
                                            isClearable
                                            onChange={this.selectResourceAttr('person_id', done)}
                                    />
                                }
                            />
                        </Col>
                    }
                </Row>
            </div>
            <div className="master-form-body bottom10">
                {(isPanelOpen && resource.id) &&
                    <Row className='hright mr-0-ml-0 bottom20 font11'>
                        <span className='bold mr-3'>Proposal ID:</span>
                        <span>{resource.id}</span>
                    </Row>
                }
                <div className="master-form-content">
                    <Row className="vcenter hcenter full-width top10">
                        <Col xs={2} className="text-center">
                            {!isPanelOpen ?
                                <OverlayTrigger placement="right"
                                                overlay={<Tooltip
                                                    id="status_label">{site ? site.label : 'Site'}</Tooltip>}>
                                    <Glyphicon glyph="map-marker" className="big"/>
                                </OverlayTrigger> : <Glyphicon glyph="map-marker" className="big"/>}
                        </Col>
                        {isPanelOpen && <>
                            <Col xs={this.state.inactiveSite ? 9 : 10}>
                                <div>
                                    <InlineEditable
                                        editable={!id}
                                        value={<h4>{site ? site.label : 'Site'}</h4>}
                                        form={(done) => <Select autoFocus menuIsOpen className="Select"
                                                                classNamePrefix="select"
                                                                value={site}
                                                                options={sitesOptions}
                                                                isClearable
                                                                onChange={e => {
                                                                    this.selectSite(e);
                                                                    done();
                                                                }}
                                        />
                                        }
                                    />

                                </div>
                            </Col>
                            {this.state.inactiveSite && <Col xs={1}>
                                <OverlayTrigger placement="right" overlay={<Tooltip
                                    id="inactive_customer">Site is inactive</Tooltip>}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} className='warning'/>
                                </OverlayTrigger>

                            </Col>}
                        </>
                        }
                    </Row>
                    <Row className="vcenter hcenter full-width top10">
                        <Col xs={2} className="text-center">
                            {!isPanelOpen ?
                                <OverlayTrigger placement="right" overlay={
                                    <Tooltip
                                        id="proposal_date_label">{formatInlineDate(resource.proposal_date)}</Tooltip>}>
                                    <Glyphicon glyph="time" className="big"/>
                                </OverlayTrigger> : <Glyphicon glyph="time" className="big"/>}
                        </Col>
                        {isPanelOpen &&
                            <Col xs={10}>
                                <InlineEditable
                                    value={formatInlineDate(resource.proposal_date)}
                                    form={() =>
                                        <Datetime
                                            dateFormat={defaultDateFormat}
                                            timeFormat={defaultTimeFormat}
                                            value={resource.proposal_date}
                                            onChange={this.dateResourceAttr('proposal_date')}/>
                                    }
                                />
                            </Col>
                        }
                    </Row>
                    <Row className="vcenter hcenter full-width top10">
                        <Col xs={2} className="text-center">
                            {!isPanelOpen ?
                                <OverlayTrigger placement="right"
                                                overlay={<Tooltip id="note_customer_label">{resource.note_customer ?
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: resource.note_customer}}/> : 'Notes'}</Tooltip>}>
                                    <Glyphicon glyph="align-justify" className="big"/>
                                </OverlayTrigger> : <Glyphicon glyph="align-justify" className="big"/>}
                        </Col>
                        {isPanelOpen && <>
                            <Col xs={8}>
                                <FormGroup bsSize="small">
                                    <ControlLabel>Proposal Notes:</ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        rows={3}
                                        name="note_customer"
                                        placeholder="Notes"
                                        value={resource.note_customer}
                                        onChange={this.updateResourceAttr}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={2}/>
                        </>
                        }
                    </Row>
                    {isPanelOpen &&
                        <>
                            <Row>
                                <Col xsOffset={2} xs={10}>
                                    <ColorCheckbox value={showLabels}
                                                   label="Show asset numbers on markers"
                                                   onChange={this.handleHideLabels}
                                                   className='no-padding no-margin'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xsOffset={2} xs={8}>
                                    <FormGroup bsSize="small">
                                        <ControlLabel>Proposal title</ControlLabel>
                                        <FormControl
                                            name="proposal_title"
                                            placeholder="Purchase title"
                                            value={resource.proposal_title}
                                            onChange={this.updateResourceAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xsOffset={2} xs={8}>
                                    <FormGroup bsSize="small">
                                        <ControlLabel>Purchase order</ControlLabel>
                                        <FormControl
                                            maxlength={30}
                                            name="purchase_order"
                                            placeholder="Purchase order"
                                            value={resource.purchase_order}
                                            onChange={this.updateResourceAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xsOffset={2} xs={8}>
                                    <FormGroup bsSize="small">
                                        <ControlLabel>Tax</ControlLabel>
                                        <FormControl
                                            name="tax"
                                            type="number"
                                            placeholder="Tax"
                                            value={resource.tax}
                                            onChange={this.updateResourceAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {resource.proposalInfo?.customerFiles.length > 0 &&
                                <Row>
                                    <Col xsOffset={2} xs={8}>
                                        <FormGroup bsSize="small">
                                            <ControlLabel>User's Photos</ControlLabel>
                                            <div className='uploaded_files uploaded_files-map_view'>
                                                {resource.proposalInfo.customerFiles.sort((a, b) => a.order - b.order).map((file) =>
                                                    <div
                                                        className={`attachment ${file.selected_for_print && 'attachment-selected'}`}>
                                                        <input type='checkbox' checked={file.selected_for_print}
                                                               onChange={() => selectCustomerAttachment(file.id)}/>
                                                        <Attachment key={file.id} image={file} disableNBSP={true}/>
                                                        {this.attachmentName(file)}
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            {resource.proposalInfo?.siteFiles.length > 0 &&
                                <Row>
                                    <Col xsOffset={2} xs={8}>
                                        <FormGroup bsSize="small">
                                            <ControlLabel>Sites Photos</ControlLabel>
                                            <div className='uploaded_files uploaded_files-map_view'>
                                                {resource.proposalInfo.siteFiles.sort((a, b) => a.order - b.order).map((file) =>
                                                    <div
                                                        className={`attachment ${file.selected_for_print && 'attachment-selected'}`}>
                                                        <input type='checkbox' checked={file.selected_for_print}
                                                               onChange={() => selectSiteAttachment(file.id)}/>
                                                        <Attachment key={file.id} image={file} disableNBSP={true}/>
                                                        {this.attachmentName(file)}
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </>}
                </div>
                {isPanelOpen &&
                    <>
                        <Row className="vcenter hcenter full-width top10 buttons">
                            <Col xsOffset={2} xs={10}>
                                <Link className="text-success pointer" onClick={() => {
                                    let {resource} = this.state;
                                    if (resource.customer_id && resource.person_id && resource.site_id) {
                                        const selectedCustomer = customerSearchResults.find(x => x.id === resource.customer_id);
                                        const selectedSite = selectedCustomer?.sites?.find(s => s.id === resource.site_id)
                                        if (selectedCustomer?.inactive || this.state.site?.inactive || selectedSite?.inactive) {
                                            this.setState({showInactiveModal: true})
                                        } else {
                                            this.props.actions.isAssignedArborist({
                                                id: resource.customer_id,
                                                arboristId: resource.person_id
                                            }, (result) => {
                                                if (result) {
                                                    this.onSave()
                                                } else {
                                                    this.setState({showAssignedArboristModal: true})
                                                }
                                            })
                                        }
                                    }
                                }}>
                                    <FontAwesomeIcon icon="check" className="small-margin"/>
                                    Save
                                </Link>
                                <Link className="text-danger pointer" onClick={this.cancel}>
                                    <FontAwesomeIcon icon="times" className="small-margin"/>
                                    Cancel
                                </Link>
                            </Col>
                        </Row>
                        <Row className="vcenter top25">
                            <Col xsOffset={2} xs={10}>
                                {!resource.proposalInfo?.proposal_deleteable ?
                                    <OverlayTrigger placement="top" overlay={
                                        <Tooltip id="tooltip">
                                            One of Proposal Services is in Accepted or higher status
                                        </Tooltip>
                                    }>
                                        <div style={{width: "fit-content"}}>
                                            <Button
                                                bsSize="small"
                                                bsStyle="danger"
                                                disabled={true}
                                                style={{pointerEvents: "none"}}
                                            >
                                                Delete Proposal
                                            </Button>
                                        </div>
                                    </OverlayTrigger>
                                    :
                                    <Button
                                        bsSize="small"
                                        onClick={() => this.setState({showProposalDeletionModal: true})}
                                        bsStyle="danger"
                                    >
                                        Delete Proposal
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </Form>
    };

    onToggleLockMode = () => {
        const {resource, lockedMapAssetMovement} = this.state;
        const anyMoved = this.state.assets.some(a => a.moved);
        if (anyMoved) {
            this.props.actions.loadSite(resource.site_id, site => {
                const {assets} = this.state

                this.setState({
                    assets: site.assets.map(a => {
                        const index = assets.findIndex(asset => asset.id === a.id);
                        if (index > -1) {
                            a.checked = assets[index].checked
                        }
                        return a
                    }), loaded: moment()
                });
            });
        }
        this.props.actions.updateAssetMovementLockSettings(resource.id)
        this.setState({lockedMapAssetMovement: !lockedMapAssetMovement});
    }

    renderMapPanel = () => {
        const {
            site,
            assets,
            loaded,
            center,
            showLabels,
            customerSearchResults,
            resource,
            showNewAssets
        } = this.state;
        const anyMoved = assets.some(a => a.moved);

        let customerPrimarySite;
        let siteIndex;
        const currentCustomer = customerSearchResults && customerSearchResults[0];

        if (currentCustomer && currentCustomer.sites && currentCustomer.sites.length > 0) {
            if (site) {
                siteIndex = currentCustomer.sites.indexOf(s => s.id === site.id)
                customerPrimarySite = currentCustomer.sites[siteIndex]
            } else {
                customerPrimarySite = currentCustomer.sites[0]
            }
        }

        customerPrimarySite = this.props.params.proposalId ? null : customerPrimarySite

        return (<Panel
            className="map"
        >
            <Map
                active={this.state.active}
                key={this.state.remountMapKey}
                myLocationCheckbox={true}
                fullScreen={this.props.fullScreen}
                lockedMode={this.state.lockedMapAssetMovement}
                onToggleLock={this.onToggleLockMode}
                anyMoved={anyMoved}
                setStreetView={(streetView) => this.setState({isStreetView: streetView})}
                loaded={loaded}
                center={center}
                showNewAssets={showNewAssets}
                newAsset={this.state.asset}
                opacity={this.state.opacity}
                proposalPolygon={this.state.proposalPolygon}
                site={site ? site : customerPrimarySite}
                siteAssets={[...this.getFilteredAssets().map(a => ({...a}))]}
                showLabels={showLabels}
                client={this.props.client}
                newAssetDisplay={this.state.resource.new_asset_display}
                onMarkerEdit={(asset, selectedAssetVal, openedInMap) => {
                    if (!anyMoved) {
                        this.setState({asset: asset, selectedAssetVal, openedInMap: true})
                    }
                }}
                onMarkerAdd={marker => {
                    this.setState({
                        asset: {
                            latitude: marker.getPosition().lat(),
                            longitude: marker.getPosition().lng(),
                        }
                    });
                }}
                onGeolocationMarkerAdd={marker => {
                    this.setState({
                        asset: {
                            latitude: marker.latitude,
                            longitude: marker.longitude,
                            enableAI: true
                        }
                    });
                }}
                onPolygonAdd={(marker, selectedAssetVal) => {
                    this.setState({
                        asset: marker,
                        selectedAssetVal
                    });
                }}
                onToggleFullScreen={() => {
                    const {fullScreen} = this.props
                    const map = document.getElementsByClassName('lead-map')[0]
                    map.classList.toggle("map-full-screen")
                    this.props.actions.toggleFullScreen(fullScreen === false)
                }}
                onMarkersAdd={(assetId, markers) => {
                    this.props.actions.saveDuplicates(site.id, assetId, markers, duplicates => {
                        const copiedDuplicates = _.cloneDeep(duplicates);
                        const copiedOldAssets = _.cloneDeep(this.state.oldAssets);
                        copiedOldAssets.push(...copiedDuplicates);
                        assets.push(...duplicates);
                        this.setState({assets, oldAssets: copiedOldAssets});
                    });
                }}
                editable={true}
                onMarkerClick={(id, selectedAssetValue) => {
                    this.selectMarker('assets', (e, y) => e === y.id, () => this.setState({
                        loaded: moment(),
                        selectedMarkerId: id
                    }))(id);
                }}
                onDragend={(assetId, marker) => {
                    const asset = assets.find(a => a.id === assetId);
                    asset.latitude = marker.latLng.lat();
                    asset.longitude = marker.latLng.lng();
                    asset.moved = !this.state.lockedMapAssetMovement;

                    this.setState({assets});
                }}
                onCircleEdit={(assetValues, assetId, radius) => {
                    const asset = assets.find(a => a.id === assetId);
                    const polygon = asset.polygons.find(p => p.chosen)
                    polygon.radius = radius;
                    polygon.circuit = assetValues.circuit;
                    polygon.field = assetValues.field;
                    asset.moved = !this.state.lockedMapAssetMovement;
                    this.setState({assets});

                }}
                onDragendPoly={(assetValues, assetId, polygon_points_attributes, type) => {
                    const asset = assets.find(a => a.id === assetId);
                    const polygon = asset.polygons.find(p => p.chosen)
                    asset.latitude = polygon_points_attributes[0].latitude
                    asset.lat = polygon_points_attributes[0].latitude
                    asset.longitude = polygon_points_attributes[0].longitude
                    asset.lng = polygon_points_attributes[0].longitude
                    polygon.polygon_points_attributes = polygon_points_attributes
                    polygon.circuit = assetValues.circuit
                    polygon.field = assetValues.field
                    polygon.polygon_points = polygon_points_attributes
                    if (type) {
                        polygon.type_polygon = type
                    }
                    asset.moved = !this.state.lockedMapAssetMovement;

                    this.setState({assets});
                }}
                onDragendCircle={(assetId, marker, polygon_points_attributes) => {
                    const asset = assets.find(a => a.id === assetId);
                    const polygon = asset.polygons.find(p => p.chosen)
                    asset.latitude = marker.lat();
                    asset.lat = marker.lat();
                    asset.longitude = marker.lng();
                    asset.lng = marker.lng();
                    polygon.polygon_points_attributes = polygon_points_attributes
                    polygon.polygon_points = polygon_points_attributes
                    asset.moved = !this.state.lockedMapAssetMovement;

                    this.setState({assets});
                }}
            />
            {anyMoved && <div className="edit-map-button">
                <Button
                    bsSize="small"
                    onClick={() => {
                        const moved = assets.filter(m => m.moved).map((m) => {
                            return ({
                                ...m,
                                polygons_attributes: m.polygons.find(p => p.chosen)
                            })
                        })

                        this.props.actions.saveMap(resource.site_id, moved, () => {
                            moved.forEach(m => delete m.moved);
                            const newAssets = [...assets]
                            newAssets.map((a) => {
                                delete a.moved;
                                return a;
                            });
                            this.setState({newAssets}, () => this.reload(resource.id))
                        });
                    }}
                >
                    Save Changes
                </Button>
                <Button
                    bsSize="small"
                    className="small-margin"
                    onClick={() => {
                        if (assets.some(a => a?.moved && a?.polygons?.find(p => p.chosen)?.type_polygon === "circle")) {
                            this.setState({anyCircleMoved: true})
                        } else {
                            this.setState({anyCircleMoved: false})
                        }
                        const mappedAssets = _.cloneDeep(assets.map(newAsset => {
                            const assetChecked = newAsset.checked
                            const oldAsset = this.state.oldAssets.find(oldAsset => oldAsset.id === newAsset.id);
                            if (oldAsset) {
                                newAsset = _.cloneDeep(oldAsset);
                                if (newAsset?.moved) {
                                    delete newAsset.moved;
                                }
                            }
                            newAsset.checked = assetChecked
                            return newAsset;
                        }))

                        this.setState({assets: mappedAssets, loaded: moment()}, () => {
                            if (this.state.anyCircleMoved) {
                                this.setState({remountMapKey: this.state.remountMapKey + 1})
                            }
                        });
                    }}
                >
                    Cancel
                </Button>
            </div>}

        </Panel>)
    };

    renderView = (i) => {
        const {user} = this.state;
        const mapping = {
            'Map': this.renderMapPanel,
            'Services': this.renderProposalServices,
            'Assets': this.renderAssets
        };
        return mapping[user.map_view_settings[i]]();
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        this.setState({moveToProposed: null});
    }

    isPanelOpened() {
        return window.innerWidth > this.panelMinWidth ? this.state.isPanelOpen : true;
    };

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (ass.shapeOpacity && polygon.type_polygon === 'polyLine') {
            return 0.35
        } else if (ass.shapeOpacity) {
            return 0.2
        } else if (ass.opacity && polygon.type_polygon === 'polyLine') {
            return 0.75
        } else if (ass.opacity) {
            return 0.5
        } else {
            return 1
        }
    }

    toggleNewAssets = (id) => {
        const {showNewAssets} = this.state;
        this.setState({showNewAssets: !showNewAssets}, () => this.reload(id))
    }

    exportToKMZ = () => {
        const [polygonAssets, points] = _.partition(this.getFilteredAssets(), (a) => a.polygons && a.polygons.length > 0)
        const pointsFeatures = points.map(assetPoint => turf.point([assetPoint.longitude, assetPoint.latitude], {
                name: assetPoint.label,
                "asset-number": assetPoint.asset_number,
                "plant-name": assetPoint.plant_name,
                color: assetPoint.color ? colors[assetPoint.color] : colors[parseInt(assetPoint.plant_color)]
            })
        )

        const polygonsFeatures = polygonAssets.flatMap(a => a.polygons.flatMap(polygon => {
            const color = polygon.color ? colors[polygon.color] : colors[parseInt(a.plant_color)]
            const opacity = this.setShapeOpacity(a)

            const properties = {
                name: a.label,
                "asset-number": a.asset_number,
                "plant-name": a.plant_name,
                stroke: color,
                "stroke-width": 2,
                "stroke-opacity": 1,
                fill: color,
                "fill-opacity": opacity
            }

            switch (polygon.type_polygon) {
                case 'polyLine':
                    return turf.lineString([...polygon.polygon_points.map(pp => [pp.lng, pp.lat]), [polygon.polygon_points[0].lng, polygon.polygon_points[0].lat]], properties)
                case 'polygon':
                case 'rectangle':
                    return turf.polygon([[...polygon.polygon_points.map(pp => [pp.lng, pp.lat]), [polygon.polygon_points[0].lng, polygon.polygon_points[0].lat]]], properties)
                case 'circle':
                    const center = [polygon.polygon_points[0].lng, polygon.polygon_points[0].lat]
                    return [
                        turf.point(center),
                        turf.circle(center, polygon.radius / 1000.0, {properties})
                    ]
            }
        }))

        const geoJson = turf.featureCollection([...pointsFeatures, ...polygonsFeatures])
        const kml = tokml(geoJson, {simplestyle: true, name: 'name'})
        const zip = new JSZip()
        zip.file('doc.kml', kml)
        zip.generateAsync({type: 'blob'})
            .then(content => saveAs(content, 'proposal_map.kmz'))
    }

    render() {
        const id = this.props.params.proposalId;
        const {
            resource,
            site,
            asset,
            assets,
            contextLoaded,
            isPanelVisible,
            panelWidth,
            editedPs,
            isPsTotalChanged,
            isPsModalOpened,
            currentInvoiceStatus,
            customerSearchResults,
            showNewAssets,
            showAssetErrorModal,
            showFixAssetsModal,
            errorAssetNumbers,
            showAssignedArboristModal,
            showInactiveModal,
            showBundleServicesWarningModal,
            showProposalDeletionModal
        } = this.state;

        const {fullScreen} = this.props;
        const isPanelOpen = this.isPanelOpened();
        const fullSizePanel = (isPanelOpen && isPanelVisible);

        const bodyPanel = document.getElementsByClassName('Pane')[1]
        const mapPanel = document.getElementsByClassName('Pane')[3]

        if (bodyPanel && mapPanel && !fullScreen) {
            bodyPanel.style.paddingTop = "10px"
            mapPanel.style.paddingRight = "10px"
        }


        let sortedAssets = []

        assets.map(asset => {
            if (asset.removed) {
                asset.disabled = true
            } else if (asset.semi_removed && asset.opacity) {
                asset.opacity = true
                asset.shapeOpacity = true
            }
            sortedAssets.push(asset)
        })

        return (
            <Grid fluid id="proposal-page">
                <Modal show={showAssetErrorModal}
                       onHide={() => this.setState({showAssetErrorModal: false})}
                       className={"heightAuto fontIOS"}>
                    <Modal.Header closeButton><Modal.Title>Loading Assets
                        Error</Modal.Title></Modal.Header>
                    <Modal.Body>
                        {errorAssetNumbers.length === 1 ?
                            <div className='columnDirection rate-modal-body-padding'>
                                Asset number {errorAssetNumbers} did not record the coordinates, please reload and try
                                again or enter them manually in advanced section.
                            </div> :
                            <div className='columnDirection rate-modal-body-padding'>
                                Assets of number {errorAssetNumbers.map(number => number).join(', ')} did not record the
                                coordinates, please reload and try again or enter them manually in advanced section.
                            </div>}

                    </Modal.Body>
                </Modal>
                {!this.props.fullScreen && <ProposalMenu
                    customer={resource}
                    assets={assets}
                    showNewAssets={showNewAssets}
                    toggleNewAssets={this.toggleNewAssets}
                    id={resource.id}
                    isPanelVisible={isPanelVisible}
                    handleHidePanel={this.handleHidePanel}
                    expandButton={true}
                    classicView={true}
                    onReload={this.handleProposalIdChange}
                    onEmailSend={id => this.onEmailSend(id)}
                    moveToProposed={this.state.moveToProposed}
                    exportToKMZ={this.exportToKMZ}
                />}
                {isPsModalOpened &&
                    <RateModal
                        title="Proposal service"
                        show={isPsModalOpened}
                        onHide={() => {
                            resource.proposal_service_id = editedPs
                            resource.is_ps_total_changed = isPsTotalChanged
                            this.setState({
                                isPsModalOpened: !isPsModalOpened,
                                isPsTotalChanged: !isPsTotalChanged
                            }, this.props.actions.saveProposal(resource, () => this.reload(resource.id)))
                        }}
                        closeButton={() => this.setState({
                            isPsModalOpened: !isPsModalOpened,
                            isPsTotalChanged: !isPsTotalChanged
                        }, () => this.reload(resource.id))}
                    >
                        <div className="rate-modal-body-padding d-flex">
                            <FontAwesomeIcon icon={faExclamationTriangle}
                                             className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/>
                            <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                                This proposal current status is "{currentInvoiceStatus}". {isMobile && <br/>} Are you
                                sure,
                                you want to change proposal service
                                parameters? <br/>
                                {currentInvoiceStatus === "Paid" ? 'Invoice status and total amount are subject to change' : 'Total amount is subject to change'}
                            </p>
                        </div>
                    </RateModal>
                }

                <Row>
                    {
                        isPanelVisible && !this.props.fullScreen &&
                        <Col className={fullSizePanel ? 'col-md-3 master-form' : 'panel-fixed master-form'}>
                            {this.renderDetailsForm()}
                        </Col>
                    }

                    {contextLoaded && <div>
                        <Col xsHidden className={fullSizePanel ? 'col-md-9 pane-wrapper' : 'col-md-12 pane-wrapper'}
                             smHidden>
                            <SplitPane split="vertical"
                                       className={isPanelVisible ? (isPanelOpen ? '' : 'margin50') : ''}
                                       defaultSize={this.defaultSize(0)} onChange={this.handleWrapServiceTitle}>
                                <div style={{height: "98%"}}>{this.renderView(0)}</div>

                                <SplitPane split="vertical" defaultSize={this.defaultSize(1)}
                                           onChange={e => this.setState({panelWidth: e})}>
                                    <div style={{height: "98%"}}>
                                        {this.renderView(1)}
                                    </div>
                                    <div style={{height: "98%"}}>
                                        {this.renderView(2)}
                                    </div>
                                </SplitPane>
                            </SplitPane>
                        </Col>
                        <Clearfix visibleXsBlock visibleSmBlock>
                            <Col xs={12}>
                                {this.renderView(0)}
                            </Col>
                            <Col xs={12}>
                                {this.renderView(1)}
                            </Col>
                            <Col xs={12} style={{padding: this.props.fullScreen && 0}}>
                                {this.renderView(2)}
                            </Col>
                        </Clearfix>
                    </div>}
                </Row>
                {asset && <AssetEditorModal
                    asset={asset}
                    assets={assets.sort((a, b) => sortAssetsLabels(a, b, 'label'))}
                    refreshList={(updatedAsset) => {
                        const copyAsset = _.cloneDeep(updatedAsset)
                        const index = assets.findIndex(a => a.id === copyAsset.id);
                        if (index > -1 && !_.isEqual(assets[index].images, copyAsset.images)) {
                            assets[index].images = copyAsset.images;
                            assets[index].image_order = copyAsset.image_order;
                        }
                        if (index > -1 && (assets[index].plant_name !== copyAsset.plant_name || assets[index].label !== copyAsset.label)) {
                            assets[index].plant_name = copyAsset.plant_name;
                            assets[index].label = copyAsset.label;
                        }
                        this.setState({assets: assets})
                    }}
                    closeModal={() => this.setState({asset: false})}
                    openedInMap={this.state.openedInMap}
                    selectedAssetVal={this.state.selectedAssetVal}
                    siteId={site ? site.id : resource.site_id}
                    updateOpacity={this.updateOpacity}
                    onClose={(updatedAsset) => {
                        const copyAsset = _.cloneDeep(updatedAsset)
                        const index = assets.findIndex(a => a.id === copyAsset.id);
                        if (index > -1 && !_.isEqual(assets[index].images, copyAsset.images)) {
                            assets[index].images = copyAsset.images;
                        }
                        this.setState({asset: null})
                    }}
                    onDelete={(assetId) => {
                        const newAssets = assets.filter(a => a.id !== assetId);
                        this.setState({assets: newAssets, asset: null}, () => this.reload(resource.id));
                    }}
                    onSave={(newAsset, close = true) => {
                        const copyAsset = _.cloneDeep(newAsset)
                        const copyOldAssets = _.cloneDeep(this.state.oldAssets)

                        const index = assets.findIndex(a => a.id === copyAsset.id);
                        if (index > -1) {
                            const oldAsset = assets[index]
                            copyAsset.checked = oldAsset.checked
                            assets[index] = copyAsset;
                            copyOldAssets[index] = _.cloneDeep(newAsset);
                        } else {
                            assets.push(copyAsset);
                            copyOldAssets.push(_.cloneDeep(newAsset));
                        }
                        const newState = {assets, oldAssets: copyOldAssets};
                        if (close) {
                            newState.asset = null;
                            if (assets.some(asset => ((!asset.latitude && asset.latitude !== 0) ||
                                !asset.longitude && asset.longitude !== 0) || this.checkPolygonsCoords(asset))) {
                                setTimeout(() => this.setState({showFixAssetsModal: true}), 500)
                                let assetNumbers = []
                                assets.map(asset => {
                                    if (((!asset.longitude && asset.longitude !== 0) || (!asset.latitude && asset.latitude !== 0)) || this.checkPolygonsCoords(asset)) {
                                        assetNumbers.push(asset.label)
                                        this.setState({errorAssetNumbers: assetNumbers})
                                    }
                                })
                            }
                        }
                        newState.asset = newAsset
                        this.setState(newState, () => {
                            setTimeout(() => this.selectMarker('assets', (e, y) => e === y.id, () => this.setState({loaded: moment()}))(copyAsset.id), 100);
                        });
                    }}
                />}
                <Modal show={showFixAssetsModal}
                       onHide={() => this.setState({showFixAssetsModal: false})}
                       className={"heightAuto fontIOS"}>
                    <Modal.Header closeButton><Modal.Title>Loading Assets
                        Error</Modal.Title></Modal.Header>
                    <Modal.Body>
                        {errorAssetNumbers.length === 1 ?
                            <div className='columnDirection rate-modal-body-padding'>
                                Asset number {errorAssetNumbers} did not record the coordinates, please fix it before
                                continue.
                            </div> :
                            <div className='columnDirection rate-modal-body-padding'>
                                Assets of number {errorAssetNumbers.map(number => number).join(', ')} did not record the
                                coordinates, please fix them before continue.
                            </div>}
                    </Modal.Body>
                </Modal>
                {showInactiveModal && <InactiveModal
                    inactiveSite={this.state.site?.inactive || this.state.inactiveSite}
                    inactiveCustomer={this.state.customerSearchResults?.find(x => x.id === resource.customer_id)?.inactive}
                    onHide={() => this.setState({showInactiveModal: false})}
                    onSave={() => {
                        this.props.actions.isAssignedArborist({
                            id: resource.customer_id,
                            arboristId: resource.person_id
                        }, (result) => {
                            if (result) {
                                this.onSave()
                            } else {
                                this.setState({showAssignedArboristModal: true})
                            }
                        })
                    }}
                />}
                {resource.customer_id && resource.person_id && showAssignedArboristModal && <AssignedArboristModal
                    showModal={showAssignedArboristModal}
                    hideModal={() => this.setState({showAssignedArboristModal: false})}
                    selectedCustomer={resource.customer_id}
                    onSave={() => this.onSave()}
                />}
                {showBundleServicesWarningModal &&
                    <Modal id="bundle-services-warning-modal" show={showBundleServicesWarningModal}
                           onHide={() => this.setState({showBundleServicesWarningModal: false})}
                           className={"heightAuto font18"}>
                        <Modal.Header closeButton><Modal.Title>Warning</Modal.Title></Modal.Header>
                        <Modal.Body>
                            <b>Only services in Draft can be Bundled</b>
                        </Modal.Body>
                        <ModalFooter>
                            <Button
                                onClick={() => this.setState({showBundleServicesWarningModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>}
                {showProposalDeletionModal &&
                    <ConfirmProposalDeletionModal
                        onHide={() => this.setState({showProposalDeletionModal: false})}
                        onDelete={(deleteWithLead) => this.props.actions.deleteProposal(resource.id, deleteWithLead, () => browserHistory.push(`/customer/proposals/${resource.customer_id}`))}
                    />
                }
            </Grid>
        );
    }
}

MapViewPage.propTypes = {
    //myProp: PropTypes.object.isRequired
};

MapViewPage.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state) {
    return {
        userRoles: state.auth.roles,
        fullScreen: state.mapView.fullScreen,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewPage);
