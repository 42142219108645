import React from 'react';
import {bindActionCreators} from 'redux';
import {
    Button,
    ButtonToolbar, Checkbox,
    Col, DropdownButton,
    Grid,
    Row,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";
import * as api from "./InvoicesApi";
import ResourceComponent from "../../components/ResourceComponent";
import {showEmailDialog} from "../../components/email/actions"
import EmailDialog from "../../components/email/EmailDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultDateFormat, defaultDateTimeFormat, defaultDateTimeFormatComma, defaultTimeFormat, saveNewCsvDownload, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import {debounce} from "throttle-debounce";
import "./Invoices.scss";
import moment from "moment";
import {connect} from 'react-redux';
import {faCog} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import PaymentModal from "../CustomerViewInvoice/Payment/PaymentModal";
import {fetchInvoice} from "../CustomerViewInvoice/actions";
import {exportToXLSX} from "./InvoicesWorksheetExport";
import InvoicesTable from "./InvoicesTable";
import InvoicesFilterModal from "./InvoicesFilterModal";

const actions = {...api, showEmailDialog, saveNewCsvDownload, fetchInvoice};
const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

class Invoices extends ResourceComponent {
    state = {
        total: 0,
        selectedRows: [],
        invoices: [],
        InvoiceFilterModalShow: false,
        loaded: false,
        resource: {
            sort: 'desc',
            sort_by: 'aging',
            page: 1,
            per_page: 50,
            paid: false,
            from: null,
            to: null,
            date_filter: 'invoiced_at',
            label: 'all',
            inactive: false
        },
        employees: [],
        filterVisible: true,
        dateFilterOptions: ['Today', '1 - 30', '31 - 60', '61 - 90', '91+', 'all'],
        columnsSettings: {
            "Customer": true,
            "Site": true,
            "Arborist": true,
            "Title": true,
            "Invoice notes": true,
            "Proposal #": true,
            "WO #": true,
            "Invoice #": true,
            "Status Dates": true,
            "Sent": true,
            "Aging": true,
            "Balance": true,
            "Cost": true,
            "Pay": true
        },
        columnsSettingsSelectedAll: true,
        numericFilterChanged: false,
        showPaymentPopUp: false
    };

    tableRef = React.createRef()

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.customerSearch = {searchOptions: () => [], search: () => {}};
        this.siteSearch = {searchOptions: () => [], search: () => {}};
        this.arboristSearch = {searchOptions: () => [], search: () => {}};
        this.proposalTitleSearch = {searchOptions: () => [], search: () => {}};
        this.proposalNoSearch = {searchOptions: () => [], search: () => {}};
        this.workOrderSearch = {searchOptions: () => [], search: () => {}};
        this.invoiceSearch = {searchOptions: () => [], search: () => {}};
    }

    search = () => {
        const {resource} = this.state;
        this.setState({loaded: false}, () => this.props.actions.loadInvoices(resource, results => {
            this.setState({invoices: results.content, total: results.total, loaded: true});
        }))
    }

    onSelectAll = (isSelected, rows) => {
        let selectedRows = [];

        if (isSelected) {
            selectedRows = rows.map(p => p.id)
        }
        this.setState({selectedRows})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRows = [...this.state.selectedRows];
        const id = parseInt(row.id, 10);
        if (isSelected) {
            selectedRows = selectedRows.concat(id);
            const uniqSelected = new Set(selectedRows);
            selectedRows = [...uniqSelected];
        } else {
            selectedRows = selectedRows.filter(s => s !== id);
        }
        this.setState({selectedRows});
    };

    updatePaidDate() {
        const {selectedRows, paid_date} = this.state;
        this.props.actions.updatePaidDate(selectedRows, paid_date, () => {
            this.search();
        })
    }

    handleSelectDateFilterType = e => {
        let {resource} = this.state;
        resource.date_filter = e;
        this.setState({resource});
    };
    renderRadioButton = (param) => {
        const {resource} = this.state;
        return <div className="form-check abc-radio" onClick={() => this.handleSelectPayment(param.value)}>
            <input className="form-check-input"
                   type="radio"
                   checked={resource.paid === param.value}
            />
            <label className="form-check-label">
                {param.label}
            </label>
        </div>
    };

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.siteSearch = this.buildSearchComponentWithParams('site_id', this.props.actions.searchForSite);
        this.arboristSearch = this.buildSearchComponentWithParams('arborist_id', this.props.actions.searchForArborist);
        this.proposalTitleSearch = this.buildSearchComponentWithParams('proposal_id', this.props.actions.searchForProposalTitle);
        this.proposalNoSearch = this.buildSearchComponentWithParams('proposal_no', this.props.actions.searchForProposalNo);
        this.workOrderSearch = this.buildSearchComponentWithParams('work_order_id', this.props.actions.searchForWorkOrder);
        this.invoiceSearch = this.buildSearchComponentWithParams('invoice_id', this.props.actions.searchForInvoice);
        this.props.actions.load(result => this.setState(result, () => {
            if (result.user?.invoices_list_settings?.columns) {
                this.setState({columnsSettings: result.user.invoices_list_settings.columns}, () => {
                    this.setAllColumnsSelect()
                })
            } else {
                this.setAllColumnsSelect()
            }
            this.search()
        }));
    }

    email = row => {
        const {email} = this.props;

        let recipientEmail;
        const {contact_email, contact_email2, secondContactEmail, secondContactEmail2} = row
        const primarySiteContactEmails = (contact_email && contact_email2) ? `${contact_email}, ${contact_email2}` : contact_email
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        recipientEmail = `${primarySiteContactEmails || ""}`
        if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
        let billingEmail = row.email_address
        this.props.actions.showEmailDialog(
            {
                emailType: row.paid_at ? 'invoice paid' : 'invoice',
                referenceId: row.id,
                recipient: billingEmail,
                cc_recipients: row.site_email_addresses,
                defaultEmail: email,
                dataPdf: row, //only row.token is used
                client: this.props.client,
                sendInBackground: true
            });
    };

    handleSelectPayment(param) {
        let {resource} = this.state;
        resource.paid = param;
        this.setState({resource}, this.search);
    };



    createCustomToolBar = () => {
        const {resource, columnsSettings, selectedRows, dateFilterOptions, loaded} = this.state;
        const paymentOptions = [
            {value: null, label: 'All'},
            {value: true, label: 'Paid'},
            {value: false, label: 'Unpaid'}
        ];
        return (
            <Row id="invoices-toolbar" className={isMobile ? "no-margin" : "no-margin d-flex margin-inline-15"}>
                <div className={isMobile ? 'bottom15' : "InvoicePanelLR export-email-buttons"}>
                    {isMobile ? null : <Button bsStyle="success" disabled={!loaded || selectedRows.length === 0}
                                               onClick={this.doExportToXLSX}>Export to XLSX</Button>}
                    <Button bsStyle="warning"
                            className={isMobile ? null : "marginLeft10"}
                            onClick={() => this.setState({email: true})}
                            disabled={selectedRows.length === 0}>Email Selected</Button>
                </div>
                <div className={isMobile ? 'textLeft bottom10' : 'vcenter radio-buttons'}>
                    {paymentOptions.map(option => this.renderRadioButton(option))}
                </div>
                <div className={isMobile ? "bottom15" : "InvoicePanelLR label-buttons"}>
                    <ButtonToolbar>
                        <ToggleButtonGroup type="radio"
                                           name="options"
                                           value={resource.label}
                                           onChange={this.selectCheckboxAttr('label', this.search)}
                        >
                            {
                                dateFilterOptions.map((d, i) => {
                                    return <ToggleButton
                                        key={i}
                                        value={d}
                                        className={isMobile ? "padding10LR" : null}
                                    >
                                        {d}
                                    </ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                </div>
                <div className={isMobile ? 'textLeft' : 'justify-flex-end update-button-and-input'}>
                    <Button className="update-paid-button" bsStyle="warning" onClick={() => this.updatePaidDate()}
                            disabled={selectedRows.length === 0}>Update paid date</Button>
                    <Datetime
                        dateFormat={defaultDateFormat}
                        timeFormat={defaultTimeFormat}
                        value={resource.paid_date}
                        className="marginLeft10"
                        inputProps={{placeholder: 'Paid date'}}
                        onChange={(e) => this.setState({paid_date: e})}
                    />
                </div>
            </Row>
        );
    }

    dateChangeCallback = () => {
        this.setState(state => {
            state.resource.label = 'all';
            return state
        });
    };

    onExportToXLSX = () => {
        const {invoices} = this.state
        const selectedRows = this.tableRef.current.state.selectedRowKeys;
        return invoices.filter(i => {
            if (selectedRows.indexOf(i.id) > -1) {
                return i;
            }
        });
    }

    onColumnsSettingsChange = (column) => {
        let {columnsSettings} = this.state
        columnsSettings[column] = !columnsSettings[column]
        this.setState({columnsSettings}, this.setAllColumnsSelect)
        this.props.actions.updateInvoicesListSettings(columnsSettings)
    }

    setAllColumnsSelect = () => {
        let {columnsSettings} = this.state
        let check = true
        Object.keys(columnsSettings).forEach(column =>{if (!columnsSettings[column]) {check = false}})
        this.setState({columnsSettingsSelectedAll: check})
    }

    onSelectAllColumns = () => {
        let {columnsSettings, columnsSettingsSelectedAll} = this.state
        Object.keys(columnsSettings).forEach(column => columnsSettings[column]=!columnsSettingsSelectedAll)
        columnsSettingsSelectedAll = !columnsSettingsSelectedAll;
        this.props.actions.updateInvoicesListSettings(columnsSettings, (res) => {
            this.setState({columnsSettings, columnsSettingsSelectedAll})
        })
    }

    renderSettingsDropdown = () => {
        const {columnsSettings, columnsSettingsSelectedAll} = this.state
        return (
            <div className="settings-dropdown-menu">
                <DropdownButton title={<>Settings<FontAwesomeIcon icon={faCog} className="small-margin fontSize14"/></>}
                                className="mr-9 settings-dropdown-menu">
                    <Checkbox className="ml-9" checked={columnsSettingsSelectedAll} onChange={() => {
                        this.onSelectAllColumns()
                    }}>Select All</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Customer"]} onChange={() => {
                        this.onColumnsSettingsChange("Customer")
                    }}>Customer</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Site"]} onChange={() => {
                        this.onColumnsSettingsChange("Site")
                    }}>Site</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Arborist"]} onChange={() => {
                        this.onColumnsSettingsChange("Arborist")
                    }}>Arborist</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Title"]} onChange={() => {
                        this.onColumnsSettingsChange("Title")
                    }}>Title</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Invoice notes"]} onChange={() => {
                        this.onColumnsSettingsChange("Invoice notes")
                    }}>Invoice notes</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Proposal #"]} onChange={() => {
                        this.onColumnsSettingsChange("Proposal #")
                    }}>Proposal #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["WO #"]} onChange={() => {
                        this.onColumnsSettingsChange("WO #")
                    }}>WO #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Invoice #"]} onChange={() => {
                        this.onColumnsSettingsChange("Invoice #")
                    }}>Invoice #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Status Dates"]} onChange={() => {
                        this.onColumnsSettingsChange("Status Dates")
                    }}>Status Dates</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Sent"]} onChange={() => {
                        this.onColumnsSettingsChange("Sent")
                    }}>Sent</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Aging"]} onChange={() => {
                        this.onColumnsSettingsChange("Aging")
                    }}>Aging</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Balance"]} onChange={() => {
                        this.onColumnsSettingsChange("Balance")
                    }}>Balance</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Cost"]} onChange={() => {
                        this.onColumnsSettingsChange("Cost")
                    }}>Cost</Checkbox>
                    <Checkbox className="ml-9 bottom3" checked={columnsSettings["Pay"]} onChange={() => {
                        this.onColumnsSettingsChange("Pay")
                    }}>Pay</Checkbox>
                </DropdownButton>
            </div>
        )
    }

    clearFilters = () => {
        const {resource} = this.state
        this.setState({
            resource: {
                ...resource,
                customer_id: null,
                site_id: null,
                arborist_id: null,
                proposal_id: null,
                proposal_no: null,
                work_order_no: null,
                invoice_no: null,
                page: 1,
                from: null,
                to: null,
                invoice_sent_count_from: null,
                invoice_sent_count_to: null,
                aging_from: null,
                aging_to: null,
                balance_from: null,
                balance_to: null,
                cost_from: null,
                cost_to: null
            }
        }, this.delayedSearch)
    }

    onFilterChange = () => {
        const {resource} = this.state
        if (resource.page !== 1) {
            this.setState({resource: {...resource, page: 1}}, this.delayedSearch)
        } else {
            this.delayedSearch()
        }
    }

    onNumericFilterChange = () => {
        const {numericFilterChanged} = this.state
        if (!numericFilterChanged) {
            this.setState({numericFilterChanged: true})
        }
    }

    reloadInvoicesPaymentModal = () => {
        this.props.actions.load(result => this.setState(result, this.search));
        this.setState({
            paymentModalInvoice: null,
            showPaymentPopUp: false
        })
    };

    doExportToXLSX = async () => {
        const {resource, columnsSettings} = this.state
        const invoices = _.cloneDeep(this.onExportToXLSX())
        await exportToXLSX(invoices, resource, columnsSettings)
        const csvFileName = `invoices-${moment().format(defaultDateFormat)}.csv`
        if (this.tableRef?.current) {
            this.props.actions.saveNewCsvDownload(this.tableRef.current, csvFileName, this.state.resource, "invoices")
        }
    }

    onPayButtonClick = (invoiceToken) => {
        this.props.actions.fetchInvoice(invoiceToken, result => {
            this.setState({
                paymentModalInvoice: result,
                showPaymentPopUp: true
            })
        }, null, true)
    }

    updatePaging = (page, per_page) => {
        let {resource} = this.state
        resource = {...resource, page, per_page};
        this.setState({resource}, this.delayedSearch);
    }

    updateSorting = (sort_by, sort) => {
        let {resource} = this.state;
        resource = {...resource, sort_by, sort};
        this.setState({resource}, this.delayedSearch);
    }

    updateInvoiceCustomerPaymentSettings = (customer_payment_settings, callback) => {
        let {invoices, paymentModalInvoice} = this.state
        invoices.map((i) => {
            if (i.id === paymentModalInvoice.id) {
                i.customer_payment_settings = customer_payment_settings
            }
            return i
        })
        this.setState({invoices}, callback)
    }

    changePaymentMethod = (key, value) => {
        if (key === 'payByACH' && value) {
            this.updateInvoiceCustomerPaymentSettings("payByACH", () => {
                this.setState({paymentModalPaymentMethod: key})
            })
        } else if (key === 'payByStripe' && value) {
            this.updateInvoiceCustomerPaymentSettings("payByStripe", () => {
                this.setState({paymentModalPaymentMethod: key})
            })
        } else if (key === 'payByManualPayment' && value) {
            this.updateInvoiceCustomerPaymentSettings("payByManualPayment", () => {
                this.setState({paymentModalPaymentMethod: key})
            })
        } else {
            this.updateInvoiceCustomerPaymentSettings("", () => {
                this.setState({paymentModalPaymentMethod: null})
            })
        }
    }

    render() {
        const {
            invoices, total, selectedRows, email, filterVisible,
            resource, dateFilterOptions, employees, InvoiceFilterModalShow,
            loaded, columnsSettings, showPaymentPopUp, paymentModalInvoice,
            paymentModalPaymentMethod
        } = this.state;
        const {sort, sort_by, page, per_page} = this.state.resource;
        const customerOptions = this.customerSearch.searchOptions();
        const selectedRowsEmail = invoices.filter(d => selectedRows?.includes(d.id)).map(d => {
            return {name: d.customer_name, email: d.email_address}
        })
        const siteOptions = this.siteSearch.searchOptions();
        const arboristOptions = this.arboristSearch.searchOptions();
        const proposalTitleOptions = this.proposalTitleSearch.searchOptions();
        const proposalNoOptions = this.proposalNoSearch.searchOptions();
        const workOrderOptions = this.workOrderSearch.searchOptions();
        const invoiceOptions = this.invoiceSearch.searchOptions();
        return (
            <Grid fluid id='invoices'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter">
                        <h2 className="no-top-margin mr-9">Invoices</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                    </Col>
                    <Col md={8} className="justify-flex-end">
                        {this.renderSettingsDropdown()}
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => this.setState({
                                InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow
                            })}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <hr className="no-margin"/>
                <Row>
                    {InvoiceFilterModalShow && <InvoicesFilterModal
                        resource={resource}
                        show={InvoiceFilterModalShow}
                        onHide={() => this.setState({InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow}, () => {
                            this.onFilterChange()
                        })
                        }
                        closeButton={() => this.setState({InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow})}
                        clearFiltersButton={this.clearFilters}
                        onDateTimeChange={(e, name) => {
                            this.state.resource[name] = (e && e !== '') ? e : null;
                            this.setState({resource: this.state.resource}, this.dateChangeCallback)
                        }}
                        onFocusDateTimeTo={() => {
                            if (resource.to === null) {
                                resource.to = moment().endOf('day')
                                this.setState(resource)
                            }
                        }}
                        onClickRadioButton={(name) => this.handleSelectDateFilterType(name)}
                        selectOptions={{
                            customerOptions,
                            siteOptions,
                            arboristOptions,
                            proposalTitleOptions,
                            proposalNoOptions,
                            workOrderOptions,
                            invoiceOptions
                        }}
                        search={{
                            customerSearch: this.customerSearch.search,
                            siteSearch: this.siteSearch.search,
                            arboristSearch: this.arboristSearch.search,
                            proposalTitleSearch: this.proposalTitleSearch.search,
                            proposalNoSearch: this.proposalNoSearch.search,
                            workOrderSearch: this.workOrderSearch.search,
                            invoiceSearch: this.invoiceSearch.search
                        }}
                        selectResourceAttr={(name) => this.selectResourceAttr(name)}
                        selectCheckboxAttr={(e, name) => {
                            this.state.resource[name] = e;
                            this.setState({resource: this.state.resource}, () => this.setState(resource))
                        }}
                        updateResourceAttr={(e) => this.updateResourceAttr(e, this.onNumericFilterChange)}
                        onInputBlur={(e, name) => this.dollarFormatAttr(e, this.reformatDollars(name))}
                        dollarInputValue={(name) => this.numericInputValueFormatterDollar(name)}
                    />}
                    {showPaymentPopUp && <PaymentModal
                        changePaymentMethod={this.changePaymentMethod}
                        show={showPaymentPopUp}
                        reload={this.reloadInvoicesPaymentModal}
                        invoice={paymentModalInvoice}
                        onHide={() => this.setState({showPaymentPopUp: false, paymentModalPaymentMethod: null})}
                        invoiceList={true}
                        payByACH={paymentModalPaymentMethod === "payByACH"}
                        payByStripe={paymentModalPaymentMethod === "payByStripe"}
                        payByManualPayment={paymentModalPaymentMethod === "payByManualPayment"}
                        newViewEnabled={true}
                    />}
                </Row>
                <Row className="top10">
                    <InvoicesTable
                        renderPaginationPanel = {this.renderPaginationPanel}
                        createCustomToolBar = {this.createCustomToolBar}
                        sizePerPageDropDown = {this.renderSizePerPageDropDown}
                        tableRef={this.tableRef}
                        total={total}
                        onRowSelect={this.onRowSelect}
                        onSelectAll={this.onSelectAll}
                        resource={resource}
                        updatePaging={this.updatePaging}
                        updateSorting={this.updateSorting}
                        columnsSettings={columnsSettings}
                        invoices={invoices}
                        loaded={loaded}
                        selectedRows={selectedRows}
                        doExportToXLSX={this.doExportToXLSX}
                        onPayButtonClick={this.onPayButtonClick}
                        email={this.email}
                    />
                </Row>
                {
                    email && <EmailDialog defaultEmail={this.props.email}
                                          sendBulkNotification={(email, callback) => {
                                              this.props.actions.sendBulkNotification(email, selectedRows, "bulk", email?.email_template_id, (res) => {
                                                  callback && callback(res.data)
                                              })
                                          }}
                                          isBulk={true}
                                          referenceId={selectedRows}
                                          templateMode
                                          emailType="bulk_invoiced"
                                          confirmMessage={true}
                                          selectedRecords={selectedRowsEmail}
                                          hideEmailDialog={() => {
                                              this.setState({email: null});
                                          }}
                                          sendInBackground={true}
                    />
                }
            </Grid>
        );
    }
}

Invoices.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Invoices.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        client: state.client.customerInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);