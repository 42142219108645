import React, {Component} from "react"

import {bindActionCreators} from "redux"

import {connect} from "react-redux"

import ThNav from "../../components/ThNav"
import Helmet from "react-helmet"
import CiAlert from "../../components/Alert"

import * as MyActions from "../App/actions"
import {deleteDevice, logout} from "../LoginPage/actions"
import "../../styles/alertAnimations.css"
import Poller from './contents/Poller';
import Secured from "../../common/Secured";
import Dock from "../../components/dock/Dock";
import createApiService from "../../common/clientActionsBuilder";
import Activities from "../Notifications/Activities";
import Messages from "../Notifications/Messages";
import Issues from "../Notifications/Issues/Issues";
import FollowUps from "../Notifications/FollowUps";
import EmailDialog from "../../components/email/EmailDialog";
import JobSiteAnalysis from "../JobSiteAnalysisPage/JobSiteAnalysis";
import {hideTaskDialog, getUserStatus, setEquipmentsChecklistTimeout, setEquipmentsChecklistInterval} from "./contents/actions";
import {UniversalStyle as Style} from 'react-css-component'
import {buildColorsCss} from "../../common/commonHandlers";
import SchedulerEventDialog from "../Scheduler/SchedulerEventDialog";
import './TopMenu.css';
import ProposalNotes from "../Notifications/ProposalNotes";
import {showDock} from "../../components/dock/actions"
import CrewMessages from "../Notifications/CrewMessages";
import {browserHistory} from "react-router";
import {ROLES} from "../../constants";
import IssuesRecorderToolbar from '../Notifications/Issues/IssuesRecorderToolbar'
import EquipmentChecklistModal from "../lists/EquipmentChecklistModal";
import * as equipmentActions from "../lists/EquipmentChecklistModalApi";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
const clientsApi = createApiService('clients', 'client', 'Client');
const usersApi = createApiService('users', 'user', 'User');

const Actions = Object.assign({}, MyActions,
    {
        showDock,
        logout,
        deleteDevice,
        hideTaskDialog,
        getUserStatus,
        setEquipmentsChecklistTimeout,
        setEquipmentsChecklistInterval,
        loadUsers: usersApi.load,
        loadClient: clientsApi.get,
        ...equipmentActions
    });

class AppWrapper extends Component {

    state = {
        updateVersion: false
    }

    componentWillMount() {
        const {isAuthenticated, auth, actions} = this.props
        this.makeRequestOnceEveryFullHour();
        if (isAuthenticated) {
            if (auth.client_id) {
                actions.loadClient(auth.client_id);
            }
            actions.loadUsers();
        }
    }

    componentDidMount() {
        const {isAuthenticated} = this.props

        if (isAuthenticated && !this.props.auth.clientAppVersion && this.props.auth.newClientAppVersion) {
            this.props.actions.setClientAppVersion(this.props.auth.newClientAppVersion)
        }

        if (isAuthenticated && this.props.auth.clientAppVersion !== null && this.props.auth.clientAppVersion !== this.props.auth.newClientAppVersion) {
            this.setState({updateVersion: true})
        }
    }

    componentDidUpdate() {
        const {isAuthenticated, perm, auth} = this.props

        if (isAuthenticated && !perm.loading && !auth.roles.includes(ROLES.superAdmin)) {
            if (!(perm.userTerms && (perm.adminTerms || !perm.adminTermsRequired))) {
                if (!(auth.roles.includes(ROLES.crew) && auth.roles.length === 1
                    || auth.roles.includes(ROLES.subcontractor) && auth.roles.length === 1
                    || auth.roles.includes(ROLES.crew, ROLES.subcontractor) && auth.roles.length === 2)) {
                    const url = window.location.href
                    if (!url.includes('login')) {
                        browserHistory.push('/login')
                    }
                }

            }
        }

        if (isAuthenticated && !this.state.updateVersion && this.props.auth.clientAppVersion !== null) {
            if (this.props.auth.clientAppVersion !== this.props.auth.newClientAppVersion) {
                this.setState({updateVersion: true})
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const {isAuthenticated, newMapView, newInvoicesView} = this.props

        if (isAuthenticated && nextProps.newMapView !== undefined && newMapView !== undefined
            && newMapView !== nextProps.newMapView) {
            window.location.reload();
        }
        if (isAuthenticated && nextProps.newInvoicesView !== undefined && newInvoicesView !== undefined
            && newInvoicesView !== nextProps.newInvoicesView) {
            window.location.reload();
        }
    }
    makeRequestOnceEveryFullHour() {
        const now = new Date();
        const minutesUntilNextHour = 60 - now.getMinutes();
        const millisecondsUntilNextHour = minutesUntilNextHour * 60000;

        const equipmentTimer = setTimeout(() => {
            if (this.props.isAuthenticated) {
                this.makeRequest();
            }

            const equipmentInterval = setInterval(() => {
                if (this.props.isAuthenticated) {
                    this.makeRequest();
                }
            }, 3600000);

            this.props.actions.setEquipmentsChecklistInterval(equipmentInterval)
        }, millisecondsUntilNextHour);

        this.props.actions.setEquipmentsChecklistTimeout(equipmentTimer)
    }

    makeRequest() {
        if (!this.props?.state?.equipment?.showEquipmentChecklistModal) {
            this.props.actions.checkEquipmentsChecklist(true, res => {
                if (res) {
                    this.props.actions.fetchChecklistModal(true)
                }
            })
        }
    }

    render() {
        const {
            isAuthenticated,
            email,
            name,
            client,
            alertMessages,
            roles,
            actions,
            theme,
            fullScreen,
            emails,
            jsa,
            showTaskDialog,
            docks,
            clientInfo,
            auth,
            isRecording,
            state
        } = this.props;
        let hasCrewRole = false
        const isCustomerView = window.location.href.includes('customer_view')
        if (roles && roles.length === 1 && (roles[0] === 'crew' || roles[0] === 'subcontractor' || roles[0] === 'technician')) {
            hasCrewRole = true
        }
        return (
            <div>
                <link rel="stylesheet" type="text/css" href={`/themes/${theme}/bootstrap.min.css`}/>
                <Helmet defaultTitle={isCustomerView ? "TreeHub" : client}/>
                <div className='alertStyles'>
                    {alertMessages.map(alert => (
                        <CiAlert
                            key={alert.id}
                            id={alert.id}
                            message={alert.message}
                            dismissAfter={alert.dismissAfter}
                            mood={alert.mood}
                            onDismiss={actions.clearAlert}
                        />
                    ))}
                </div>
                {this.state.updateVersion &&
                    <Alert className="bump-up-version-banner bottom0 vcenter">
                        <FontAwesomeIcon
                            className="font20 ml-3 mr-5"
                            icon={faInfoCircle}
                            data-toggle={'tooltip'}
                            title={'New version available'}
                        />
                        A new version of TreeHub is available. Please save your work and
                        <a
                            onClick={() => {
                                this.props.actions.setClientAppVersion(this.props.auth.newClientAppVersion)
                                window.location.reload();
                            }}
                            className="pointer mr-5 ml-6"
                            style={{color: '#000080'}}
                        >
                            <u>CLICK HERE</u>
                        </a>
                        to update.
                    </Alert>
                }


                {!this.props.location.pathname.includes('/customer_view/') &&
                    !this.props.location.pathname.includes('/cv/') &&
                    !this.props.location.pathname.includes('/questionnaire/') &&
                    !this.props.location.pathname.includes('/reset_password/') &&
                    !this.props.location.pathname.includes('subscription_renew') &&
                    !this.props.location.pathname.includes('subscription_expired') &&
                    !fullScreen && <ThNav
                        logout={() => {
                            if (auth && auth.user_id) {
                                actions.deleteDevice({
                                    user_id: auth.user_id,
                                    token: auth.token
                                }, actions.logout)
                            } else {
                                actions.logout()
                            }
                        }}
                        isAuthenticated={isAuthenticated}
                        roles={roles}
                        email={email}
                        client={client}
                        name={name}
                        updateVersion={this.state.updateVersion}
                    />
                }
                {isRecording && <IssuesRecorderToolbar/>}
                <Style css={buildColorsCss()}/>
                {
                    isAuthenticated &&
                    <Secured roles="!it_engineering">
                        <Poller/>
                    </Secured>
                }

                <Secured roles="!it_engineering">
                    <Dock type="activities" position="right">
                        <Activities count={25} type="activities"/>
                    </Dock>
                </Secured>
                {isAuthenticated && <Secured roles="!it_engineering">
                    <Dock type="messages" position="right" custom_title={'Customer messages'}>
                        <Messages count={25} type="messages"/>
                    </Dock>
                </Secured>}
                {isAuthenticated && <Issues/>}
                <Secured roles="!it_engineering">
                    <Dock type="follow ups" position="right">
                        <FollowUps count={25} type="follow ups"/>
                    </Dock>
                </Secured>
                <Secured roles="!it_engineering">
                    <Dock type="crew messages" position="right">
                        <CrewMessages count={25} type="crew messages"/>
                    </Dock>
                </Secured>
                <Secured roles="!it_engineering">
                    <Dock type="proposal notes" position="right">
                        <ProposalNotes count={25} type="follow ups"/>
                    </Dock>
                </Secured>

                {emails.active &&
                <EmailDialog
                    emailType={emails.options.emailType}
                    referenceId={emails.options.referenceId}
                    recipient={emails.options.recipient}
                    selectedRecords={emails.options.selectedRecords}
                    confirmMessage={emails.options.confirmMessage}
                    bulkError={emails.options.bulkError}
                    templateMode={emails.options.templateMode}
                    cc_recipients={emails.options.cc_recipients}
                    handleAction={emails.options.handleAction}
                    withFollowUpDate={emails.options.withFollowUpDate}
                    defaultEmail={emails.options.defaultEmail}
                    dataPdf={emails.options.dataPdf}
                    client={emails.options.client}
                    technicianOnly={emails.options.technicianOnly}
                    sendInBackground={emails.options.sendInBackground}
                    bounced={emails.options.bounced}
                    error={emails.options.error}
                />
                }

                {emails.errors?.length > 0 && <EmailDialog
                    key={emails.errors?.length}
                    emailType={emails.errors[emails.errorIndex].emailType}
                    referenceId={emails.errors[emails.errorIndex].referenceId}
                    recipient={emails.errors[emails.errorIndex].recipient}
                    confirmMessage={emails.errors[emails.errorIndex].bulk.confirmMessage}
                    bulkError={emails.errors[emails.errorIndex].bulk.error}
                    isBulk={emails.errors[emails.errorIndex].bulk.isBulk}
                    bulkEmailData={emails.errors[emails.errorIndex].bulkEmailData}
                    templateMode={emails.errors[emails.errorIndex].templateMode}
                    selectedRecords={emails.errors[emails.errorIndex].selectedRecords}
                    templateId={emails.errors[emails.errorIndex].emailTemplateId}
                    cc_recipients={emails.errors[emails.errorIndex].cc_recipients}
                    handleAction={emails.errors[emails.errorIndex].handleAction}
                    withFollowUpDate={emails.errors[emails.errorIndex].withFollowUpDate}
                    defaultEmail={emails.errors[emails.errorIndex].defaultEmail}
                    dataPdf={emails.errors[emails.errorIndex].dataPdf}
                    client={emails.errors[emails.errorIndex].client}
                    sendInBackground={emails.errors[emails.errorIndex].sendInBackground}
                    bounced={emails.errors[emails.errorIndex].bounced}
                    emailError={emails.errors[emails.errorIndex].error}
                />}

                {jsa.active && <JobSiteAnalysis
                    lockedCrewEditAccess={hasCrewRole && (clientInfo && clientInfo.block_crews_modal_access)}
                    siteId={jsa.options.siteId}/>}
                {showTaskDialog && <SchedulerEventDialog
                    handleClose={this.props.actions.hideTaskDialog}
                    event={{}}
                />}
                {state?.equipment?.showEquipmentChecklistModal &&
                    <EquipmentChecklistModal
                        onHide={() => this.props.actions.fetchChecklistModal(false)}
                        getEquipmentsChecklist={this.props.actions.getEquipmentsChecklist}
                        saveEquipmentsQuestions={this.props.actions.saveEquipmentsQuestions}
                        getUserStatus={() => this.props.actions.getUserStatus(this.props.auth.user_id)}
                    />
                }
                <div onClick={() => docks.active && docks.active !== 'notes' && this.props.actions.showDock(null)}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        perm: state.perm,
        email: state.auth.email,
        isAuthenticated: state.auth.isAuthenticated,
        alertMessages: state.app.alertMessages,
        isProcessing: state.app.isProcessing,
        roles: state.auth.roles,
        name: state.auth.name,
        client: state.auth.client,
        clientInfo: state.client.customerInfo,
        theme: state.theme.theme,
        previousTheme: state.theme.previousTheme,
        emails: state.emails,
        jsa: state.jsa,
        showTaskDialog: state.userStatus.showTaskDialog,
        newMapView: state.auth.newMapView,
        newInvoicesView: state.auth.newInvoicesView,
        docks: state.docks,
        fullScreen: state.mapView.fullScreen,
        customerInfo: state.customerInfo.customerInfo,
        isRecording: state.recorder.isRecording,
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
