import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import EmailEditor from "./EmailEditor";
import * as api from './EmailEditorApi'
import {getClient} from './EmailEditorApi'
import * as actions from './actions'
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {SendgridModal} from "../../containers/Sendgrid/SendgridModal";
import BlockedRecipientsModal from "./BlockedRecipientsModal";
import EmailDraftModal from "./EmailDraftModal";
import {updateExternalLinks} from "../../common/commonHandlers";
import {appendEmailError, setEmailErrors, showEmailDialog} from "./actions";
import {sendBulkEmail} from "../../containers/SalesDashboard/SalesDashboardApi";
import {sendBulkEmail as sendBulkEmailNoRows} from "../../containers/InvoicesPage/InvoicesApi";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const Actions = {getClient, showEmailDialog, setEmailErrors, appendEmailError, sendBulkEmail, sendBulkEmailNoRows, ...api, ...actions};

class EmailDialog extends Component {

    state = {
        attachments: [],
        showSendgridModal: false,
        sendgridEmailWasSend: false,
        senderVerified: true,
        showBlockedRecipientsModal: {show: false, blocked_recipients: []},
        loadingEmails: false,
        duplicateEmailTemplateModal: {
            show: false,
            emailTemplate: null
        },
        draftSaved: false,
        backgroundPdfToken: null
    };

    componentDidMount() {
        this.hideEmailDialog = this.props.hideEmailDialog || this.props.actions.hideEmailDialog;
        this.setTextMessagesEnabled()
        if(this.props.bounced) {
            this.setState({
                showBlockedRecipientsModal: {
                    show: true,
                    blocked_recipients: this.props.bounced
                }
            })
        }
        this.props.actions.checkIfSenderVerified((res) => {
            if (!res.verified && res.was_send) {
                this.setState({sendgridEmailWasSend: res.was_send, senderVerified: res.verified})
            } else if (!res.verified && !res.was_send) {
                this.setState({sendgridEmailWasSend: res.was_send, senderVerified: res.verified})
            } else {
                this.setState({senderVerified: res.verified})
            }
        })
        this.setState({selectedRows: this.props.selectedRecords})
    }

    setShowSendgridModal = (value) => {
        this.setState({showSendgridModal: value})
    }

    setTextMessagesEnabled = () => {
        if (this.props.customerInfo?.text_messages_enabled !== undefined) {
            this.setState({text_messages_enabled: this.props.customerInfo.text_messages_enabled})
        } else {
            this.props.actions.getClient(this.props.customerInfo?.client_id, res => {
                this.setState({text_messages_enabled: res.text_messages_enabled})
            })
        }
    }

    handleChange = (resource) => {
        this.setState(resource);
    };

    attachBackgroundPdf = (attachment, token) => {
        const attachments = _.cloneDeep(this.state.attachments);
        attachments.push({
            filename: `${attachment.client_name}-Invoice #${attachment.number}.pdf`,
            token: token
        });
        this.setState({attachments: _.uniqBy(attachments, "filename")});
    }

    uploadAttachment = (attachment) => {
        this.props.actions.uploadAttachment(attachment, upload => {
            const attachments = _.cloneDeep(this.state.attachments);
            attachments.push(upload);
            this.setState({attachments: _.uniqBy(attachments, "filename")});
        })
    };

    removeAttachment = (token) => {
        const attachments = _.cloneDeep(this.state.attachments).filter(a => a.token !== token);
        this.setState({attachments: _.uniqBy(attachments, "filename")});
    };
    setEmailTemplate = (template) => {
        this.setState({
            emailTemplateId: template.id,
            textMessageTemplateId: template.text_message_template_id,
            emailTemplateRawMessage: template.message
        })
    }

    sendNotification = () => {
        this.setState({loading: true})
        this.props.actions.sendNotification(this.props.emailType, this.props.referenceId, this.state.emailTemplateId, (res) => {
            if (res.data.message === 'queued') {
                this.setState({loading: false, notificationSent: true})
            } else {
                this.setState({loading: false})
            }
        })
    }
    sendBulkNotification = () => {
        this.setState({loading: true})
        this.props.sendBulkNotification({
            ...this.state.resource,
            reference_id: this.props.referenceId,
            email_type: this.props.emailType,
            email_template_id: this.state.emailTemplateId
        }, (res) => {
            if (res.message === 'queued') {
                this.setState({loading: false, notificationSent: true})
            } else {
                this.setState({loading: false})
            }
        })
    }

    handleBulkError = (email, errors) => {
        if(!_.isEmpty(errors)) {
            const totalErrors = [...this.props.emailsErrorsState, ...errors.map(e => {
                let customer_name = "";
                let selected_record = null;

                if (this.props.emailType === "bulk") {
                    const rowIndex = this.props.bulkEmailData.recipients.indexOf(e.reference_id);
                    if(rowIndex !== -1) {
                        customer_name = this.state.selectedRows[rowIndex]?.name
                        selected_record = [this.props.selectedRecords[rowIndex]]
                    }
                } else if (this.props.emailType === "bulk_invoiced") {
                    customer_name = this.state.emailModalRecipients?.find(r => r.invoice_ids.includes(e.reference_id))?.customer || ""
                }

                return {
                    emailType: this.props.emailType,
                    referenceId: [e.reference_id.toString()],
                    defaultEmail: this.props.defaultEmail,
                    sendInBackground: true,
                    bulkEmailData: this.props.bulkEmailData,
                    templateMode: true,
                    selectedRecords: selected_record,
                    emailTemplateId: email.template_id,
                    bounced: e.blocked_recipients ? [JSON.parse(e.blocked_recipients)] : null,
                    bulk: {
                        selectedRecords: this.props.bulkEmailData?.selectedRows,
                        recipients: this.props.bulkEmailData?.recipients,
                        error: {
                            ...e,
                            customer_name: customer_name
                        },
                        confirmMessage: true,
                        isBulk: true
                    }
                }
            })]
            this.props.actions.setEmailErrors(_.uniqBy(totalErrors, obj => obj.referenceId.join(',')), true);
        }
    }

    sendBulkEmail = (email) => {
        if(this.props.bulkEmailData) {
            this.props.actions.sendBulkEmail(
                this.props.bulkEmailData.recipients,
                email,
                this.props.bulkEmailData.selectedRows,
                (errors) => {
                    this.handleBulkError(email, errors)
                })
        } else {
            this.props.actions.sendBulkEmailNoRows(this.props.referenceId, email, (errors) => {
                this.handleBulkError(email, errors)
            })
        }
    }

    submit = () => {
        if(!this.props.sendInBackground) {
            this.setState({loadingEmails: true})
        }

        const filteredErrors = this.props.emailsErrorsState.filter(e => {
            const referenceIdArray = Array.isArray(this.props.referenceId)
                ? this.props.referenceId
                : [this.props.referenceId];

            const eReferenceIdArray = Array.isArray(e.referenceId)
                ? e.referenceId
                : [e.referenceId];

            return referenceIdArray.join(',') !== eReferenceIdArray.join(',');
        });
        this.props.actions.setEmailErrors(filteredErrors);

        let sendEmailAction = this.props.isBulk ? this.sendBulkEmail : this.props.sendEmail || this.props.actions.sendEmail;
        sendEmailAction(
            {
                ...this.state.resource,
                message: updateExternalLinks("prod", this.state.resource.message),
                attachments: this.state.attachments.map(a => a.token),
                cc_recipients: this.state.resource.cc_recipients && this.state.resource.cc_recipients.map(cc => cc.email),
                bcc_recipients: this.state.resource.bcc_recipients.map(bcc => bcc.email),
                reference_id: this.props.referenceId,
                email_type: this.props.emailType,
                invoice_emails: this.props.emailType === 'bulk_invoiced' && this.state.emailRecipients,
                template_id: this.state.emailTemplateId,
                generate_invoice_pdf: true,
                dialog_options: this.props.sendEmail ? {
                    emailType: this.props.emailType,
                    referenceId: this.props.referenceId,
                    recipient: this.props.recipient,
                    cc_recipients: this.props.cc_recipients,
                    defaultEmail: this.props.defaultEmail,
                    dataPdf: this.props.dataPdf,
                    client: this.props.client,
                    sendInBackground: true
                } : null
            }, ({data}) => {
                const error = data
                if(!_.isEmpty(error)) {
                    this.props.actions.appendEmailError({
                        emailType: this.props.emailType,
                        referenceId: this.props.referenceId,
                        defaultEmail: this.props.defaultEmail,
                        recipient: this.props.recipient,
                        cc_recipients: this.props.cc_recipients,
                        sendInBackground: true,
                        bulkEmailData: this.props.bulkEmailData,
                        emailTemplateId: this.state.emailTemplateId,
                        dataPdf: this.props.dataPdf,
                        client: this.props.client,
                        bulk: {
                            confirmMessage: false,
                            isBulk: false
                        },
                        error: {
                            message: error.error,
                            customer_name: this.props.dataPdf?.customer_name || ""
                        },
                        bounced: error.blocked_recipients ? [JSON.parse(error.blocked_recipients)] : null
                    }, true)
                }
                if(!this.props.sendInBackground) {
                    this.setState({loadingEmails: false})
                    if (this.props.onHide) {
                        this.props.onHide();
                    } else {
                        this.hideEmailDialog();
                    }
                    this.props.handleAction && this.props.handleAction();
                }
            }, (res) => {
                this.props.actions.appendEmailError({
                    emailType: this.props.emailType,
                    referenceId: this.props.referenceId,
                    defaultEmail: this.props.defaultEmail,
                    recipient: this.props.recipient,
                    cc_recipients: this.props.cc_recipients,
                    sendInBackground: true,
                    bulkEmailData: this.props.bulkEmailData,
                    emailTemplateId: this.state.emailTemplateId,
                    dataPdf: this.props.dataPdf,
                    client: this.props.client,
                    bulk: {
                        confirmMessage: false,
                        isBulk: false
                    },
                    error: {
                        error: "Unknown Error",
                        customer_name: ""
                    }
                }, true)
                if(!this.props.sendInBackground) {
                    this.setState({loadingEmails: false})
                    if (res && res.data.blocked_recipients) {
                        this.setState({
                            showBlockedRecipientsModal: {
                                show: true,
                                blocked_recipients: res.data.blocked_recipients
                            }
                        })
                    }
                }
            });
        if(this.props.sendInBackground && filteredErrors.length === 0) {
            if (this.props.onHide) {
                this.props.onHide();
            } else {
                this.hideEmailDialog();
            }
        }
    };

    handleDuplicateTemplateButton = () => {

        this.setState({
            duplicateEmailTemplateModal: {
                show: true,
                emailTemplate: {
                    name: this.state.emailTemplate.label + " - Duplicate",
                    subject: this.state.resource.subject,
                    message: this.state.emailTemplateRawMessage
                }
            }
        })
    }

    duplicateTemplate = (emailTemplate) => {
        const {resource} = this.state
        let duplicatedEmailTemplate = {
            email_template: {
                from: resource.from,
                links: [],
                message: updateExternalLinks("prod", emailTemplate.message),
                name: emailTemplate.name,
                reply_to: resource.reply_to,
                subject: emailTemplate.subject
            }
        }
        this.props.actions.duplicateTemplate(duplicatedEmailTemplate, newTemplate => {
            newTemplate.value = newTemplate.id
            this.state.setCurrentEmail(newTemplate)
            this.setState({
                duplicateEmailTemplateModal: {
                    show: false,
                    emailTemplate: null
                },
                draftSaved: true
            })
        })

    }

    changeDraftSavedStatus = () => {
        this.setState({draftSaved: false});
    }

    removeRecipientFromSelectedRows = (index) => {
        const {selectedRows} = this.state

        const newSelectedRows = selectedRows.filter((s, i) => i !== index)
        this.setState({selectedRows: newSelectedRows})
    }

    removeAllRecipientsWithoutEmail = () => {
        const {selectedRows} = this.state

        if (selectedRows && selectedRows.length > 0) {
            let resultRows = []

            selectedRows.map(r => {
                if (r.email && r.email.length > 0) {
                    resultRows.push(r)
                }
            })

            this.setState({selectedRows: resultRows})
        }
    }

    setCurrentEmailFunction = (setter) => {
        this.setState({setCurrentEmail: setter})
    }

    render() {
        let headerString = "New email";
        if (this.props.emailError) {
            switch (this.props.emailError?.message) {
                case "email_bounced":
                    headerString = `Sending email to ${this.props.emailError?.customer_name} failed. Cause: Email Bounced`
                    break;
                case "pdf_timeout":
                    headerString = `Sending email to ${this.props.emailError?.customer_name} failed. Cause: Pdf timed out`
                    break;
                case "pdf_fail":
                    headerString = `Sending email to ${this.props.emailError?.customer_name} failed. Cause: Pdf generation failed`
                    break;
                default:
                    headerString = `Sending email to ${this.props.emailError?.customer_name} failed. Cause: Unknown`
                    break;
            }
        } else if (this.props.bulkError) {
            switch (this.props.bulkError?.error) {
                case "email_bounced":
                    headerString = `Sending email to ${this.props.bulkError?.customer_name} failed. Cause: Email Bounced`
                    break;
                case "pdf_timeout":
                    headerString = `Sending email to ${this.props.bulkError?.customer_name} failed. Cause: Pdf timed out`
                    break;
                case "pdf_fail":
                    headerString = `Sending email to ${this.props.bulkError?.customer_name} failed. Cause: Pdf generation failed`
                    break;
                default:
                    headerString = `Sending email to ${this.props.bulkError?.customer_name} failed. Cause: Unknown`
                    break;
            }
        }

        return (
            <>{this.state.duplicateEmailTemplateModal.show && <EmailDraftModal
                showModal={this.state.duplicateEmailTemplateModal.show}
                emailTemplate={this.state.duplicateEmailTemplateModal.emailTemplate}
                duplicateTemplate={this.duplicateTemplate}
                closeModal={() => this.setState({
                    duplicateEmailTemplateModal: {
                        show: false,
                        emailTemplate: null
                    }
                })}
            />}
            <Modal
                bsSize="large"
                animation={false}
                show={true}
                onHide={() => {
                    const filteredErrors = this.props.emailsErrorsState.filter(e => {
                        const referenceIdArray = Array.isArray(this.props.referenceId)
                            ? this.props.referenceId
                            : [this.props.referenceId];

                        const eReferenceIdArray = Array.isArray(e.referenceId)
                            ? e.referenceId
                            : [e.referenceId];

                        return referenceIdArray.join(',') !== eReferenceIdArray.join(',');
                    });
                    this.props.actions.setEmailErrors(filteredErrors);
                    if(filteredErrors.length > 0) {
                        return
                    }

                    this.props.onHide ? this.props.onHide() : this.hideEmailDialog()
                }}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className={this.props.bulkError || this.props.emailError ? "colorRed" : ""}>
                        {headerString}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmailEditor
                        emailType={this.props.emailType}
                        bulkEmailType={this.props.bulkEmailType}
                        referenceId={this.props.referenceId}
                        handleChange={this.handleChange}
                        defaultEmail={this.props.defaultEmail}
                        recipient={this.props.recipient}
                        cc_recipients={this.props.cc_recipients}
                        withFollowUpDate={this.props.withFollowUpDate}
                        uploadAttachment={this.uploadAttachment}
                        removeAttachment={this.removeAttachment}
                        attachments={this.state.attachments}
                        message={this.props.message}
                        templateMode={this.props.templateMode}
                        dataPdf={this.props.dataPdf}
                        attachPdf={this.attachPdf}
                        client={this.props.client}
                        confirmMessage={this.props.confirmMessage}
                        selectedRows={this.state.selectedRows}
                        setEmailTemplate={this.setEmailTemplate}
                        draftSaved={this.state.draftSaved}
                        changeDraftSavedStatus={this.changeDraftSavedStatus}
                        setCurrentEmail={this.setCurrentEmailFunction}
                        removeRecipientFromSelectedRows={this.removeRecipientFromSelectedRows}
                        removeAllRecipientsWithoutEmail={this.removeAllRecipientsWithoutEmail}
                        technicianOnly={this.props.technicianOnly}
                        attachBackgroundPdf={this.attachBackgroundPdf}
                        templateId={this.props.templateId}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="text-left" xs={6}>
                            <Button
                                bsSize="small"
                                bsStyle="warning"
                                onClick={() => {
                                    const filteredErrors = this.props.emailsErrorsState.filter(e => {
                                        const referenceIdArray = Array.isArray(this.props.referenceId)
                                            ? this.props.referenceId
                                            : [this.props.referenceId];

                                        const eReferenceIdArray = Array.isArray(e.referenceId)
                                            ? e.referenceId
                                            : [e.referenceId];

                                        return referenceIdArray.join(',') !== eReferenceIdArray.join(',');
                                    });
                                    this.props.actions.setEmailErrors(filteredErrors);
                                    if(filteredErrors.length > 0) {
                                        return
                                    }

                                    if (this.props.onHide) {
                                        this.props.onHide();
                                    } else {
                                        this.hideEmailDialog();
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>

                            <Col className="text-right ali" xs={6}>
                                {/*Uncomment when Sendgrid disable sending from not verified emails*/}
                                {/*{!this.state.senderVerified && <OverlayTrigger placement="top"*/}
                                {/*                                                      overlay={<Tooltip*/}
                                {/*                                                          id="tooltip">*/}
                                {/*                                                          Your email is not verified, If you want to verify your email, please click here.*/}
                                {/*                                                      </Tooltip>}>*/}
                                {/*    <FontAwesomeIcon*/}
                                {/*        onClick={() => this.setShowSendgridModal(true)}*/}
                                {/*        className="bigger pointer mr-10 text-danger"*/}
                                {/*        icon={faExclamationCircle}/>*/}
                                {/*</OverlayTrigger>}*/}
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    disabled={!this.state.emailTemplate}
                                    onClick={this.handleDuplicateTemplateButton}
                                    type="submit"
                                >
                                    Duplicate template
                                </Button>
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    onClick={this.submit}
                                    type="submit"
                                    disabled={
                                        this.state.loadingEmails ||
                                        !this.state.resource?.subject && !this.state.resource?.subject.length > 0 ||
                                        !this.state.resource?.message && !this.state.resource?.message.length > 0 ||
                                        (this.props.emailType !== 'bulk_invoiced' ?
                                            this.props.emailType === 'bulk' ?
                                                !this.state.selectedRows?.every(r => r.email?.length > 0) :
                                                !this.state.resource?.recipient && !this.state.resource?.recipient.length > 0 :
                                            !this.state.emailRecipients?.some(r => r.emails?.some(e => e.show)))
                                    }
                                >
                                    {this.state.loadingEmails ? "Sending..." : "Send Email"}
                                </Button>
                                {this.state.text_messages_enabled && <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    onClick={this.props.sendBulkNotification ? this.sendBulkNotification : this.sendNotification}
                                    type="submit"
                                    disabled={this.state.notificationSent || !this.state.textMessageTemplateId}
                                >
                                    {this.state.loading ? "Sending..." : this.state.notificationSent ? "Text sent" : "Send Text"}
                                </Button>}
                            </Col>
                        </Row>
                    </Modal.Footer>
                    <SendgridModal show={this.state.showSendgridModal}
                                   closeModal={() => this.setShowSendgridModal(false)}
                                   sendgridEmailWasSend={this.state.sendgridEmailWasSend}
                                   verifySender={this.props.actions.verifySender}
                                   resendVerifyEmail={this.props.actions.resendVerifyEmail}
                    />
                    {this.state.showBlockedRecipientsModal?.show &&
                        <BlockedRecipientsModal
                            onHide={() => this.setState({
                                showBlockedRecipientsModal: {
                                    show: false,
                                    blocked_recipients: []
                                }
                            })}
                            blockedRecipients={this.state.showBlockedRecipientsModal?.blocked_recipients}
                        />
                    }
                </Modal>
            </>
        )
    }
}

EmailDialog.propTypes = {
    emailType: PropTypes.string,
    bulkEmailType: PropTypes.string,
    referenceId: PropTypes.number,
    defaultEmail: PropTypes.string,
    recipient: PropTypes.string,
    cc_recipients: PropTypes.array,
    message: PropTypes.string,
    withFollowUpDate: PropTypes.bool,
    handleAction: PropTypes.func,
    onEmailSend: PropTypes.func,
    sendEmail: PropTypes.func,
    hideEmailDialog: PropTypes.func,
    templateMode: PropTypes.bool,
    sendInBackground: PropTypes.bool,
    bounced: PropTypes.array
};

function mapStateToProps(state, ownProps) {
    return {
        customerInfo: state.client.customerInfo,
        emailsErrorsState: state.emails.errors,
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailDialog)
