import {Button, Col, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import {defaultDateFormat, defaultDateTimeFormat, defaultTimeFormat, mapForSelect, select} from "../../common/commonHandlers";
import moment from "moment";
import Datetime from "react-datetime";
import React from "react";
import Select from "react-select";
import './TermModal.css';
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import Payments from "./Payments";
import ColorCheckbox from "../Scheduler/ColorCheckbox";


const isMobile = window.screen.width <= 1024;

class TermModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {
                invoiced_at: moment(),
                paid_at: null,
            },
            isTermValidated: true,
            isTermTypeValidated: true,
            isPaymentValidated: true,
            isDateValidated: true,
            isEdited: false,
            isPaid: false,
            payment: {
                payment_date: null,
                amount: 0
            },
            partial: false,
            paymentAdded: false,
            addingPayments: false
        };


    }

    parseDecimal = (number) => {
        number = parseFloat(number)
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            style: "decimal"
        });
        return formatter.format(number)

    }

    resetValues = () => {
        this.setState({isTermValidated: true, isPaymentValidated: true, isDateValidated: true, payment_date: moment()})

    }


    componentWillReceiveProps(nextProps) {
        const {payment} = this.state;
        this.setState({paymentAdded: false})
        if (nextProps.newInvoiceAmount) {
            payment.amount = nextProps.newInvoiceAmount
            this.setState({payment})
        }
        if (nextProps.invoice) {
            let amount = nextProps.invoice.total
            if (nextProps.invoice.payments) {
                for (let p of nextProps.invoice.payments.filter(p => p.status !== 'refunded')) {
                    amount -= p.amount
                }
            }
            payment.amount = amount
            const isPaid = payment.amount === 0
            this.setState({resource: nextProps.invoice, payment, isPaid})
        }

    }


    render() {
        const term_types = mapForSelect(this.props.term_types);
        const {
            resource,
            isEdited,
            isPaid,
            isDateValidated,
            isPaymentValidated,
            isTermValidated,
            isTermTypeValidated,
            payment,
            addingPayments
        } = this.state;
        const {invoice, manualPaymentTypes} = this.props;


        let totalPaidAmount = 0;
        if (invoice && invoice.payments && invoice.payments.length > 0) {
            invoice.payments.forEach(payment => {
                totalPaidAmount += +payment.amount
            })
            if (parseFloat(invoice.total) === parseFloat(totalPaidAmount)) {
                payment.amount = 0
            }
        }

        return (
            <Modal
                {...this.props}
                id="term_modal"
                bsSize="big"
                animation={true}
                onHide={() => {
                    this.resetValues()
                    this.props.onHide()
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{invoice ? `Invoice #${invoice.no}` : `New Invoice`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="top15">
                    <Row>
                        <Col md={6} className={isMobile ? 'bottom15' : null}>
                            <div><strong className="small-margin">Term</strong></div>
                            <Select className={`Select ${isTermTypeValidated ? '' : 'invalid'}`} classNamePrefix="select"
                                    placeholder="Term"
                                    value={select(term_types, resource.term_type_id)}
                                    onChange={e => {
                                        this.selectResourceAttr('term_type_id')(e);
                                        const term = this.props.term_types.find(term => term.id === e.value);
                                        const days = term.term;
                                        const invoiced = moment(resource.invoiced_at);
                                        if (term.name === 'Last Day Of Month') {
                                            resource.due_date = invoiced.endOf('month')
                                        } else {
                                            resource.due_date = moment(invoiced, "DD-MM-YYYY").add(days, 'days');
                                        }
                                        this.setState(resource);
                                    }}
                                    onBlur={()=>{this.setState({isTermTypeValidated: !!resource.term_type_id})}}
                                    options={term_types}
                            />
                        </Col>
                        <Col md={6}>
                            <div><strong className="small-margin">Invoice Date</strong></div>
                            <Datetime
                                className='modal-datetime'
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={moment(resource.invoiced_at)}
                                inputProps={{placeholder: 'Invoice Date'}}
                                onChange={this.dateResourceAttr('invoiced_at')}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6} className={isMobile ? 'bottom15' : null}>
                            <div><strong className="small-margin">Due Date</strong></div>
                            <Datetime
                                className='modal-datetime'
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={moment(resource.due_date)}
                                inputProps={{placeholder: 'Due Date'}}
                                onChange={this.dateResourceAttr('due_date')}
                            />
                        </Col>
                        <Col md={6}>
                            <div><strong className="small-margin">Final Paid Date</strong></div>
                            <Datetime
                                className={isTermValidated ? 'modal-datetime' : 'validation-error modal-datetime'}
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={resource.paid_at ? moment(resource.paid_at) : null}
                                inputProps={{placeholder: 'Paid date', disabled: this.state.isPaid}}
                                onChange={e => {
                                    let newValue = null
                                    if (moment(e).isValid() && moment(e).format(defaultDateTimeFormat) !== "Invalid date") {
                                        newValue = e
                                    }
                                    this.dateResourceAttr('paid_at', () => {
                                        let {resource} = this.state;
                                        resource.proposal_service_id = invoice && invoice.proposal_service_id;
                                        this.setState(resource)
                                    })(newValue)
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    {!isPaid && <><Row>
                        <Col md={3} className={isMobile ? 'bottom15' : null}>
                            <div><strong className="small-margin">Amount</strong></div>
                            <InputGroup>
                                <InputGroupAddon>
                                    $
                                </InputGroupAddon>
                                {isEdited && this.state.partial && !resource.paid_at ?
                                    <FormControl
                                        disabled={this.props.newInvoiceAmount}
                                        type="text"
                                        className={isPaymentValidated ? null : 'validation-error'}
                                        onChange={(e) => {
                                            let {resource, payment} = this.state;
                                            payment.amount = (e && e !== '' && e.target.value !== '') ? e.target.value : 0;
                                            resource.proposal_service_id = invoice && invoice.proposal_service_id;
                                            this.setState(payment)
                                        }}
                                        onBlur={() => this.setState({
                                            isEdited: false,
                                            isPaymentValidated: payment.amount > 0 || +invoice.total === 0 && payment.amount >= 0
                                        })}
                                        value={payment.amount || this.props.newInvoiceAmount}
                                    />
                                    :
                                    <FormControl
                                        className={isPaymentValidated ? null : 'validation-error'}
                                        type="text"
                                        onFocus={() => this.setState({isEdited: true})}
                                        onBlur={() => this.setState({isPaymentValidated: payment.amount > 0 || +invoice?.total === 0 && payment.amount >= 0})}
                                        value={this.parseDecimal(payment.amount)}
                                    />}
                            </InputGroup>

                        </Col>

                        <Col md={3}>
                            <Select placeholder="Payment method"
                                    className="mt-16"
                                    options={manualPaymentTypes}
                                    menuPlacement="top"
                                    value={select(manualPaymentTypes, payment?.payment_type_id)}
                                    onChange={(e) => {
                                        let {payment} = this.state;
                                        this.setState({payment: {...payment, payment_type_id: e.value}})
                                    }}
                            />
                        </Col>

                        {this.state.partial && !resource.paid_at &&
                        <Col md={4} className={isMobile ? 'bottom15' : null}>
                            <div><strong className="small-margin">Partial payment date</strong></div>
                            <Datetime
                                className={isDateValidated ? 'modal-datetime' : 'validation-error modal-datetime'}
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={defaultTimeFormat}
                                value={moment(payment.payment_date)}
                                inputProps={{placeholder: 'Payment date'}}
                                onChange={e => {
                                    let {resource, payment} = this.state;
                                    payment.payment_date = (e && e !== '') ? e : null;
                                    resource.proposal_service_id = invoice && invoice.proposal_service_id;
                                    this.setState(payment)
                                }}
                            />
                        </Col>}

                        {invoice && <Col md={2} className={isMobile ? 'bottom15' : 'term-modal-input'}>
                            <Button
                                disabled={(invoice && invoice.total === 0) || this.props.loading || this.state.paymentAdded}
                                onClick={() => {
                                    if ((+invoice.total !== 0 && payment.amount <= 0 || payment.amount > (parseFloat(invoice.total) - (invoice.payments?.reduce((a, b) => a + (+b['amount'] || 0), 0) || 0))) || +invoice.total === 0 && payment.amount < 0) {
                                        this.setState({isPaymentValidated: false})
                                    } else if (this.state.partial && !payment.payment_date) {
                                        this.setState({isDateValidated: false})
                                    } else {
                                        this.setState({paymentAdded: true, addingPayments: true}, () => {
                                            this.props.onPaymentAdd(invoice.token, payment.amount, resource.paid_at ? resource.paid_at : payment.payment_date, payment.payment_type_id, payment.reference_no, () => {
                                                this.props.onHide()
                                                this.setState({addingPayments: false})
                                            })
                                            this.resetValues()
                                        })
                                    }
                                }}>
                                Add
                            </Button>
                        </Col>}
                    </Row>
                        <Row>
                            {!resource.paid_at &&
                            <>
                                <Col md={3} className={isMobile ? 'bottom15' : 'term-modal-input'}>
                                    <ColorCheckbox value={this.state.partial}
                                                   label='Partial payment'
                                                   onChange={e => {
                                                       let {payment} = this.state;
                                                       let {invoice} = this.props;
                                                       payment.payment_date = moment();

                                                       let amount = invoice.total
                                                       if (invoice.payments) {
                                                           for (let p of invoice.payments.filter(p => p.status !== 'refunded')) {
                                                               amount -= p.amount
                                                           }
                                                       }
                                                       payment.amount = amount

                                                       this.setState({
                                                           payment,
                                                           isPaymentValidated: payment.amount > 0 || +invoice.total === 0 && payment.amount >= 0,
                                                           partial: !this.state.partial
                                                       })
                                                   }}
                                    />
                                </Col>
                                <Col md={3} className={isMobile ? 'bottom15' : 'term-modal-input'}>
                                    <FormControl placeholder="Reference no."
                                                 value={payment.reference_no}
                                                 onChange={(e) => this.setState({
                                                     payment: {
                                                         ...payment,
                                                         reference_no: e.target.value
                                                     }
                                                 })}>
                                    </FormControl>
                                </Col>
                            </>
                            }
                        </Row></>}
                </Modal.Body>
                {invoice && invoice.enable_payments_client && invoice.payments && invoice.payments.length > 0 &&
                <Payments
                    show={this.state.resource}
                    onRefund={this.props.onPaymentRefund}
                    onUpdate={this.props.onPaymentUpdate}
                    onDelete={this.props.onPaymentDelete}/>}
                <Modal.Footer>
                    <Row style={{display: isMobile ? 'flex' : null}}>
                        <Col md={6} xs={6} className="text-left">
                            <Button bsSize="small" onClick={() => {
                                this.resetValues()
                                this.props.onHide()
                            }}>Close</Button>
                        </Col>
                        <Col md={6} xs={6} className="text-right">
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                disabled={addingPayments || !this.props.show}
                                onClick={() => {
                                    if (!resource.paid_at && invoice && ((+invoice.total !== 0 && payment.amount <= 0) || (+invoice.total === 0 && payment.amount < 0))) {
                                        this.setState({isPaymentValidated: false})
                                    } else if ((resource.paid_at && payment.amount > 0) || (!resource.paid_at && invoice && ((+invoice.total === 0 && payment.amount < 0) || (+invoice.total !== 0 && payment.amount <= 0)))) {
                                        this.setState({isTermValidated: false})
                                    } else if (!resource.term_type_id) {
                                        this.setState({isTermTypeValidated: false})
                                    } else {
                                        this.props.onSave(resource, invoice)
                                        this.resetValues()
                                    }
                                }}
                            >Save
                            </Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}


export default TermModal;
