import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, FormGroup, Grid, MenuItem, Nav, NavDropdown, Row, Table,} from "react-bootstrap"

import Dollars from "../../components/Dollars"

import {LinkContainer} from "react-router-bootstrap"
import moment from "moment";
import ThDate from "../../components/ThDate";
import StaticMap from "../PrintProposalPage/StaticMap";
import './NewInvoicePrintOutput.css'
import PlantName from "../../components/PlantName";
import "../MapViewPage/new/ProposalMenu.css";
import './NewInvoicePrint.css';
import LabelWithText from "../../components/LabelWithText";
import {colors, defaultDateFormat, reverseColorArray, sortAssetsLabels} from "../../common/commonHandlers";
import Chart from "chart.js";
import paid from './paid.png';

const google = window.google;
const isMobile = window.screen.width <= 1024;

export class NewInvoicePrintOutput extends Component {

    rendered = 0;

    state = {};

    drawCharts = () => {
        const charts = [];
        const chartsArray = [
            {name: 'assetSummary', title: 'Asset Summary'},
            {name: 'serviceSummary', title: 'Service Summary'},
            {name: 'serviceType', title: 'Service Types'}
        ]

        chartsArray.forEach((chart) => {
            if (this.props[chart.name]) {
                const chartObject = this.drawSVGChart(chart.name, chart.title);
                charts.push(chartObject);
            }
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.serviceSummary !== prevProps.serviceSummary ||
            this.props.assetSummary !== prevProps.assetSummary ||
            this.props.serviceType !== prevProps.serviceType ||
            this.props.options?.headerFontSize !== prevProps.options?.headerFontSize) {
            this.drawCharts()
        }
    }

    componentDidMount() {
        const {options, invoice} = this.props;
        if (invoice && options) {
            const siteMapsCount = invoice.site_maps.map((siteMap) =>
                this.props.options[`maps_${siteMap.id}`] ? 1 : 0).reduce((sum, x) => sum + x, 0);
            const mapsToRender = (options.maps ? +siteMapsCount : 0) + (options.maps ? 1 : 0);
            this.drawCharts();
            this.setState({mapsToRender}, () => {
                if (mapsToRender === 0) {
                    setTimeout(() => {
                        window.status = 'rendered';
                    }, 3000);
                }
            });
        }
    }

    renderServices = invoice => {
        const {dbh, condition, lat, lng, height, width, stems, factors, wrapVertically} = this.props.options;
        const {serviceSummary, serviceType, assetSummary} = this.props
        const {thumbnailSize, wrapHorizontally} = this.props.options
        let showCharts;

        if (serviceSummary || serviceType || assetSummary) {
            showCharts = true
        }

        return (
            <div className='services-page'>
                {invoice.proposal_services &&
                    invoice.proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map((ps, i) => {
                            if (
                                !this.props.options.servicesNotOffered &&
                                ps.status === "Not Offered"
                            ) {
                                return <div className="skip_not_offered"/>
                            }

                            return <div className='single-service'>
                                <div className='header-row'>
                                    <strong className='counter'>#{ps.order_number_in_proposal}</strong>{' '}
                                    <strong>{ps.service_name}</strong>{' '} {this.props.options.servicePrices ?
                                    <span className='price'><Dollars amount={ps.price}/></span> : null}
                                </div>
                                <div className='po-number'>
                                    {this.props.options.servicePurchaseOrder && ps.purchase_order &&
                                    <span><strong>
                                        PO:</strong> {ps.purchase_order}<br/>
                                    </span>}
                                </div>
                                <div className='service-details'>
                                    <strong>
                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                            ps.priorities.map(p => <span
                                                key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                    </strong>
                                    {this.props.options.servicePriorities && ps.priority
                                        ? <br/>
                                        : null}

                                    {this.props.options.serviceDateRanges && ps.date_ranges.length
                                        ? ps.date_ranges.map(dr => (
                                            <div key={dr.id}>
                                                Typical Timing:
                                                {" "}
                                                {moment(dr.from_date).format("MMMM Do")}
                                                {" "}
                                                -
                                                {" "}
                                                {moment(dr.to_date).format("MMMM Do")}
                                            </div>
                                        ))
                                        : null}

                                    {this.props.options.proposalServiceNotes &&
                                        <span className='pre-line'>{ps.proposal_service_note}</span>}
                                    {ps.is_bundle_service && ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map(bs => {
                                        return (
                                            <div key={bs.id} className='mt10'>
                                                <div>
                                                    <strong className='ml-9'>{bs.name}</strong>
                                                </div>
                                                <span className='pre-line'>
                                                    {bs.notes}
                                                </span>
                                                <div className='mt10'>
                                                    <strong className='trees '>Trees</strong>
                                                </div>
                                                <div className='assets-container'>
                                                    {bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(asset => {
                                                        if (asset.image_order) {
                                                            let order = JSON.parse(asset.image_order);
                                                            asset.photos = asset.photos.map(img => {
                                                                img.order = order.findIndex((o) => o === img.id)
                                                                return img
                                                            })
                                                            return asset
                                                        } else {
                                                            asset.photos = asset.photos.map((img, index) => {
                                                                img.order = index
                                                                return img
                                                            })
                                                            return asset
                                                        }
                                                    }).map((a, i) => (
                                                        <div
                                                            className={`asset-wrapper` + `${this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '-inline' : ""}`}
                                                            style={{width: this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '30%' : '100%'}}
                                                            key={a.asset_label}>

                                                            <strong>
                                                                #{a.asset_label} <PlantName plant={a.name}/> {" "}
                                                            </strong>
                                                            <span
                                                                className={`asset-details` + `${wrapVertically ? '-vertically' : ""}`}>
                                                <span>{a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}</span>
                                                <span>{dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}</span>
                                                <span>{condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                <span>{lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                <span>{lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                <span>{height && a.height ? ` Height: ${a.height}` : null}</span>
                                                <span>{width && a.width ? ` Width: ${a.width}` : null}</span>
                                                <span>{stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                <span>{this.props.options.locationDescription && a.location
                                                    ? ` Location: ${a.location}`
                                                    : null}</span>
                                                <span> {this.props.options.assetNotes && a.note && a.note.length > 1
                                                    ? ` Note: ${a.note}  `
                                                    : null}</span>
                                                <p>{(this.props.options.thumbAssetPhotos && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                    <span
                                                        className={wrapHorizontally ? `thumbnails-horizontally` : `thumbnails-vertically`}>
                                                        <img src={p.url}
                                                             style={{height: thumbnailSize || 180}}/></span>) : ""}
                                                </p>
                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                {!ps.is_bundle_service &&
                                    <>
                                        <strong className='trees'>Trees</strong>
                                        <div className='assets-container'>
                                            {ps.assets
                                                .sort((a, b) => sortAssetsLabels(a, b, 'asset_label'))
                                                .map((a, i) => (
                                                    <div
                                                        className={`asset-wrapper` + `${this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '-inline' : ""}`}
                                                        style={{width: this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '30%' : '100%'}}
                                                        key={a.asset_label}>

                                                        <strong>
                                                            #{a.asset_label} <PlantName plant={a.name}/> {" "}
                                                        </strong>
                                                        <span
                                                            className={`asset-details` + `${wrapVertically ? '-vertically' : ""}`}>
                                                <span>{a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}</span>
                                                <span>{dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}</span>
                                                <span>{condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                <span>{lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                <span>{lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                <span>{height && a.height ? ` Height: ${a.height}` : null}</span>
                                                <span>{width && a.width ? ` Width: ${a.width}` : null}</span>
                                                <span>{stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                <span>{factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                <span>{this.props.options.locationDescription && a.location
                                                    ? ` Location: ${a.location}`
                                                    : null}</span>
                                                <span> {this.props.options.assetNotes && a.note && a.note.length > 1
                                                    ? ` Note: ${a.note}  `
                                                    : null}</span>
                                                <p>{(this.props.options.thumbAssetPhotos && a.photos?.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                    <span
                                                        className={wrapHorizontally ? `thumbnails-horizontally` : `thumbnails-vertically`}>

                                                        <img src={p.url}
                                                             style={{height: thumbnailSize || 180}}/></span>) : ""}
                                                </p>
                                            </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </>
                                }
                            </div>
                        })
                }
            </div>
        )
    };

    renderProposalNote = invoice => (invoice && <Row className="avoid_page_breaking_inside">
            <Col xs={12}>
                <p>&nbsp;</p>
                <em className='pre-line'>
                    {invoice.proposal_customer_note || ""}
                </em>
                <p>&nbsp;</p>
            </Col>
        </Row>
    );

    handleRender = invoice => {
        this.rendered++;
        const {mapsToRender} = this.state;

        if (mapsToRender === this.rendered) {
            setTimeout(() => {
                window.status = 'rendered';
            }, 1000);
        }
    };

    viewLoaded = () => {
        setTimeout(() => {
            window.status = 'rendered';
        }, 1000);
    };
    setMapMarginBottom = () => {
        const {logoPosition, showLogoHeader} = this.props
        const {marginBottom} = this.props.options

        const logoOptions = logoPosition !== 'center'

        if (marginBottom) {
            return marginBottom
        } else if (!showLogoHeader) {
            return 200
        } else if (showLogoHeader && logoOptions) {
            return 60
        } else {
            return 145
        }
    }
    drawSVGChart = (chartName, chartTitle) => {
        const {proposal_services} = this.props.invoice;

        const data = this.getChartsInfo(proposal_services, chartName)
        const fontSize = this.props.options?.headerFontSize || 16

        const defaultOptions = {
            type: 'pie',
            options: {
                events: {
                    display: false
                },
                layout: {
                    padding: {
                        bottom: 0
                    },
                },
                title: {
                    display: true,
                    text: chartTitle,
                    padding: 10,
                    fontSize: fontSize + 2,
                    font: {
                        weight: 'lighter',
                        family: "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                },
                legend: {
                    display: false,
                },
                legendCallback: chart => {
                    let html = '<div>';
                    chart.data.labels.forEach((l, i) => {
                        const ds = chart.data.datasets[0];
                        const bgColor = ds.backgroundColor[i];
                        const border = ds.borderWidth + 'px solid ' + ds.borderColor;
                        const legendFontSize = fontSize - 2;

                        html += '<span>' +
                            '<div class="box" style="width: 25px; height: 14px; background-color:' + bgColor + '!important' + '; border:' + border + '"></div>' +
                            '<span id="legend-label-' + i + '"' +' style="font-size: ' + legendFontSize + 'px"' + '>'  +
                            (Array.isArray(l) ? l.join('<br/>') : l) + '</span>' +
                            '</span>';
                    });
                    return html + '</div>';
                },
                responsive: false,
                maintainAspectRatio: false,
                bezierCurve: false,
            }
        }

        const options = {
            ...defaultOptions,
            data: {
                labels: data.labels,
                datasets: [
                    {
                        data: data.counts,
                        backgroundColor: data.colors,
                        datalabels: {
                            color: reverseColorArray(data.colors),
                            align: 'end',
                        },
                        borderWidth: 1,
                    },
                ],
            }
        }

        const chartDOM = document.getElementById(`SVG-${chartName}`)
        chartDOM.classList.add("visible-desktop");
        const chart = new Chart(chartDOM, options);
        document.getElementById(`legend-${chartName}`).innerHTML = chart.generateLegend();
        return chart
    }

    removeDuplicates = (array, value) => {
        return array.reduce((unique, o) => {
            if (!unique.some(obj => obj[value] === o[value])) {
                unique.push(o);
            }
            return unique;
        }, []);
    }

    getChartsInfo = (services, chartName) => {
        const data = {}

        const allItems = []
        const assetColors = []
        const allAssets = []
        services.map(service => {
            switch (chartName) {
                case 'assetSummary':
                    allAssets.push(...service.assets)
                    break;
                case 'serviceType':
                    allItems.push(service.service_type)
                    break;
                case 'serviceSummary':
                    allItems.push(service.service_name)
                    break;
            }
        })
        if (chartName === 'assetSummary') {
            const assets = this.removeDuplicates(allAssets, 'asset_label')
            const serviceAssetsColors = allAssets.map(a => {
                return [a.name, parseInt(a.plant_color)]
            })
            allItems.push(...assets.map(a => a.name))
            assetColors.push(...serviceAssetsColors)
        }

        const items = allItems.reduce((acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1
        }), {});

        data.labels = Object.keys(items);
        data.counts = Object.values(items);

        if (chartName === 'assetSummary') {
            const assetColorsDictionary = Object.fromEntries(assetColors);
            data.colors = data.labels.map(label => colors[assetColorsDictionary[label]])
        } else {
            data.colors = data.labels.map((label, index) => colors[index + 4])
        }

        return data;
    }
    renderPie = (chartName) => {

        return (
            <div className="chart-wrapper">
                <canvas width={200} height={200} id={`SVG-${chartName}`}/>
                <div id={`legend-${chartName}`}></div>
            </div>
        )
    }

    renderCharts = (invoice) => {

        if (invoice.proposal_services === undefined) {
            return null
        }

        const {assetSummary, serviceType, serviceSummary} = this.props;

        return (
            <div className='charts-align'>
                {assetSummary && this.renderPie('assetSummary')}
                {serviceType && this.renderPie('serviceType')}
                {serviceSummary && this.renderPie('serviceSummary')}
            </div>
        )
    }

    renderSiteMap = (invoice, mapNum, assets, client) => {
        const {marginTop} = this.props.options
        const top = marginTop ? marginTop : 15
        if (invoice.proposal_services === undefined) {
            return null
        }
        return (
            <div className="text-center map-wrapper"
                 style={{marginTop: `${top}px`, marginBottom: `${this.setMapMarginBottom()}px`}}>
                <h3>Asset Map</h3>
                <StaticMap
                    size="600x600"
                    zoom={this.props.options && !this.props.options.fitBounds && (mapNum === 1 ? +invoice.site_zoom : 20)}
                    maptype={invoice.site_map_type || "hybrid"}
                    center={this.props.options && !this.props.options.fitBounds && (`${invoice.site_lat || ((client && client.organization_latitude) ?
                        client.organization_latitude
                        : 40.0)},
                        ${invoice.site_lng || ((client && client.organization_longitude) ?
                        client.organization_longitude
                        : -105.0)}`)}
                    invoiceToken={invoice.token}
                    onRender={() => this.handleRender(invoice)}
                    mainMap={true}
                    fitBounds={this.props.options.fitBounds}
                />
            </div>
        );
    };

    _renderMapSettings = (invoice) => {
        if (invoice.site_maps) {
            return invoice.site_maps.map((siteMap, index) =>
                this.props.options[`maps_${siteMap.id}`] ?
                    <div key={index}>
                        <br/>
                        {this._renderSiteMap(siteMap, invoice, index + 1, this.props.client)}
                    </div> : '');
        }
    };

    _renderSiteMap = (siteMap, invoice, idx, client) => {
        if (invoice.proposal_services === undefined) {
            return null
        }
        return (
            <div className="text-center">
                <StaticMap
                    size="600x600"
                    zoom={siteMap.default_zoom || 20}
                    maptype={siteMap.map_type || 'hybrid'}
                    center={
                        `${siteMap.latitude || ((client && client.organization_latitude) ?
                            client.organization_latitude
                            : 40.0)},
                        ${siteMap.longitude || ((client && client.organization_longitude) ?
                            client.organization_longitude
                            : -105.0)}`}
                    invoiceToken={invoice.token}
                    onRender={() => this.handleRender(invoice)}
                    siteMap={idx}
                    mainMap={false}
                    fitBounds={this.props.options.fitBounds}
                />
            </div>
        )
    };

    renderPageHeader = invoice => {
        return (this.props.options && <>
                <Row className={`invoice-page-header`}>
                    <Col md={6} xs={12} className='info-print'>
                        <div className="pull-left">
                            Invoiced: <ThDate dateString={invoice.invoiced_at}/>
                            <strong
                                className='ml-10 mr-10'>{invoice.term_name && invoice.term_name}</strong> <strong
                            className='colorRed'>Due On:{" "}{<ThDate dateString={invoice.due_date}/>}</strong>
                            <h3 style={{marginTop: '0px', marginBottom: '0px'}}>
                                {this.props.options.invoiceSiteName ? invoice.site_name : invoice.customer_full_name}
                            </h3>
                            <span className='title'>{invoice.proposal_title}</span><br/>
                            {this.props.options && this.props.options.purchaseOrder && invoice.purchase_order &&
                                <span><strong>
                                PO:</strong> {invoice.purchase_order}<br/>
                            </span>}
                            <LabelWithText label="Invoice" text={invoice.number}/>
                            <LabelWithText label="Contact" text={invoice.site_contact_name}/>
                            <span className="font-weight-bold">Phone: </span>
                            <a media="screen" href={`tel:${invoice.customer_phone.replace(/\\D/g, '')}`}>
                                {invoice.customer_phone}
                            </a><br/>
                            <span className="font-weight-bold">Email: </span>
                            <a media="screen" href={`mailto:${invoice.customer_email_address}`}>
                                {invoice.customer_email_address}
                            </a><br/>
                            <span className="font-weight-bold">Billing Address: </span>
                            {this.props.options.invoiceSiteAddress ? invoice.site_full_address : <>
                                {invoice.customer_address_1}{" "}
                                {invoice.customer_address_2 ? invoice.customer_address_2 : null}
                                {invoice.customer_address_2 ? <br/> : null}
                                {invoice.customer_city}, {invoice.customer_state}{" "}
                                {invoice.customer_zip}
                            </>}
                            <br/>
                            {!this.props.options.invoiceSiteName &&
                                <LabelWithText label='Site' text={invoice.site_name}/>}
                            {!this.props.options.invoiceSiteAddress &&
                                <LabelWithText label="Address" text={invoice.site_full_address}/>}
                        </div>
                    </Col>
                    {(this.props.options.arboristInfo || this.props.options.showCompanyAddress) &&
                        <Col md={6} xs={12} className={`text-left proposal-info info-print ${!isMobile && 'hright'}`}>
                            <div>
                                {this.props.options.arboristInfo && <>
                                    <LabelWithText label="Arborist" text={invoice.sales_arborist_name}/>
                                    <LabelWithText label="Phone" text={invoice.arborist_phone}/>
                                    <LabelWithText label="Email" text={invoice.arborist_email}/>
                                </>}
                                {this.props.options.showCompanyAddress && <LabelWithText label="Company address"
                                                                                         text={`${invoice.client_address}, ${invoice.client_city}, ${invoice.client_state} ${invoice.client_zip}`}/>}
                            </div>
                        </Col>}
                </Row>
            </>
        )
    };

    renderInvoiceTotal = invoice => {
        if (invoice && invoice.proposal_services === undefined) {
            return null
        }

        const total = invoice && invoice.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);

        return (invoice && <><Row className="avoid_page_breaking_inside">
                <hr/>
                <Col xs={12} className="text-right">
                    <p>
                        <strong>
                            Subtotal: {<Dollars amount={total}/>}
                        </strong>
                    </p>
                    {invoice.print_options.tax && <p>
                        <strong>
                            Tax: {<Dollars amount={total * invoice.tax}/>}
                        </strong>
                    </p>}
                    <p>
                        <strong>
                            Total Price: {<Dollars amount={total + total * invoice.tax}/>}
                        </strong>
                    </p>
                </Col>
            </Row>
                {this.props.options.showInvoicePayments && invoice.payments?.length > 0 && <Row className="avoid_page_breaking_inside">
                    <Col xs={12} className="text-right">
                        <p>
                            <strong>Payment Details:</strong>
                            <ul>
                                {invoice.payments.map(ip => <li key={ip.id}>
                                    {moment(ip.payment_date).format(defaultDateFormat)} <Dollars amount={ip.amount}/>
                                </li>)}
                            </ul>
                        </p>
                        <p>
                            <strong>
                                Total Paid: {<Dollars amount={invoice.payments.reduce((sum, ip) => {
                                return sum + parseFloat(ip.amount)
                            }, 0)}/>}
                            </strong>
                        </p>
                        {invoice.print_options.balance && <p>
                            <strong>
                                Balance: {<Dollars amount={invoice.balance}/>}
                            </strong>
                        </p>}
                    </Col>
                </Row>}
            </>
        )
    };

    getUniqueAssets(invoice) {
        const assetsMap = {};
        invoice.proposal_services.forEach(ps => ps.assets && ps.assets.forEach(a => assetsMap[a.asset_number] = a));
        return Object.keys(assetsMap).map(key => assetsMap[key]);
    }

    renderPhotos = invoice => {
        if (invoice.proposal_services === undefined) {
            return null
        }

        const isMobile = window.screen.width <= 425;

        let photos = [];
        let assets = this.getUniqueAssets(invoice).sort((a, b) => sortAssetsLabels(a, b, 'asset_label'));
        assets.map(asset => {
            if (asset.image_order) {
                let order = JSON.parse(asset.image_order);
                asset.photos = asset.photos.map(img => {
                    img.order = order.findIndex((o) => o === img.id)
                    return img
                })
                return asset
            } else {
                asset.photos = asset.photos.map((img, index) => {
                    img.order = index
                    return img
                })
                return asset
            }
        })
        assets.forEach(a => {
            a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(p => {
                photos.push(
                    <Table bordered key={p.id} className="photo">
                        <thead/>
                        <tbody>
                        <tr>
                            <td className="text-center" style={{padding: isMobile && 0}}>
                                <img alt="tree" width={isMobile && window.screen.width - 60 || '100%'} src={p.url}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                #{a.asset_label}{" "}<PlantName plant={a.name}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )
            })
        });

        if (photos.length === 0) {
            photos = null
        } else {
            return (
                <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <h5>Photos</h5>
                        {photos}
                    </Col>
                </Row>
            )
        }
    };

    renderClientHeader = invoice => {
        const isMobile = window.screen.width <= 768;
        const logoPosition = this.props.logoPosition && this.props.logoPosition === 'center' ? 'unset' : this.props.logoPosition

        return this.props.options && <Row>
            <Col xs={12} className="text-center">
                {this.props.showLogoHeader && <img
                    height={this.props.logoHeight}
                    style={{float: logoPosition}}
                    width={isMobile && window.screen.width - 60}
                    alt="company header"
                    className="logoHeader"
                    src={invoice.client_header}
                />}
            </Col>
        </Row>
    };

    renderTermsAndConditions = invoice => {
        return (
            invoice.proposal_terms && <Row className="terms-wrapper top50">
                <Col md={12} style={{fontSize: "initial"}}>
                    <FormGroup>
                        <div dangerouslySetInnerHTML={{__html: invoice.proposal_terms}}/>
                    </FormGroup>
                </Col>
            </Row>
        )
    };

    render() {
        const {invoice, options, client, assets} = this.props;
        const {mapsToRender} = this.state;
        const {atTheEnd} = this.props.options
        const defaultFontSize = 16;
        return (
            <Grid id="print_invoice">
                <Row>
                    <Col xs={12}>
                        <div style={{fontSize: `${options?.headerFontSize || defaultFontSize}px`}}>
                            {this.renderClientHeader(invoice)}
                            {options.proposalCustomerNote && !options.notesAfterServices && !options.notesBeforeServices &&
                                this.renderProposalNote(invoice)}
                            {this.renderPageHeader(invoice)}
                            {this.renderCharts(invoice)}
                        </div>
                        <div style={{fontSize: `${options?.contentFontSize || defaultFontSize}px`}}>
                            {!atTheEnd && options.maps && mapsToRender && this.renderSiteMap(invoice, 1, assets, this.props.client)}
                            {!atTheEnd && options.maps && this._renderMapSettings(invoice)}

                            {options.proposalCustomerNote && options.notesBeforeServices && this.renderProposalNote(invoice)}
                            {this.renderServices(invoice)}

                            {options.proposalCustomerNote && options.notesAfterServices &&
                                this.renderProposalNote(invoice)}
                        </div>

                        <div style={{fontSize: `${options?.footerFontSize || defaultFontSize}px`}}>
                            {this.renderInvoiceTotal(invoice)}
                        </div>

                        <div style={{fontSize: `${options?.contentFontSize || defaultFontSize}px`}}>
                            {options.largeAssetPhotos && this.renderPhotos(invoice)}
                            {options.assInline}
                            {atTheEnd && options.maps && mapsToRender && this.renderSiteMap(invoice, 1, assets, this.props.client)}
                            {atTheEnd && options.maps && this._renderMapSettings(invoice)}
                            {this.props.options.showPaidImage && invoice.paid_at &&
                                <img className='paid_image' style={{marginLeft: isMobile && 0}} src={paid}/>}
                        </div>

                        {options.showTerms && this.renderTermsAndConditions(invoice)}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

NewInvoicePrintOutput.propTypes = {
    invoice: PropTypes.object.isRequired,
    options: PropTypes.object
};
